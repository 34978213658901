import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  stackClasses,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_EMAIL } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { EmailTemplate } from "../types/EmailTemplate";
import PFMInput from "../components/PFMInput";
import {
  TransactionalEmail,
  TransactionalEmailKey,
} from "../types/TransactionalEmail";
import { rtmSetTransactionEmail } from "../core/api/admin";

export default function EditTransactionalEmailDialog(
  props: DialogProps<{
    templates: EmailTemplate[];
    transactional?: TransactionalEmail;
    key: TransactionalEmailKey;
  }>
) {
  const [data, setData] = useState<Partial<TransactionalEmail>>(
    props.data?.transactional || {}
  );
  const templates = props.data?.templates || [];
  const key = props.data?.key;

  const [selectedTemplate, setSelectedTemplate] = useState<
    EmailTemplate | undefined
  >(templates.find((t) => data?.template === t.id));
  const [busy, setBusy] = useState(false);

  async function save() {
    try {
      if (!selectedTemplate) {
        enqueueSnackbar("Please select a template. ", { variant: "error" });
        return;
      }
      if (!data.subject) {
        enqueueSnackbar("Please type a subject for the email. ", {
          variant: "error",
        });
        return;
      }
      if (!data.from) {
        enqueueSnackbar("Please enter the from header. ", { variant: "error" });
        return;
      }
      setBusy(true);

      await rtmSetTransactionEmail({
        from: data.from,
        key: key,
        subject: data.subject,
        template: selectedTemplate.id,
      });

      enqueueSnackbar("Transactional email has been updated. ", {
        variant: "success",
      });

      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error updating transactional email. Please try again. ",
        {
          variant: "error",
        }
      );
      console.error("Error creating transactional email. ", err);
    }
    setBusy(false);
  }

  function RenderTemplate(t: EmailTemplate) {
    return (
      <Stack
        onClick={() => setSelectedTemplate(t)}
        sx={{
          width: "250px",
          height: "300px",
          border:
            selectedTemplate?.id === t.id
              ? "1px solid #48FA"
              : "1px solid #FFF3",
          borderRadius: "12px",
          position: "relative",
          cursor: "pointer",
          overflow: "hidden",
          ":hover": {
            [`& .${stackClasses.root}`]: {
              opacity: 1,
            },
          },
        }}
        alignItems={"start"}
        justifyContent={"flex-end"}
        spacing={"8px"}
      >
        <img
          src={t?.thumbnail}
          alt={t?.name}
          style={{
            flex: 1,
            objectFit: "contain",
            width: "100%",
          }}
        />
        <Typography fontSize={18} sx={{ padding: "8px" }}>
          {t?.name}
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"8px"}
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            background: "#0003",
            transition: "all .3s",
            opacity: 0,
            zIndex: 2,
          }}
        ></Stack>
      </Stack>
    );
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullScreen
      maxWidth={"md"}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_EMAIL />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Edit Transactional Email
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Assign template for an automated system email.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
          <Stack direction={"row"} spacing={"8px"}>
            <Stack flex={1}>
              <PFMInput
                label="From"
                text={data.from}
                placeholder="Enter a friendly address..."
                endAdornment={
                  <Typography sx={{ px: "12px" }}>
                    @mailing.cashifybot.com
                  </Typography>
                }
                onUpdate={(t) => setData({ ...data, from: t })}
              />
            </Stack>
            <Stack flex={1}>
              <PFMInput
                label="Subject"
                text={data.subject}
                placeholder="Enter a subject for the email..."
                onUpdate={(t) => setData({ ...data, subject: t })}
              />
            </Stack>
          </Stack>

          {/* Render Templates  */}
          <Stack spacing={"8px"}>
            <Typography>Select a template</Typography>
            <Stack direction={"row"} rowGap={"8px"} columnGap={"8px"}>
              {templates.map((t) => RenderTemplate(t))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          size="large"
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={save}
          size="large"
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
