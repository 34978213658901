import React, { useState } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import MigrationUsingAccessCodes from "./components/MigrationUsingAccessCodes";
import MigrationUsingTrialSub from "./components/MigrationUsingTrialSub";
export default function Migration(label: any) {
  const desktop = useMediaQuery("(min-width: 700px)");
  const theme = useTheme();
  const [method, setMethod] = useState<"code" | "trial">("code");
  const handleClick = (newMethod: any) => {
    setMethod(newMethod);
  };
  return (
    <Stack id="migrate" alignItems={"center"} spacing={"20px"}>
      <Typography
        sx={{
          fontSize: desktop ? "40px" : "14px",
          fontWeight: "700",
          textAlign: "center",
          padding: " 16px 197px",
          [theme.breakpoints.down("tablet")]: {
            fontSize: "30px",
            padding: "0px",
            textAlign: "center",
          },
        }}
      >
        Migrating to Cashifybot
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          background: "#29266E",
          padding: "8px",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          //
          [theme.breakpoints.down("desktop")]: {
            alignItems: "start",
            justifyContent: "start",
          },
          //
          [theme.breakpoints.down("tablet")]: {
            alignItems: "start",
            justifyContent: "start",
            padding: "8px",
            gap: "10px",
          },
        }}
      >
        <Typography
          onClick={() => handleClick("code")}
          sx={{
            cursor: "pointer",
            color: method === "code" ? "#0A0927" : "#FFF",
            fontWeight: method === "code" ? "400" : "400",
            background: method === "code" ? "#fff" : "transparent",
            borderRadius: method === "code" ? "5px" : "0",
            padding: method === "code" ? " 16px 32px" : "16px 32px",
            letterSpacing: "-0.4px",
            //
            [theme.breakpoints.down("desktop")]: {
              fontSize: method === "code" ? "12px" : "12px",
              padding: method === "code" ? " 16px 32px" : "16px 32px",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              fontSize: method === "code" ? "12px" : "12px",
              padding: method === "code" ? "  18px 20px" : "18px 20px",
            },
          }}
        >
          Using Access Codes
        </Typography>
        <Typography
          onClick={() => handleClick("trial")}
          sx={{
            cursor: "pointer",
            color: method === "trial" ? "#0A0927" : "#FFF",
            fontWeight: method === "trial" ? "400" : "400",
            background: method === "trial" ? "#fff" : "transparent",
            borderRadius: method === "trial" ? "5px" : "0",
            padding: method === "trial" ? " 16px 32px" : " 16px 32px",
            letterSpacing: "-0.4px",
            //
            [theme.breakpoints.down("desktop")]: {
              fontSize: method === "code" ? "12px" : "12px",
              padding: method === "code" ? " 16px 32px" : "16px 32px",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              fontSize: method === "code" ? "12px" : "12px",
              padding: method === "code" ? "  18px 20px" : "18px 20px",
            },
          }}
        >
          Using Trial Subscriptions
        </Typography>
      </Stack>
      <AnimatePresence mode="wait">
        {method === "code" && <MigrationUsingAccessCodes />}

        {method === "trial" && <MigrationUsingTrialSub />}
      </AnimatePresence>
    </Stack>
  );
}
