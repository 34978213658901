import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import { IC_ALERT, IC_DELETE_CREDIT_CARD } from "../assets/ui";
import { User } from "../types/User";

export default function CancelCashifySubscription(props: DialogProps<User>) {
  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_DELETE_CREDIT_CARD />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Cancel User Subscription?
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          <Typography fontSize={14} fontWeight={400} sx={{ px: "24px" }}>
            Do you really want to cancel Cashifybot Premium subscription for{" "}
            {props.data?.name} ({props.data?.email})? They will no longer be
            able to create more than 3 subscriptions, and 5 access codes, as
            well as their access to premium features will be restricted.
          </Typography>
          <Alert
            sx={{ alignItems: "center" }}
            color="info"
            icon={
              <SvgIcon>
                <IC_ALERT />
              </SvgIcon>
            }
          >
            <Typography>
              The subscription will be cancelled immediately, and user will no
              longer be able to access premium feature.
            </Typography>
          </Alert>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              onClick={() => props.closeHandler(false)}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={() => {
                props.closeHandler(true);
              }}
              size="large"
              fullWidth
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
