import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { Delete, Edit } from "@mui/icons-material";

import { IC_LOCK_ADD, IC_REFRESH } from "../../assets/ui";
import { motion } from "framer-motion";
import CreateAccessCodeDialog from "../../dialogs/CreateAccessCode";
import DeleteAccessCodeDialog from "../../dialogs/DeleteAccessCode";
import EditAccessCodeDialog from "../../dialogs/EditAccessCode";
import { rtmDeleteAccessCode, rtmGetCodes } from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import { AccessCode } from "../../types/AccessCode";

export default function SectionUserCodes() {
  const [, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<any[]>([]);

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _codes = await rtmGetCodes();
      setData(_codes || []);
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.error(err);
    }
    setBusy(false);
  }

  function showCreateDialog() {
    const modal = showModal(CreateAccessCodeDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          load();
        }
      },
    });
  }

  function showEditDialog(sub: AccessCode) {
    const modal = showModal(EditAccessCodeDialog, {
      data: sub,
      closeHandler(result) {
        modal.destroy();
        if (result) {
          load();
        }
      },
    });
  }
  function showDeleteDialog(sub: AccessCode) {
    const modal = showModal(DeleteAccessCodeDialog, {
      async closeHandler(result) {
        if (result) {
          await rtmDeleteAccessCode(sub.id)
            .catch((err) => {
              enqueueSnackbar("Error deleting access code. ", {
                variant: "error",
              });
              console.error("Error deleting access code. ", err);
            })
            .then(() => {
              enqueueSnackbar("The code has been deleted. ", {
                variant: "success",
              });
            });
          load();
        }
        modal.destroy();
      },
      data: sub,
    });
  }

  useEffect(() => {
    setSection(`Access Codes`);
    setSectionDescription(`View and manage access codes to give free access.`);
    load();
  }, []);

  function RenderCode(sub: AccessCode) {
    return (
      <TableRow>
        {!mobile && (
          <TableCell>
            <Typography color="#94969C">{sub.id}</Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {sub.code}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {sub.description}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(sub.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {sub.last_used
              ? moment.unix(sub.last_used).format("MMM DD, YYYY HH:mm:ss")
              : ""}
          </Typography>
        </TableCell>
        <TableCell>
          {sub.chats.map((c) => (
            <Chip
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
              variant="identifier"
              label={c.title}
              size="small"
            />
          ))}
        </TableCell>

        <TableCell>
          {/* Use count reached  */}
          {sub.max_uses > 0 && sub.max_uses - sub.uses <= 0 && (
            <Chip
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
              label="Expired"
              variant="outlined"
              color="warning"
              size="small"
            />
          )}
          {/* Expiry date reached  */}
          {sub.expires! > 0 && moment().isAfter(moment.unix(sub.expires!)) && (
            <Chip
              label="Expired"
              variant="outlined"
              color="warning"
              size="small"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            />
          )}
          {!(
            sub.max_uses > 0 &&
            sub.uses > 0 &&
            sub.max_uses - sub.uses <= 0
          ) &&
            !(
              sub.expires! > 0 && moment.unix(sub.expires!).isBefore(moment())
            ) && (
              <Chip
                label="Active"
                variant="outlined"
                color="success"
                size="small"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              />
            )}
        </TableCell>

        <TableCell align="right">
          <Stack direction={"row"}>
            <IconButton
              disabled={busy}
              color="error"
              title="Delete"
              onClick={() => showDeleteDialog(sub)}
            >
              <Delete />
            </IconButton>
            <IconButton disabled={busy} onClick={() => showEditDialog(sub)}>
              <Edit />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "12px",
      }}
    >
      {mobile && (
        <Stack direction="row" justifyContent={"flex-end"} spacing={"8px"}>
          <Button
            disabled={busy}
            variant="contained"
            color="primary"
            startIcon={
              <SvgIcon>
                <IC_LOCK_ADD />
              </SvgIcon>
            }
            onClick={showCreateDialog}
          >
            Create
          </Button>
          <Button
            variant="contained"
            disabled={busy}
            onClick={() => load()}
            color="secondary"
            size="small"
            sx={{ width: "140px" }}
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
        </Stack>
      )}
      <PFMTable
        sx={{ flex: 1 }}
        title="Access Codes"
        description="List of all the access codes for your bot."
        titleBadge={data.length + " Codes"}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                disabled={busy}
                onClick={load}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
              <Button
                disabled={busy}
                variant="contained"
                color="primary"
                startIcon={
                  <SvgIcon>
                    <IC_LOCK_ADD />
                  </SvgIcon>
                }
                onClick={showCreateDialog}
              >
                Create
              </Button>
            </>
          )
        }
        tableHead={
          <TableRow>
            {!mobile && <TableCell>ID</TableCell>}
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Code
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Description
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Created
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Last Used
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Chats
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Status
            </TableCell>
            <TableCell
              align="right"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderCode(d))}</>}
      />
    </motion.div>
  );
}
