import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  stackClasses,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import { motion } from "framer-motion";
import {
  aCurrentSection,
  aCurrentSectionDescription,
} from "../../../core/states";
import PFMTable from "../../../components/PFMTable";
import {
  IC_EDIT,
  IC_EDIT_EMAIL,
  IC_EMAIL_DELETE,
  IC_REFRESH,
} from "../../../assets/ui";
import { Email } from "../../../types/Email";
import { useModal } from "mui-modal-provider";
import EditCampaignDialog from "../../../dialogs/EditCampaign";
import {
  rtmDeleteCampaign,
  rtmDeleteTransactionEmail,
  rtmGetEmailCampaigns,
  rtmGetEmailTemplates,
  rtmGetTransactionalEmails,
  rtmSetTransactionEmail,
} from "../../../core/api/admin";
import moment from "moment";
import { Add, Delete } from "@mui/icons-material";
import {
  TransactionalEmail,
  TransactionalEmailKey,
} from "../../../types/TransactionalEmail";
import { EmailTemplate } from "../../../types/EmailTemplate";
import { GetTransactionalEmailKeyDescription } from "../../../core/helper";
import EditTransactionalEmailDialog from "../../../dialogs/EditTransactionalEmail";

const TRANSACTION_KEYS = Object.values(TransactionalEmailKey);

export default function SectionAdminEmailTransactional() {
  const { showModal } = useModal();
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);

  const [busy, setBusy] = useState(true);

  const [transactionalEmails, setTransactionalEmails] = useState<
    TransactionalEmail[]
  >([]);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  async function load() {
    try {
      setBusy(true);
      const camps = await rtmGetTransactionalEmails();
      setTransactionalEmails(camps);
      const temps = await rtmGetEmailTemplates();
      setEmailTemplates(temps);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  async function deleteTe(key: TransactionalEmailKey) {
    try {
      setBusy(true);
      await rtmDeleteTransactionEmail(key);
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  function showEditDialog(key: TransactionalEmailKey) {
    const modal = showModal(EditTransactionalEmailDialog, {
      closeHandler(result) {
        modal.destroy();
        load();
      },
      data: {
        key: key,
        templates: emailTemplates,
        transactional: transactionalEmails.find((t) => t.key === key),
      },
    });
  }

  useEffect(() => {
    setSection(`Transactional Emails`);
    setSectionDescription(
      `Assign templates for automated emails sent by system.`
    );
    load();
  }, []);

  function RenderTemplate(key: TransactionalEmailKey) {
    // Check if the key has template assigned.
    const t = transactionalEmails.find((t) => t.key === key);

    if (t) {
      // Get the template
      const template = emailTemplates.find((et) => et.id === t.template);

      return (
        <Stack
          sx={{
            width: "250px",
            height: "300px",
            border: "1px solid #FFF3",
            borderRadius: "12px",
            position: "relative",
            overflow: "hidden",
            ":hover": {
              [`& .${stackClasses.root}`]: {
                opacity: 1,
              },
            },
          }}
          alignItems={"start"}
          justifyContent={"flex-end"}
          spacing={"8px"}
        >
          <img
            src={template?.thumbnail}
            alt={template?.name}
            style={{
              flex: 1,
              objectFit: "contain",
              width: "100%",
            }}
          />
          <Typography fontSize={18} sx={{ padding: "8px" }}>
            {template?.name}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={"8px"}
            sx={{
              height: "100%",
              width: "100%",
              position: "absolute",
              background: "#0003",
              transition: "all .3s",
              opacity: 0,
              zIndex: 2,
            }}
          >
            <IconButton
              title="Edit"
              sx={{ background: "#222", ":hover": { background: "#444" } }}
              onClick={() => showEditDialog(key)}
            >
              <SvgIcon color="primary">
                <IC_EDIT_EMAIL />
              </SvgIcon>
            </IconButton>

            <IconButton
              title="Delete"
              sx={{ background: "#222", ":hover": { background: "#444" } }}
              onClick={() => deleteTe(key)}
            >
              <SvgIcon color="error">
                <IC_EMAIL_DELETE />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
      );
    } else {
      // render empty one
      return (
        <Stack
          sx={{
            width: "250px",
            height: "300px",
            border: "1px solid #FFF3",
            borderRadius: "12px",
            position: "relative",
            cursor: "pointer",
            ":hover": {
              [`& .${stackClasses.root}`]: {
                opacity: 1,
              },
            },
          }}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={"8px"}
          onClick={() => showEditDialog(key)}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={"8px"}
            sx={{
              height: "100%",
              width: "100%",
              position: "absolute",
              background: "#0003",
              transition: "all .3s",
              opacity: 0,
            }}
          ></Stack>
          <SvgIcon>
            <Add />
          </SvgIcon>
          <Typography fontSize={14} sx={{ padding: "8px" }}>
            Assign a Template
          </Typography>
          <Typography>{GetTransactionalEmailKeyDescription(key)}</Typography>
        </Stack>
      );
    }
  }

  return (
    <motion.div
      style={{ overflow: "hidden", flex: 1, display: "flex", padding: "12px" }}
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
    >
      <Stack
        sx={{ overflow: "auto" }}
        direction={"row"}
        flexWrap={"wrap"}
        columnGap={"18px"}
        rowGap={"18px"}
      >
        {TRANSACTION_KEYS.map((t) => RenderTemplate(t as any))}
      </Stack>
    </motion.div>
  );
}
