import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Badge,
  Divider,
  Grid,
  Paper,
  Stack,
  SvgIcon,
  ToggleButton,
  Typography,
} from "@mui/material";
import InsightCard, { SingleInsightCard } from "../../components/InsightCard";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import {
  IC_BALANCE,
  IC_CHECKMARK,
  IC_CREDIT_CARD,
  IC_PAYMENTS,
  IC_TRANSACTION,
  IC_USERS,
} from "../../assets/ui";
import { rtmGetDashboardStats } from "../../core/api/admin";
import { Invoice } from "../../types/Invoice";
import { User } from "../../types/User";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import {
  Bar,
  BarChart,
  Legend,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
} from "recharts";

export default function SectionAdminDashboard() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [chartData, setChartData] =
    useState<{ date: number; gbp: number; eur: number; usd: number }[]>();

  const [volumeChartData, setVolumeChartData] =
    useState<{ date: number; gbp: number; eur: number; usd: number }[]>();

  const [data, setData] = useState<{
    topCustomers: { uid: string; user: User; volume: number; sales: number }[];
    monthlySignups: number;
    monthlySalesChart: {
      [key: number]: {
        sales: {
          gbp: number;
          usd: number;
          eur: number;
        };
        volume: {
          usd: number;
          gbp: number;
          eur: number;
        };
      };
    };
    monthlySales: {
      usd: number;
      eur: number;
      gbp: number;
    };
    monthlyVolume: {
      usd: number;
      eur: number;
      gbp: number;
    };
    activeSubscriptions: number;
    totalSales: {
      usd: number;
      eur: number;
      gbp: number;
    };
    totalVolume: {
      usd: number;
      eur: number;
      gbp: number;
    };
    openInvoices: Invoice[];
  }>();
  const [busy, setBusy] = useState(false);
  const totalVolume = useMemo(() => {
    const _gbp = parseStripeAmount(data?.totalVolume.gbp || 0);
    const _eur = parseStripeAmount(data?.totalVolume.eur || 0);
    const _usd = parseStripeAmount(data?.totalVolume.usd || 0);
    return {
      gbp: `${getCurrencySymbol("gbp")}${_gbp.dollars}.${_gbp.cents}`,
      eur: `${getCurrencySymbol("eur")}${_eur.dollars}.${_eur.cents}`,
      usd: `${getCurrencySymbol("usd")}${_usd.dollars}.${_usd.cents}`,
    };
  }, [data]);
  const totalSales = useMemo(() => {
    const _gbp = parseStripeAmount(data?.totalSales.gbp || 0);
    const _eur = parseStripeAmount(data?.totalSales.eur || 0);
    const _usd = parseStripeAmount(data?.totalSales.usd || 0);
    return {
      gbp: `${getCurrencySymbol("gbp")}${_gbp.dollars}.${_gbp.cents}`,
      eur: `${getCurrencySymbol("eur")}${_eur.dollars}.${_eur.cents}`,
      usd: `${getCurrencySymbol("usd")}${_usd.dollars}.${_usd.cents}`,
    };
  }, [data]);
  const monthlyVolume = useMemo(() => {
    const _gbp = parseStripeAmount(data?.monthlyVolume.gbp || 0);
    const _eur = parseStripeAmount(data?.monthlyVolume.eur || 0);
    const _usd = parseStripeAmount(data?.monthlyVolume.usd || 0);
    return {
      gbp: `${getCurrencySymbol("gbp")}${_gbp.dollars}.${_gbp.cents}`,
      eur: `${getCurrencySymbol("eur")}${_eur.dollars}.${_eur.cents}`,
      usd: `${getCurrencySymbol("usd")}${_usd.dollars}.${_usd.cents}`,
    };
  }, [data]);
  const monthlySales = useMemo(() => {
    const _gbp = parseStripeAmount(data?.monthlySales.gbp || 0);
    const _eur = parseStripeAmount(data?.monthlySales.eur || 0);
    const _usd = parseStripeAmount(data?.monthlySales.usd || 0);
    return {
      gbp: `${getCurrencySymbol("gbp")}${_gbp.dollars}.${_gbp.cents}`,
      eur: `${getCurrencySymbol("eur")}${_eur.dollars}.${_eur.cents}`,
      usd: `${getCurrencySymbol("usd")}${_usd.dollars}.${_usd.cents}`,
    };
  }, [data]);

  async function load() {
    try {
      setBusy(true);
      const _data = await rtmGetDashboardStats();
      setData(_data);
      if (_data) {
        let _r = [];
        let _v = [];
        for (let _d of Object.entries(_data.monthlySalesChart)) {
          _r.push({
            //date: moment.unix(parseInt(_d[0])).format("MMM DD, YYYY"),
            date: parseInt(_d[0]),
            gbp: parseStripeAmount(_d[1].sales.gbp || 0).dollars,
            eur: parseStripeAmount(_d[1].sales.eur || 0).dollars,
            usd: parseStripeAmount(_d[1].sales.usd || 0).dollars,
          });
          _v.push({
            //date: moment.unix(parseInt(_d[0])).format("MMM DD, YYYY"),
            date: parseInt(_d[0]),
            gbp: parseStripeAmount(_d[1].volume.gbp || 0).dollars,
            eur: parseStripeAmount(_d[1].volume.eur || 0).dollars,
            usd: parseStripeAmount(_d[1].volume.usd || 0).dollars,
          });
        }
        setChartData(_r);
        setVolumeChartData(_v);
      }
      console.log(_data);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(``);
    setSectionDescription(``);
    load();
  }, []);

  function RenderChartTooltip(payloads: any[], label: any, heading: string) {
    const _saleGbp = payloads.find((p) => p.name === "gbp");
    const _saleEur = payloads.find((p) => p.name === "eur");
    const _saleUsd = payloads.find((p) => p.name === "usd");
    return (
      <Stack
        spacing={"16px"}
        sx={{
          width: "100%",
          background: "#202020",
          px: "14px",
          py: "8px",
          borderRadius: "8px",
          border: "1px solid #88F",
        }}
        justifyContent={"center"}
        alignItems={"start"}
      >
        <Typography>{moment.unix(label).format("MMM DD, YYYY")}</Typography>

        <Stack sx={{ width: "100%" }}>
          <Typography fontSize={18} fontWeight={500}>
            {heading}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>USD</Typography>
            <Typography>
              {getCurrencySymbol("usd")}
              {_saleUsd?.value || 0}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>GBP</Typography>
            <Typography>
              {getCurrencySymbol("gbp")}
              {_saleGbp?.value || 0}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>EUR</Typography>
            <Typography>
              {getCurrencySymbol("eur")}
              {_saleEur?.value || 0}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  function RenderLegend(payload: any) {
    return (
      <Stack
        spacing={"16px"}
        direction={"row"}
        sx={{ width: "100%" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {payload?.map((p: any) => {
          return (
            <Stack
              spacing={"8px"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <svg height={20} width={20}>
                <rect
                  height={10}
                  width={10}
                  fill={
                    p.value === "eur"
                      ? "url(#grad2)"
                      : p.value === "gbp"
                      ? "url(#grad1)"
                      : "url(#grad3)"
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "2px",
                  }}
                />
              </svg>
              <Typography>
                {p.value === "eur" ? "EUR" : p.value === "gbp" ? "GBP" : "USD"}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  }

  return (
    <Stack sx={{ px: "32px" }} rowGap={"18px"}>
      <Grid
        spacing={"8px"}
        container
        sx={{ pt: "32px" }}
        columns={4}
        rowSpacing={"32px"}
      >
        <Grid item>
          <InsightCard
            accent="#2E90FA"
            icon={
              <SvgIcon>
                <IC_TRANSACTION />
              </SvgIcon>
            }
            title="Total Volume"
            subheading="Current Balance"
            gbp={totalVolume.gbp}
            usd={totalVolume.usd}
            eur={totalVolume.eur}
          />
        </Grid>
        <Grid item>
          <InsightCard
            accent="#2E90FA"
            icon={
              <SvgIcon>
                <IC_TRANSACTION />
              </SvgIcon>
            }
            title="This Month's Volume"
            gbp={monthlyVolume.gbp}
            usd={monthlyVolume.usd}
            eur={monthlyVolume.eur}
          />
        </Grid>
        <Grid item>
          <InsightCard
            accent="#17B26A"
            title="Total Sales"
            icon={
              <SvgIcon>
                <IC_CREDIT_CARD />
              </SvgIcon>
            }
            gbp={totalSales.gbp}
            usd={totalSales.usd}
            eur={totalSales.eur}
          />
        </Grid>

        <Grid item>
          <InsightCard
            accent="#17B26A"
            title="This Month's Sales"
            icon={
              <SvgIcon>
                <IC_CREDIT_CARD />
              </SvgIcon>
            }
            gbp={monthlySales.gbp}
            usd={monthlySales.usd}
            eur={monthlySales.eur}
          />
        </Grid>
        <Grid item>
          <SingleInsightCard
            accent="#D444F1"
            icon={
              <SvgIcon>
                <IC_USERS />
              </SvgIcon>
            }
            title="New Users"
            value={data?.monthlySignups.toString() || "0"}
          />
        </Grid>
        <Grid item>
          <SingleInsightCard
            accent="#A04F23"
            icon={
              <SvgIcon>
                <IC_CHECKMARK />
              </SvgIcon>
            }
            title="Active Subscriptions"
            value={data?.activeSubscriptions.toString() || "0"}
          />
        </Grid>
      </Grid>

      <Stack direction={"row"} spacing={"8px"}>
        <Stack flex={1} spacing={"8px"}>
          {/*Sales Chart */}
          <Paper
            sx={{
              height: "400px",
              p: "26px",
              background: "#FFF1",
              borderRadius: "8px",
            }}
          >
            <Stack sx={{ height: "100%" }}>
              <Stack direction={"row"} alignItems={"center"} spacing={"18px"}>
                <Typography fontSize={"18px"} fontWeight={"bold"}>
                  Sales
                </Typography>
                <Typography fontSize={"14px"}>
                  Over last 30 days - From{" "}
                  {moment().subtract(30, "days").format("MMM DD, YYYY")}
                </Typography>
              </Stack>
              <Divider sx={{ my: "8px" }} />
              <Stack flex={1}>
                {chartData && (
                  <ResponsiveContainer width={"100%"} height={"100%"}>
                    <BarChart data={chartData}>
                      <defs>
                        <linearGradient
                          id="grad1"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{
                              stopColor: "#48F",
                            }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: "#448",
                            }}
                          />
                        </linearGradient>
                        <linearGradient
                          id="grad2"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{
                              stopColor: "#095",
                            }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: "#090",
                            }}
                          />
                        </linearGradient>
                        <linearGradient
                          id="grad3"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            style={{
                              stopColor: "#F35",
                            }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: "#F00",
                            }}
                          />
                        </linearGradient>
                      </defs>
                      <ChartTooltip
                        cursor={false}
                        contentStyle={{
                          background: "#303030",
                          border: "0px solid #707070",
                          borderRadius: "8px",
                          fontFamily: "Poppins",
                          boxShadow: "0px 0px 5px 0px #4488FF",
                        }}
                        itemStyle={{
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                          color: "#BBB",
                        }}
                        content={({ payload, label }) => {
                          return RenderChartTooltip(
                            payload as any,
                            label,
                            "Sales"
                          );
                        }}
                      />
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(v) => "$" + v}
                      />
                      <XAxis
                        dataKey={"date"}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(v) =>
                          moment.unix(v).format("MMM DD, YYYY")
                        }
                      />
                      <Bar
                        spacing={"10px"}
                        dataKey={"gbp"}
                        stackId={"a"}
                        background={<Rectangle fill="#FFF2" radius={2} />}
                        enableBackground={"true"}
                        radius={2}
                        barSize={12}
                        style={{
                          fill: "url(#grad1)",
                        }}
                      />
                      <Bar
                        spacing={"10px"}
                        dataKey={"usd"}
                        stackId={"c"}
                        background={<Rectangle fill="#FFF2" radius={2} />}
                        enableBackground={"true"}
                        radius={2}
                        barSize={12}
                        style={{
                          fill: "url(#grad3)",
                        }}
                      />
                      <Bar
                        spacing={"10px"}
                        dataKey={"eur"}
                        stackId={"b"}
                        background={<Rectangle fill="#FFF2" radius={2} />}
                        enableBackground={"true"}
                        radius={2}
                        barSize={12}
                        style={{
                          fill: "url(#grad2)",
                        }}
                      />
                      <Legend
                        content={({ payload }) => {
                          return RenderLegend(payload as any);
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Stack>
            </Stack>
          </Paper>
        </Stack>

        <Stack>
          {/* Top Uesrs */}
          <Paper
            sx={{
              width: "600px",
              p: "26px",
              background: "#FFF1",
              borderRadius: "8px",
            }}
          >
            <Stack sx={{ height: "100%" }}>
              <Typography fontSize={"18px"} fontWeight={"bold"}>
                Top Customers
              </Typography>
              <Divider sx={{ my: "8px" }} />
              {data?.topCustomers.map((tc) => (
                <Stack
                  spacing={"8px"}
                  alignItems={"center"}
                  key={tc.uid}
                  direction={"row"}
                  sx={{ my: "4px" }}
                >
                  <Avatar src={tc.user.image} alt={tc.user.name}>
                    {tc.user.name[0]}
                  </Avatar>
                  <Stack flex={1}>
                    <Typography fontSize={20}>{tc.user.name}</Typography>
                    <Typography fontSize={12}>{tc.user.email}</Typography>
                  </Stack>
                  <Stack
                    spacing={"64px"}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Stack sx={{ minWidth: "70px" }}>
                      <Typography fontSize={10} variant="button">
                        Sales
                      </Typography>
                      <Typography>
                        {getCurrencySymbol(tc.user.currency)}
                        {parseStripeAmount(tc.sales || 0).dollars}.
                        {parseStripeAmount(tc.sales || 0).cents}
                      </Typography>
                    </Stack>
                    <Stack sx={{ minWidth: "70px" }}>
                      <Typography fontSize={10} variant="button">
                        Volume
                      </Typography>
                      <Typography>
                        {getCurrencySymbol(tc.user.currency)}
                        {parseStripeAmount(tc.volume || 0).dollars}.
                        {parseStripeAmount(tc.volume || 0).cents}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Paper>
        </Stack>
      </Stack>
      {/* Volume Chart  */}
      <Paper
        sx={{
          height: "400px",
          p: "26px",
          background: "#FFF1",
          borderRadius: "8px",
        }}
      >
        <Stack sx={{ height: "100%" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={"18px"}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>
              Volume Processed
            </Typography>
            <Typography fontSize={"14px"}>
              Over last 30 days - From{" "}
              {moment().subtract(30, "days").format("MMM DD, YYYY")}
            </Typography>
          </Stack>
          <Divider sx={{ my: "8px" }} />
          <Stack flex={1}>
            {volumeChartData && (
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <BarChart data={volumeChartData}>
                  <defs>
                    <linearGradient
                      id="grad1"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{
                          stopColor: "#48F",
                        }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: "#448",
                        }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="grad2"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{
                          stopColor: "#095",
                        }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: "#090",
                        }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="grad3"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{
                          stopColor: "#F35",
                        }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: "#F00",
                        }}
                      />
                    </linearGradient>
                  </defs>
                  <ChartTooltip
                    cursor={false}
                    contentStyle={{
                      background: "#303030",
                      border: "0px solid #707070",
                      borderRadius: "8px",
                      fontFamily: "Poppins",
                      boxShadow: "0px 0px 5px 0px #4488FF",
                    }}
                    itemStyle={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#BBB",
                    }}
                    content={({ payload, label }) => {
                      return RenderChartTooltip(
                        payload as any,
                        label,
                        "Volume"
                      );
                    }}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(v) => "$" + v}
                  />
                  <XAxis
                    dataKey={"date"}
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(v) => moment.unix(v).format("MMM DD, YYYY")}
                  />
                  <Bar
                    spacing={"10px"}
                    dataKey={"gbp"}
                    stackId={"a"}
                    background={<Rectangle fill="#FFF2" radius={2} />}
                    enableBackground={"true"}
                    radius={2}
                    barSize={12}
                    style={{
                      fill: "url(#grad1)",
                    }}
                  />
                  <Bar
                    spacing={"10px"}
                    dataKey={"usd"}
                    stackId={"c"}
                    background={<Rectangle fill="#FFF2" radius={2} />}
                    enableBackground={"true"}
                    radius={2}
                    barSize={12}
                    style={{
                      fill: "url(#grad3)",
                    }}
                  />
                  <Bar
                    spacing={"10px"}
                    dataKey={"eur"}
                    stackId={"b"}
                    background={<Rectangle fill="#FFF2" radius={2} />}
                    enableBackground={"true"}
                    radius={2}
                    barSize={12}
                    style={{
                      fill: "url(#grad2)",
                    }}
                  />
                  <Legend
                    content={({ payload }) => {
                      return RenderLegend(payload as any);
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}
