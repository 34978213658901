import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import RotatoryText from "./components/RotatoryText";

export default function Hero() {
  const [scrollOffset, setScrollOfset] = useState(window.scrollY);
  const desktop = useMediaQuery("(min-width: 1280px)");
  const tablet = useMediaQuery("(min-width: 1024px)");
  const theme = useTheme();
  const playerRef = useRef<ReactPlayer>();
  function handleScroll() {
    setScrollOfset(Math.ceil(window.scrollY));
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Stack
      id="Home"
      sx={{
        overflow: "hidden",
        maxWidth: "1280px",
        alignSelf: "center",
        p: desktop ? "48px" : "48px 32px",
        width: "100%",
        mt: "100px",
        [theme.breakpoints.down("tablet")]: {
          mt: "64px",
        },
      }}
      justifyContent={desktop ? "space-between" : "start"}
      direction={desktop ? "row" : "column-reverse"}
      spacing={"32px"}
    >
      {/* The hero content */}
      <Stack
        flex={1}
        spacing={"33px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={desktop ? "center" : "center"}
          // spacing={"8px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            sx={{
              fontSize: desktop ? "50px" : "30px",
              color: "#FFF",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Turn Your
          </Typography>
          <RotatoryText
            delay={3000}
            text={["Telegram Channels", "Telegram Groups", "Discord Server"]}
          />
          <Typography
            sx={{
              fontSize: desktop ? "50px" : "30px",
              color: "#FFF",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            into Revenue
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          Easily sell subscriptions and digital products with{" "}
          <span style={{ fontWeight: "700" }}>Cashify</span>bot.
        </Typography>
        <motion.div
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ type: "spring", stiffness: 50, delay: 1 }}
        >
          <Stack
            direction={desktop || tablet ? "row" : "column"}
            spacing={"15px"}
            sx={{
              justifyContent: desktop || tablet ? "start" : "center",
              alignItems: desktop || tablet ? "start" : "center",
            }}
          >
            <Button
              href="/signup"
              variant="contained"
              size={desktop ? "large" : tablet ? "medium" : "small"}
              sx={{
                borderRadius: "10px 0px",
                textTransform: "uppercase",
                background: "linear-gradient(180deg, #0494BE 0%, #543488 100%)",
                padding: "8px 32px",
                transition: "all .3s",
                color: "#FFF",
                width: "170px",
                height: "44px",
                ":hover": {
                  background: "transparent",
                  border: "1px solid #FFF",
                },
              }}
            >
              get started
            </Button>
            <Button
              href="https://documenter.getpostman.com/view/23844318/2sA3BobCFF"
              size={desktop ? "large" : tablet ? "medium" : "small"}
              sx={{
                borderRadius: "10px 0px",
                textTransform: "uppercase",
                background: "transparent",
                border: "1px solid #FFF",
                padding: "8px 32px",
                height: "44px",
                width: "170px",
                color: "#FFF",
                ":hover": {
                  background:
                    "linear-gradient(180deg, #0494BE 0%, #543488 100%)",
                },
              }}
            >
              Documentation
            </Button>
          </Stack>
        </motion.div>
      </Stack>
    </Stack>
  );
}
