import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { IMG_DISCORD, IMG_TELEGRAM } from "../../assets/brands";

function TelegramHeading(props: {
  title?: string;
  description?: string;
  imgSrc?: any;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");
  const tablet = useMediaQuery("(min-width: 1024px)");
  const theme = useTheme();
  return (
    <Stack
      sx={{
        padding: "48px",
        [theme.breakpoints.down("tablet")]: {
          width: "50%",
          padding: "16px",
        },
        [theme.breakpoints.down("tablet")]: {
          width: "100%",
          padding: "16px",
        },
      }}
    >
      {/* text */}

      <Stack
        alignItems={"start"}
        justifyContent={"start"}
        borderRadius={"20px"}
        spacing={"32px"}
        direction={"row"}
        sx={{
          background:
            "linear-gradient(112deg, rgba(171, 114, 237, 0.70) 1.11%, rgba(38, 164, 227, 0.70) 64.51%), linear-gradient(101deg, rgba(27, 139, 249, 0.70) 1.08%, rgba(103, 97, 235, 0.70) 100%)",
          padding: "32px 48px",
          [theme.breakpoints.down("desktop")]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "32px",
            padding: "0px",
          },
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "32px",
            padding: "22px",
          },
        }}
      >
        <Stack
          sx={{
            gap: "32px",
            width: "828px",
            alignItems: "flex-start",
            justifyContent: "center",
            [theme.breakpoints.down("desktop")]: {
              width: "50%",
              gap: "16px",
            },
            [theme.breakpoints.down("tablet")]: {
              width: "100%",
              gap: "16px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: "700",
              [theme.breakpoints.down("desktop")]: {
                fontSize: "30px",
              },
              [theme.breakpoints.down("tablet")]: {
                fontSize: "20px",
              },
            }}
          >
            Cashifybot is currently only available for Telegram users.
          </Typography>

          <Typography
            sx={{
              color: "#FFF",
              fontSize: "25px",
              fontWeight: "300",
              width: "828px",
              [theme.breakpoints.down("desktop")]: {
                fontSize: "20px",
              },
              [theme.breakpoints.down("tablet")]: {
                width: "100%",
                fontSize: "12px",
              },
            }}
          >
            But we're not stopping there—our team is hard at work developing a
            Discord app, which will be available soon. Join our Telegram Channel
            to stay updated!
          </Typography>
          <Button
            href="https://t.me/CashifybotOfficial"
            size={desktop ? "large" : tablet ? "medium" : "small"}
            sx={{
              width: "300px",
              borderRadius: "10px 0px",
              textTransform: "uppercase",
              background: "#fff",
              border: "1px solid #FFF",
              padding: "8px 32px",
              color: "#2A2E76",
              ":hover": {
                background: "linear-gradient(180deg, #0494BE 0%, #543488 100%)",
                color: "white",
              },

              [theme.breakpoints.down("tablet")]: {
                width: "100%",
                fontSize: "12px",
                padding: "10px",
              },
            }}
          >
            join our telegram channel
          </Button>
        </Stack>

        {/* img */}
        <Stack
          direction={"row"}
          sx={{
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: "49px 0px 57px 18px",
            gap: "27px",
            flex: "1 0 0",
            alignSelf: "stretch",
            [theme.breakpoints.down("desktop")]: {
              // flexDirection: "column",
              // gap: "16px",
              // padding: "0",
              // margin: "0",
              // flex: 0,
              // paddingTop: "42px",
              // justifyContent: "flex-start",
              // alignItems: "flex-start",
            },
            [theme.breakpoints.down("tablet")]: {
              flexDirection: "row",
              gap: "16px",
              padding: "0",
              margin: "0",
              flex: 0,
              // paddingTop: "42px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
          }}
        >
          <Stack
            spacing={"20px"}
            sx={{
              width: "211px",
              height: "243px",
              background: "#26A4E3",
              boxShadow:
                "0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset, 2px 2px 40px 0px rgba(255, 255, 255, 0.25)",
              borderRadius: "20px",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              [theme.breakpoints.down("desktop")]: {
                width: "130px",
                height: "160px",
                gap: "0",
                padding: " 12px",
              },
              [theme.breakpoints.down("tablet")]: {
                width: "130px",
                height: "160px",
                gap: "0",
                padding: " 12px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontSize: "20px",
                fontWeight: "900",
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Telegram
            </Typography>
            <Stack
              sx={{
                width: "110px",
                height: "110px",
                [theme.breakpoints.down("tablet")]: {
                  width: "58px",
                  height: "58px",
                },
              }}
            >
              <img
                src={IMG_TELEGRAM}
                alt="Telegram"
                height="100%"
                style={{ objectFit: "contain" }}
              />
            </Stack>
            <Typography
              sx={{
                fontSize: "15px",
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "10px",
                },
              }}
            >
              Currently Supported
            </Typography>
          </Stack>
          <Stack
            spacing={"20px"}
            sx={{
              width: "211px",
              height: "243px",
              background: "#5662F6",
              boxShadow:
                "0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset, 2px 2px 40px 0px rgba(255, 255, 255, 0.25)",
              borderRadius: "20px",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              //
              [theme.breakpoints.down("desktop")]: {
                width: "130px",
                height: "160px",
                gap: "0",
                padding: " 12px",
              },
              [theme.breakpoints.down("tablet")]: {
                width: "130px",
                height: "160px",
                gap: "0",
                padding: " 12px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontSize: "20px",
                fontWeight: "900",
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Discord
            </Typography>

            <Stack
              sx={{
                width: "110px",
                height: "110px",
                [theme.breakpoints.down("tablet")]: {
                  width: "58px",
                  height: "58px",
                },
              }}
            >
              <img
                src={IMG_DISCORD}
                alt="Telegram"
                height="100%"
                style={{ objectFit: "contain" }}
              />
            </Stack>
            <Typography
              sx={{
                fontSize: "15px",
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "10px",
                },
              }}
            >
              Coming Soon
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TelegramHeading;
