import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_BROADCAST } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import { FolderOpen } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../authentication/firebase";
import { DateTimeField } from "@mui/x-date-pickers";
import moment from "moment";
import { rtmCreateBroadcast } from "../core/api/user";

export default function CreateBroadcastDialog(props: DialogProps<any>) {
  const [type, setType] = useState<"scheduled" | "instant">("instant");
  const [subscribers, setSubscribers] = useState(false);
  const [codeUsers, setCodeUsers] = useState(false);
  const [bouncedUsers, setBouncedUsers] = useState(false);
  const [busy, setBusy] = useState(false);
  const [content, setContent] = useState("");
  const [buttons, setButtons] = useState<{ text: string; link: string }[]>([]);
  const [schedule, setSchedule] = useState<any>(moment().add(1, "hour"));

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function create() {
    try {
      // Save broadcast.
      setBusy(true);

      await rtmCreateBroadcast({
        attachment: uploadInfo,
        audience: {
          bouncedUsers: bouncedUsers,
          codeUsers: codeUsers,
          subscribers: subscribers,
        },
        buttons: buttons || [],
        content: content,
        schedule: schedule ? schedule.unix() : moment().unix(),
        type: type,
      });

      enqueueSnackbar("Broadast has been created. ", { variant: "success" });

      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error creating broadcast. Please try again. ", {
        variant: "error",
      });
      console.error("Error creating broadcast. ", err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      // Load last broadcast content
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data for broadcast dialog.", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_BROADCAST />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Create Broadcast
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Send a broadcast message to users interacting with your bot.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
          {/* Affiliation Kind  */}
          <Stack spacing={"4px"}>
            <Typography>When do you want to send the message?</Typography>
            <Stack direction={"row"} spacing={"8px"}>
              <Stack
                onClick={() => setType("instant")}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    type === "instant" ? "1px solid #48F" : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Now
                </Typography>
                <Typography fontSize={14}>
                  Sends the broadcast insantly to selected audience.
                </Typography>
              </Stack>

              <Stack
                onClick={() => setType("scheduled")}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    type === "scheduled" ? "1px solid #48F" : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Scheduled
                </Typography>
                <Typography fontSize={14}>
                  Automatically send on a specified time.
                </Typography>
              </Stack>
            </Stack>
            {type === "scheduled" && (
              <Stack sx={{ pt: "8px" }}>
                <DateTimeField
                  variant="filled"
                  disablePast
                  value={schedule}
                  onChange={(e) => setSchedule(e)}
                  helperText="Select time in your local timezone at which you'd like to send this message."
                  label="Broadcast Schedule"
                  sx={{
                    opacity: 1,
                    backdropFilter: "none",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      borderRadius: "8px",
                    },
                  }}
                />
              </Stack>
            )}
          </Stack>

          <Stack spacing={"4px"}>
            <Typography>Select your target audience</Typography>
            <Stack direction={"row"} spacing={"8px"}>
              <Stack
                onClick={() => setSubscribers(!subscribers)}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border: subscribers ? "1px solid #48F" : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Subscribers
                </Typography>
                <Typography fontSize={14}>
                  Users who have at least 1 subscription.
                </Typography>
              </Stack>

              <Stack
                onClick={() => setCodeUsers(!codeUsers)}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border: codeUsers ? "1px solid #48F" : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Access Code Users
                </Typography>
                <Typography fontSize={14}>
                  Users who have used at least 1 access code.
                </Typography>
              </Stack>

              <Stack
                onClick={() => setBouncedUsers(!bouncedUsers)}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border: bouncedUsers ? "1px solid #48F" : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Bounced Users
                </Typography>
                <Typography fontSize={14}>
                  Users who do not have any access code or subscriptions.
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={"4px"}>
            <PFMInput
              label="Message Content"
              important
              text={content}
              onUpdate={(t) => setContent(t)}
              multiline
              rows={5}
              helperText="Supports basic HTML syntax. See telegram bot API for supported tags. Make sure all the tags are closed properly."
              placeholder="Type a message..."
            />
            <PFMInput
              text={uploadInfo}
              label="Attach a Photo or Video"
              placeholder="Enter URL or select a file..."
              helperText="Supports photo and video only. Size limits: 10MB for Photos, 50MB for videos."
              endAdornment={
                <IconButton
                  disabled={busy}
                  onClick={() => document.getElementById("file-input")?.click()}
                >
                  <FolderOpen />
                </IconButton>
              }
            />
            {uploadState === "uploading" && (
              <LinearProgress value={uploadProgress} variant="determinate" />
            )}
            <input
              id="file-input"
              style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
              type="file"
              onChange={(f) => {
                if (f.target.files && f.target.files?.length > 0) {
                  uploadAttachment(f.target.files[0]);
                }
              }}
            />
          </Stack>
          <Stack spacing={"4px"}>
            <Stack
              direction={"row"}
              key={buttons.length}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>Link Buttons</Typography>

              <Button
                onClick={() => setButtons([...buttons, { link: "", text: "" }])}
              >
                Add a button
              </Button>
            </Stack>
            {buttons.map((b, i) => (
              <Stack direction={"row"} spacing={"8px"}>
                <PFMInput
                  text={b.text}
                  onUpdate={(t) => {
                    b.text = t;
                    setButtons([...buttons]);
                  }}
                  placeholder="Enter button text..."
                />
                <PFMInput
                  text={b.link}
                  placeholder="Enter URL..."
                  onUpdate={(t) => {
                    b.link = t;
                    setButtons([...buttons]);
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          size="large"
          fullWidth
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={create}
          size="large"
          fullWidth
          variant="contained"
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
