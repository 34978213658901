import { Stack, Typography } from "@mui/material";
import React from "react";

export default function PaymentSystemCard(props: {
  title: string;
  description: string;
  color: string;
  imgSrc: any;
}) {
  return (
    <Stack
      flex={1}
      spacing={"16px"}
      sx={{
        width: "300px",
        padding: "10px",
        gap: "4px",
        alignItems: "flex-start",
      }}
    >
      {/* {props.imgSrc} */}
      <img src={props.imgSrc} alt="" />

      <Typography
        sx={{
          color: "#FFF",
          fontSize: "25px",
          fontStyle: "normal",
          fontWeight: "700",
          letterSpacing: "-0.5px",
          lineHeight: "100%",
        }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={{
          color: "#FFF",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "300",
          letterSpacing: "-0.36px",
          lineHeight: "100%",
        }}
      >
        {props.description}
      </Typography>
    </Stack>
  );
}
