import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";

function MigrationUsingAccessCodes() {
  const theme = useTheme();
  return (
    <motion.div
      key={2}
      initial={{ transform: "translateX(-1000px)" }}
      animate={{ transform: "translateX(0px)" }}
      exit={{ transform: "translateX(-500px)" }}
    >
      <Stack
        // padding={"48px"}
        alignItems={"center"}
        sx={{
          padding: "48px",
          //
          [theme.breakpoints.down("desktop")]: {},
          //
          [theme.breakpoints.down("tablet")]: { padding: "16px" },
        }}
      >
        <Stack
          sx={{
            width: "880px",
            background: "#29266E",
            gap: "24px",
            flexDirection: "column",
            borderRadius: "10px",
            padding: "31px 46px",
            //
            [theme.breakpoints.down("desktop")]: {},
            //
            [theme.breakpoints.down("tablet")]: {
              width: "100%",
              padding: "8px",
            },
          }}
        >
          {/*Line  1 */}
          <Stack
            direction={"row"}
            overflow={"hidden"}
            sx={{
              columnGap: "50px",
            }}
          >
            <Typography
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                fontWeight: 700,
                textAlign: "right",
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Signup
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Create a new account and complete the onboarding process.
            </Typography>
          </Stack>
          {/* Line 2 */}
          <Stack direction={"row"} spacing="50px">
            <Typography
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                fontWeight: 700,
                textAlign: "right",
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Connect with Telegram
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Create a new bot using @BotFather, connect your bot with
              Cashifybot, and add the bot to your desired channel or group with
              admin permissions..
            </Typography>
          </Stack>
          {/*Line 3 */}
          <Stack direction={"row"} spacing="50px">
            <Typography
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                fontWeight: 700,
                textAlign: "right",
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Create Subscription
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Go to Store &gt; Subscriptions, and create a new subscriptions
              with trial period. Make sure to select your desired chat (channel
              or group) that you want the members to join.
            </Typography>
          </Stack>
          {/* Line 4 */}
          <Stack direction={"row"} spacing="50px">
            <Typography
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                fontWeight: 700,
                textAlign: "right",
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Create an Access Code
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Go to Store &gt; Subscriptions, and create a new subscriptions
              with trial period. Make sure to select your desired chat (channel
              or group) that you want the members to join.
            </Typography>
          </Stack>
          {/* Line 5 */}
          <Stack direction={"row"} spacing="50px">
            <Typography
              sx={{
                minWidth: "150px",
                maxWidth: "150px",
                fontWeight: 700,
                textAlign: "right",
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Send Invitations
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "20px",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "12px",
                },
              }}
            >
              Send the access code to your existing members in the old chat,
              along with the bot's username. Access code can be used in the bot
              menu.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
}

export default MigrationUsingAccessCodes;
