import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Divider,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { IC_ALERT, IC_HELP } from "../../../assets/ui";
import PFMInput from "../../../components/PFMInput";
import { enqueueSnackbar } from "notistack";

import moment from "moment";
import {
  rtmGetFlag,
  rtmGetPaymentsConfig,
  rtmSetFlag,
  rtmSetPaymentsConfig,
} from "../../../core/api/admin";
import { PFMSwitch } from "../../../components/PFMSwitch";
import { Config, PaymentsConfiguration } from "../../../types/Config";

export default function SectionAdminSettingsPayments() {
  const [busy, setBusy] = useState(false);
  const [cfg, setCfg] = useState<Config>();

  const [stripeFee, setStripeFee] = useState(0);
  const [cryptoFee, setCryptoFee] = useState(0);
  const [proFee, setProFee] = useState(0);
  const [minPayout, setMinPayout] = useState(0);
  const [monthlyPlanId, setMonthlyPlanId] = useState("");
  const [yearlyPlanId, setYearlyPlanId] = useState("");
  const [regularCommission, setRegularCommission] = useState(0);
  const [businessCommission, setBusinessCommission] = useState(0);
  const [trialDays, setTrialDays] = useState(0);
  const [freeAffiliateTools, setFreeAffiliateTools] = useState(false);
  const [freeBroadcasting, setFreeBroadcasting] = useState(false);
  async function load() {
    try {
      setBusy(true);
      const flagAffiliation = await rtmGetFlag("free_affiliation_tools");
      setFreeAffiliateTools(flagAffiliation);
      const flagBroadcasting = await rtmGetFlag("free_broadcasting_tools");
      setFreeBroadcasting(flagBroadcasting);
      const conf = await rtmGetPaymentsConfig();
      if (conf) {
        setCfg(conf);
        const pays = conf.value as PaymentsConfiguration;
        if (pays) {
          setStripeFee(pays.fees.stripe);
          setCryptoFee(pays.fees.crypto);
          setProFee(pays.fees.members);
          setMinPayout(pays.payouts.minPayout);
          setMonthlyPlanId(pays.plans.monthly);
          setYearlyPlanId(pays.plans.yearly);
          setRegularCommission(pays.affiliation.regularCommission);
          setBusinessCommission(pays.affiliation.businessCommission);
          setTrialDays(pays.plans.trial || 0);
        }
      }
    } catch (err: any) {
      console.error("Error loading payment configuration for admin. ", err);
      enqueueSnackbar("Error loading configuration. Please try later. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function save() {
    try {
      setBusy(true);
      await rtmSetPaymentsConfig({
        affiliation: {
          businessCommission: businessCommission,
          regularCommission: regularCommission,
        },
        fees: {
          crypto: cryptoFee,
          members: proFee,
          stripe: stripeFee,
        },
        payouts: {
          minPayout: minPayout,
        },
        plans: {
          monthly: monthlyPlanId,
          yearly: yearlyPlanId,
          trial: trialDays,
        },
      });
      await rtmSetFlag("free_affiliation_tools", freeAffiliateTools);
      await rtmSetFlag("free_broadcasting_tools", freeBroadcasting);
      enqueueSnackbar("Configuration updated successfully. ", {
        variant: "success",
      });
    } catch (err: any) {
      console.error("Error saving payment configuration for admin. ", err);
      enqueueSnackbar("Error saving configuration. Please try later. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack
      flex={1}
      sx={{ my: "18px", overflow: "auto" }}
      alignItems={"start"}
      spacing="8px"
    >
      <Typography fontWeight={500} fontSize={22}>
        Feature Flags
      </Typography>
      <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
        <PFMSwitch
          checked={freeAffiliateTools}
          onChange={(e) => setFreeAffiliateTools(e.currentTarget.checked)}
        />
        <Typography>Free Affiliate Tools</Typography>
      </Stack>
      <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
        <PFMSwitch
          checked={freeBroadcasting}
          onChange={(e) => setFreeBroadcasting(e.currentTarget.checked)}
        />
        <Typography>Free Broadcasting Tools</Typography>
      </Stack>
      <Divider />
      <Typography fontWeight={500} fontSize={22}>
        Fees
      </Typography>
      <Stack
        rowGap={"8px"}
        columnGap={"8px"}
        flexWrap={"wrap"}
        direction={"row"}
      >
        <Stack>
          <PFMInput
            label="Stripe Application Fee"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="The fee in percentage to deduct from Stripe payments. "
            text={stripeFee.toString()}
            onUpdate={(t) => setStripeFee(parseFloat(t) || 0)}
          />
        </Stack>
        <Stack>
          <PFMInput
            label="Cryptochill Application Fee"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="The fee in percentage to deduct from crypto payouts. "
            text={cryptoFee.toString()}
            onUpdate={(t) => setCryptoFee(parseFloat(t) || 0)}
          />
        </Stack>
        <Stack>
          <PFMInput
            label="Pro Membership Fee"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="The fee in percentage to deduct from transactions for Pro Members. "
            text={proFee.toString()}
            onUpdate={(t) => setProFee(parseFloat(t) || 0)}
          />
        </Stack>
      </Stack>

      <Divider sx={{ width: "100%" }} />
      <Typography fontWeight={500} fontSize={22}>
        Payouts
      </Typography>

      <Stack
        rowGap={"8px"}
        columnGap={"8px"}
        flexWrap={"wrap"}
        direction={"row"}
      >
        <PFMInput
          label="Minimum Payout Amount"
          disabled={busy}
          sx={{ width: "380px" }}
          helperText="The minimum required balance for payouts. "
          text={minPayout.toString()}
          onUpdate={(t) => setMinPayout(parseInt(t) || 0)}
        />
      </Stack>
      <Divider sx={{ width: "100%" }} />
      <Typography fontWeight={500} fontSize={22}>
        Membership Price Plans
      </Typography>
      <Stack
        rowGap={"8px"}
        columnGap={"8px"}
        flexWrap={"wrap"}
        direction={"row"}
      >
        <Stack>
          <PFMInput
            label="Monthly Price ID"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="Paste the id of monthly price from Stripe Subscription."
            text={monthlyPlanId}
            onUpdate={(t) => setMonthlyPlanId(t)}
          />
        </Stack>
        <Stack>
          <PFMInput
            label="Yearly Price ID"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="Paste the id of yearly price from Stripe Subscription."
            text={yearlyPlanId}
            onUpdate={(t) => setYearlyPlanId(t)}
          />
        </Stack>
        <Stack>
          <PFMInput
            label="Trial Days"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="Enter trial period in number of days, or set to 0 to disable trial."
            text={trialDays.toString()}
            onUpdate={(t) => setTrialDays(parseInt(t))}
          />
        </Stack>
      </Stack>
      <Divider sx={{ width: "100%" }} />
      <Typography fontWeight={500} fontSize={22}>
        Affiliation
      </Typography>

      <Stack
        rowGap={"8px"}
        columnGap={"8px"}
        flexWrap={"wrap"}
        direction={"row"}
      >
        <Stack>
          <PFMInput
            label="Regular Affiliate Commission"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="Commission percentage for regular users."
            text={regularCommission.toString()}
            onUpdate={(t) => setRegularCommission(parseInt(t) || 0)}
          />
        </Stack>
        <Stack>
          <PFMInput
            label="Business Affiliate Commission"
            disabled={busy}
            sx={{ width: "380px" }}
            helperText="Commission percentage for businesses."
            text={businessCommission.toString()}
            onUpdate={(t) => setBusinessCommission(parseInt(t) || 0)}
          />
        </Stack>
      </Stack>

      <Divider sx={{ width: "100%" }} />
      <Stack spacing="8px">
        <Button
          disabled={busy}
          onClick={save}
          sx={{ alignSelf: "flex-start" }}
          variant="contained"
        >
          Save
        </Button>

        {cfg && cfg.updated && (
          <Typography color={"#AAA"}>
            Last updated:{" "}
            <span style={{ color: "#DDD" }}>
              {moment.unix(cfg.updated).fromNow()}
            </span>{" "}
            by{" "}
            <span style={{ color: "#DDD" }}>
              {cfg.updatedBy.name} ({cfg.updatedBy.email})
            </span>
          </Typography>
        )}
      </Stack>

      <Stack flex={1} />

      <Stack direction={"row"} spacing="8px" alignItems={"center"}>
        <SvgIcon color="warning" fontSize="small">
          <IC_ALERT />
        </SvgIcon>
        <Typography fontSize={"12px"}>
          Please don't touch anything unless you know what you are doing.
        </Typography>
      </Stack>
    </Stack>
  );
}
