import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { LOGO_HEADER } from "../../assets/brand";
import LinkButton from "../../components/LinkButton";
import { FbAuth } from "../../authentication/firebase";
import { useNavigate } from "react-router";
import { Close } from "@mui/icons-material";
let lastUpdate = 0;
export default function Header(props?: { active?: string }) {
  const [stick, setStick] = useState(window.scrollY > 150);
  const theme = useTheme();
  const nav = useNavigate();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here
  const [toggled, setToggled] = useState(false);

  function handleScroll() {
    if (new Date().getTime() - lastUpdate > 50) {
      setStick(Math.ceil(window.scrollY) > 150);
      lastUpdate = new Date().getTime();
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Stack
      id="header"
      sx={{
        zIndex: 100,
        py: stick ? "8px" : "24px",
        px: "24px",
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        position: "fixed",
        background: toggled ? "#33307E" : "#0A0927",
        // backdropFilter: "blur(4px)",
        borderRadius: toggled ? " 0px 0px 32px 32px" : "0",
      }}
      alignItems={"center"}
    >
      <Stack
        sx={{ maxWidth: "1280px", width: "100%" }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          sx={{
            width: "230px",
            height: "60px",
            [theme.breakpoints.down("desktop")]: {
              height: "50px",
            },
            [theme.breakpoints.down("tablet")]: {
              height: "40px",
            },
          }}
        >
          <Stack
            sx={{
              transition: "all .2s",
              width: stick && !toggled ? "60px" : "230px",
              position: "relative",
              height: "60px",
              overflow: "clip",

              [theme.breakpoints.down("desktop")]: {
                height: "50px",
              },
              [theme.breakpoints.down("tablet")]: {
                width: stick && !toggled ? "40px" : "230px",
                height: "40px",
              },
            }}
          >
            <img
              src={LOGO_HEADER}
              alt="cashify logo"
              style={{
                height: "100%",
                position: "absolute",
                top: 0,
                bottom: 0,
              }}
            />
          </Stack>
        </Stack>
        {desktop && (
          <nav
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              rowGap: "0",
              columnGap: 0,
            }}
          >
            <LinkButton
              observable="Home"
              label="Home"
              onClick={() => {
                if (!window.document.getElementById("Home")) nav("/");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Home")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              observable="about"
              label="About Us"
              onClick={() => {
                if (!window.document.getElementById("about")) nav("/about");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("about")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Features"
              observable="features"
              onClick={() => {
                if (!window.document.getElementById("features"))
                  nav("/features");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("features")?.offsetTop,
                  });
                }
              }}
            />
            {/* <LinkButton
              observable="Testimonials"
              label="Testimonials"
              onClick={() => {
                if (!window.document.getElementById("Testimonials"))
                  nav("/Testimonials");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Testimonials")
                      ?.offsetTop,
                  });
                }
              }}
            /> */}
            <LinkButton
              observable="pricing"
              label="Pricing"
              onClick={() => {
                if (!window.document.getElementById("pricing"))
                  nav("/#pricing");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("pricing")?.offsetTop,
                  });
                }
              }}
            />
          </nav>
        )}
        {desktop && (
          <Stack
            direction={"row"}
            spacing="12px"
            alignItems={"center"}
            sx={{ width: "230px" }}
          >
            <Button
              onClick={() => nav("/signup")}
              size={desktop ? "large" : "small"}
              variant="contained"
              sx={{
                borderRadius: "10px 0px",
                textTransform: "uppercase",
                background: "linear-gradient(180deg, #0494BE 0%, #543488 100%)",
                padding: "8px 32px",
                color: "#FFF",
              }}
            >
              {FbAuth.currentUser && "Dashboard"}
              {!FbAuth.currentUser && "Get Started"}
            </Button>
          </Stack>
        )}
        {!desktop && (
          <IconButton onClick={() => setToggled(!toggled)}>
            {!toggled && <Menu />}
            {toggled && <Close />}
          </IconButton>
        )}
      </Stack>

      {!desktop && (
        <Stack
          alignItems={"center"}
          sx={{
            gap: "33px",
            // background: " #33307E",
            padding: toggled ? "48px 32px" : 0,
            borderRadius: " 0px 0px 32px 32px",
            zIndex: "100",
            height: toggled ? "auto" : "0px",
            overflow: "hidden",
            minHeight: 0,
            transition: "all .2s",
          }}
        >
          <nav
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <LinkButton
              observable="Home"
              label="Home"
              onClick={() => {
                if (!window.document.getElementById("Home")) nav("/Home");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Home")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              observable=""
              label="About Us"
              onClick={() => {
                if (!window.document.getElementById("about")) nav("/about");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("about")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Features"
              observable="features"
              onClick={() => {
                if (!window.document.getElementById("features"))
                  nav("/#features");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("features")?.offsetTop,
                  });
                }
              }}
            />
            {/* <LinkButton
              observable="Testimonials"
              label="Testimonials"
              onClick={() => {
                if (!window.document.getElementById("Testimonials"))
                  nav("/#Testimonials");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Testimonials")
                      ?.offsetTop,
                  });
                }
              }}
            /> */}
            <LinkButton
              observable="pricing"
              label="Pricing"
              onClick={() => {
                if (!window.document.getElementById("pricing"))
                  nav("/#pricing");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("pricing")?.offsetTop,
                  });
                }
              }}
            />
          </nav>
          <Button
            onClick={() => nav("/signup")}
            size={desktop ? "large" : "small"}
            variant="contained"
            sx={{
              borderRadius: "10px 0px",
              textTransform: "uppercase",
              background: "linear-gradient(180deg, #0494BE 0%, #543488 100%)",
              padding: "8px 32px",
              color: "#FFF",
            }}
          >
            get started
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
