import React, { useEffect, useState } from "react";
import { DialogProps } from "./props";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";

import { IC_COUPON } from "../assets/ui";
import { DateTimeField } from "@mui/x-date-pickers";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { Numbers, Percent } from "@mui/icons-material";
import { getCurrencySymbol } from "../core/helper";
import { Coupon } from "../types/Coupon";
import { rtmCreateCoupon } from "../core/api/user";

export default function CreateCouponDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [profile] = useRecoilState(aProfile);
  const [amountOff, setAmountOff] = useState("0");
  const [percentOff, setPercentOff] = useState("0");
  const [amountUnit, setAmountUnit] = useState<"percent" | "amount">("percent");
  // For duration=repeating
  const [durationMonths, setDurationMonths] = useState(0);
  const [duration, setDuration] = useState<AutocompleteOption>();
  const [durations, setDurations] = useState<AutocompleteOption[]>([]);
  const [maxUse, setMaxUse] = useState(0);
  const [expiry, setExpiry] = useState<moment.Moment | null>();

  const [busy, setBusy] = useState(false);

  const theme = useTheme();

  function IsInputValid() {
    if (!name) return false;
    if (!duration || !duration.id) return false;
    if (duration.id === "repeating" && !durationMonths) return false;
    if (amountUnit === "amount" && !amountOff) return false;
    if (amountUnit === "percent" && !percentOff) return false;
    return true;
  }

  async function save() {
    try {
      if (!IsInputValid()) {
        enqueueSnackbar("Please enter all the details. ", { variant: "error" });
        return;
      }
      setBusy(true);
      const coup: Partial<Coupon> = {
        currency: profile?.currency || "usd",
      };
      if (amountUnit === "amount") coup.amount_off = parseFloat(amountOff);
      if (amountUnit === "percent") coup.percent_off = parseFloat(percentOff);
      coup.duration = duration!.object;
      if (duration?.object === "repeating")
        coup.duration_in_months = durationMonths;
      coup.name = name;
      if (maxUse) coup.max_redemptions = maxUse;
      if (expiry) coup.redeem_by = expiry.unix();
      await rtmCreateCoupon(coup);
      enqueueSnackbar("Coupon has been created. ", { variant: "success" });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error creating a new coupon code. Error: " + err.message,
        {
          variant: "error",
        }
      );
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    const _durations: AutocompleteOption[] = [];
    _durations.push({
      label: "One-time",
      id: "once",
    });
    _durations.push({
      label: "Months",
      id: "repeating",
    });
    _durations.push({
      label: "Forever",
      id: "forever",
    });
    setDurations(_durations);
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_COUPON />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Create Coupon
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Add a new coupon
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px", width: "580px" }}>
          <Stack spacing={"24px"} sx={{ px: "24px" }}>
            <PFMInput
              label="Name"
              placeholder="Enter a name for the coupon."
              text={name}
              onUpdate={(t) => setName(t)}
              disabled={busy}
            />
            <Stack>
              <PFMAutoComplete
                key={durations.length}
                options={durations}
                label="Duration"
                placeholder="Select duration of coupon.."
                onUpdate={(sel) => setDuration(sel as any)}
                value={duration}
                important
              />
            </Stack>

            {duration?.id === "month" && (
              <PFMInput
                text={durationMonths.toString() || ""}
                onUpdate={(p) => setDurationMonths(parseInt(p))}
                label="Duration Months"
                placeholder="Number of months to apply the coupon for."
                important
              />
            )}

            <Stack>
              <Typography>Discount Unit</Typography>
              <RadioGroup
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <FormControlLabel
                  disabled={busy}
                  control={
                    <Radio
                      disableRipple
                      checked={amountUnit === "percent"}
                      onChange={(e) =>
                        setAmountUnit(
                          e.currentTarget.checked ? "percent" : "amount"
                        )
                      }
                      icon={<Percent />}
                      checkedIcon={<Percent />}
                    />
                  }
                  label="Percentage"
                  sx={{
                    flex: 1,
                    height: "60px",
                    border:
                      amountUnit === "percent"
                        ? "1px solid " + theme.palette.primary.main
                        : "1px solid #AAA",
                    py: "4px",
                    px: "18px",
                    borderRadius: "15px",
                    m: 0,
                  }}
                />
                <FormControlLabel
                  disabled={busy}
                  control={
                    <Radio
                      disableRipple
                      checked={amountUnit === "amount"}
                      onChange={(e) =>
                        setAmountUnit(
                          e.currentTarget.checked ? "amount" : "percent"
                        )
                      }
                      icon={<Numbers />}
                      checkedIcon={<Numbers />}
                    />
                  }
                  label="Amount"
                  sx={{
                    flex: 1,
                    height: "60px",
                    border:
                      amountUnit === "amount"
                        ? "1px solid " + theme.palette.primary.main
                        : "1px solid #AAA",
                    py: "4px",
                    px: "18px",
                    borderRadius: "15px",
                    m: 0,
                  }}
                />
              </RadioGroup>
            </Stack>

            {amountUnit === "amount" && (
              <PFMInput
                text={amountOff.toString()}
                onUpdate={(p) => setAmountOff(p)}
                label="Amount"
                startAdornment={
                  <Typography sx={{ px: "8px" }}>
                    {getCurrencySymbol(profile?.currency || "usd")}
                  </Typography>
                }
                placeholder="Enter the discount amount"
                important
              />
            )}

            {amountUnit === "percent" && (
              <PFMInput
                text={percentOff.toString()}
                onUpdate={(p) => setPercentOff(p)}
                label="Percent"
                placeholder="Enter the discount percent"
                important
              />
            )}

            <PFMInput
              text={maxUse?.toString()}
              onUpdate={(p) =>
                parseInt(p) >= 0 ? setMaxUse(parseInt(p)) : setMaxUse(0)
              }
              label="Max Uses"
              placeholder="Number of times this coupon can be used"
            />

            <Stack spacing={"4px"}>
              <Typography>Expiry</Typography>
              <DateTimeField
                value={expiry}
                onChange={(e) => setExpiry(e as any)}
                variant="filled"
                disablePast
                size="small"
                clearable
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  borderRadius: "8px",
                  //height: "44px",
                  minHeight: "44px",
                  //maxHeight: "44px",
                  px: "0",
                }}
                inputProps={{
                  style: {
                    paddingRight: "14px",
                    paddingLeft: "14px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={busy}
          onClick={props.closeHandler}
          color="secondary"
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={save}
          color="primary"
          variant="contained"
          fullWidth
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
