import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import {
  IMG_ARBITRUM,
  IMG_ETHEREUM,
  IMG_MATIC,
  IMG_STRIPE,
  IMG_TELEGRAM,
} from "../../../assets/brands";

function Tools() {
  const theme = useTheme();
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      mt={"100px"}
      spacing={"48px"}
    >
      <Typography
        sx={{
          padding: "8px 16px",
          background: "rgba(255, 255, 255, 0.10)",
          borderRadius: "5px",
        }}
      >
        BUILT WITH THE BEST TOOLS
      </Typography>
      <Marquee autoFill style={{ gap: "24px", overflow: "hidden" }}>
        <Box sx={{ height: "64px", px: "24px" }}>
          <img
            src={IMG_STRIPE}
            height="100%"
            alt="Telegram"
            style={{
              marginLeft: "24px",
              marginRight: "24px",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box sx={{ height: "64px", px: "12px" }}>
          <img
            src={IMG_TELEGRAM}
            height="100%"
            alt="Telegram"
            style={{
              marginLeft: "24px",
              marginRight: "24px",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box sx={{ height: "64px", px: "12px" }}>
          <img
            src={IMG_ARBITRUM}
            height="100%"
            alt="Arbitrum"
            style={{
              marginLeft: "24px",
              marginRight: "24px",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box sx={{ height: "64px", px: "12px" }}>
          <img
            src={IMG_ETHEREUM}
            height="100%"
            alt="Ethereum"
            style={{
              marginLeft: "24px",
              marginRight: "24px",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box sx={{ height: "64px", px: "24px" }}>
          <img
            src={IMG_MATIC}
            height="100%"
            width={"100%"}
            alt="Ethereum"
            style={{
              marginLeft: "24px",
              marginRight: "24px",
              objectFit: "contain",
            }}
          />
        </Box>
      </Marquee>
    </Stack>
  );
}

export default Tools;
