import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import IconContainer from "../components/IconContainer";
import { IC_HELP } from "../assets/ui";
import ReactPlayer from "react-player";
import { PlatformFeedback } from "../types/PlatformFeedback";
import { User } from "../types/User";
import moment from "moment";
export default function ViewFeedbackDialog(
  props: DialogProps<PlatformFeedback & { user: User }>
) {
  const content = props.data?.content;
  const category = props.data?.category;
  const user = props.data?.user;

  const media = props.data?.media;

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_HELP />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                View Feedback
              </Typography>
              <Typography fontSize={14} fontWeight={400} variant="caption">
                Feedback submitted by platform user.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Category
              </Typography>
              <PFMInput text={category} />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Feedback
              </Typography>
              <Stack flex={1} spacing={"16px"}>
                <PFMInput text={content} fullWidth multiline rows={8} />
                <Stack>
                  <Typography fontSize={18} fontWeight={500}>
                    Media
                  </Typography>
                  <Typography fontSize={14} fontWeight={300} variant="caption">
                    Screenshots, images, or videos.
                  </Typography>

                  <Stack spacing="8px">
                    {media?.map((f) => (
                      <Stack
                        onClick={() => window.open(f.url)}
                        alignItems={"center"}
                        direction={"row"}
                        sx={{
                          background: "#333",
                          borderRadius: "12px",
                          cursor: "pointer",
                          position: "relative",
                          height: "50px",
                          p: "8px",
                        }}
                      >
                        {f.type.startsWith("image/") && (
                          <img
                            alt={f.name}
                            src={f.url}
                            style={{
                              height: "100%",
                              maxWidth: "100px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                        {f.type.startsWith("video/") && (
                          <ReactPlayer
                            controls
                            height={"100%"}
                            style={{ maxWidth: "100px" }}
                            url={f.url}
                          />
                        )}
                        <Stack
                          flex={1}
                          alignItems={"start"}
                          sx={{ px: "16px" }}
                        >
                          <Typography sx={{ pl: "12px" }}>{f.name}</Typography>
                          <Typography
                            sx={{ pl: "12px", color: "#FFF9" }}
                            fontSize={12}
                          >
                            {Math.ceil(f.size / 1024)}KB
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Submitted by
              </Typography>
              <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                <Avatar src={user?.image} alt={user?.name}>
                  {user?.name[0]}
                </Avatar>
                <Stack>
                  <Typography>{user?.name}</Typography>
                  <Typography variant="caption" fontSize={12}>
                    {user?.email}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Submitted on
              </Typography>
              <Typography variant="caption" fontSize={12}>
                {moment
                  .unix(props.data?.created!)
                  .format("MMMM DD, YYYY hh:mm A")}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
