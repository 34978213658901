import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import {
  IC_CAMERA,
  IC_CHART,
  IC_HASH,
  IC_NOTEBOOK,
  IC_TRAVEL,
  IC_TROPHY,
} from "../../assets/ui";
import PaymentSystemCard from "./components/PaymentSystemCard";

export default function Paymentsystem() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const theme = useTheme();
  return (
    <Stack alignItems={"center"} justifyContent={"center"}>
      <Typography
        sx={{
          width: "430px",
          fontWeight: 300,
          textAlign: "center",
          fontSize: "50px",
          [theme.breakpoints.down("desktop")]: { fontSize: "40px" },

          [theme.breakpoints.down("tablet")]: {
            fontSize: "30px",
            padding: "8px",
          },
        }}
      >
        Payment system for every industry
      </Typography>
      <Stack
        spacing={"32px"}
        sx={{
          [theme.breakpoints.down("desktop")]: {
            fontSize: "40px",
          },

          [theme.breakpoints.down("tablet")]: {
            fontSize: "35px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Stack
          direction={"row"}
          spacing={"32px"}
          sx={{
            [theme.breakpoints.down("desktop")]: {},

            [theme.breakpoints.down("tablet")]: {
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              spacing: "32px",
            },
          }}
        >
          <PaymentSystemCard
            imgSrc={IC_CAMERA}
            title="Creative Arts & Media"
            color="#48F"
            description="Perfect for digital artists, photographers, and content creators looking to sell their work and offer exclusive access"
          />
          <PaymentSystemCard
            imgSrc={IC_CHART}
            title="Digital Products & SaaS"
            color="#48F"
            description="Enables software developers and product creators to sell digital assets, license keys, trial access, and software subscriptions."
          />
          <PaymentSystemCard
            imgSrc={IC_NOTEBOOK}
            title="Education & E-Learning"
            color="#48F"
            description="Ideal for educators and institutions offering paid courses, tutorials, and learning materials."
          />
        </Stack>
        <Stack
          direction={"row"}
          spacing={"32px"}
          sx={{
            [theme.breakpoints.down("desktop")]: {},

            [theme.breakpoints.down("tablet")]: {
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              spacing: "32px",
            },
          }}
        >
          <PaymentSystemCard
            imgSrc={IC_TROPHY}
            title="Gaming & Esports"
            color="#48F"
            description="Helps streamers and gamers to monetize content, receive tips, and offer exclusive guides."
          />
          <PaymentSystemCard
            imgSrc={IC_HASH}
            title="Community Building"
            color="#48F"
            description="Facilitates community building through affiliation tools, rewarding each user for every successful transactions through their referrals"
          />
          <PaymentSystemCard
            imgSrc={IC_TRAVEL}
            title="Consulting & Professional Services"
            color="#48F"
            description="Provides consultants, coaches, and advisors with an easy way to charge for sessions, resources, and ongoing support."
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
