import React from "react";
import { Stack, Typography } from "@mui/material";
import Header from "../header";
import Footer from "../footer";
import GoToTop from "../fab";
import ContactUs from "../contact";
import DualHeading from "../../../components/DualHeading";

export default function TermsPage() {
  const TERMS = [
    {
      title: "Modification of Terms",
      text: "Cashifybot reserves the right to update or modify the terms and conditions at any time. Users will be notified of changes, and continued use of the platform implies acceptance of the revised terms.",
    },
    {
      title: "Content Guidelines",
      text: "Users are strictly prohibited from selling or promoting content that violates Telegram's or Stripe's terms, including but not limited to explicit adult material.",
    },
    {
      title: "User Privacy",
      text: "Cashifybot users must uphold the privacy of their Telegram subscribers. Personal information, especially payment details and associated emails provided through Stripe, must be treated with utmost confidentiality.",
    },
    {
      title: "Email Communications",
      text: `By creating an account on our platform, you agree to receive periodic newsletters related to our product. These emails are intended to keep you informed about updates, features, and relevant information about our services. You can opt out of receiving these communications at any time by clicking the "Unsubscribe" link provided in each email or by updating your preferences in your account settings. Please note that even if you opt out of marketing emails, you may still receive transactional emails necessary for the operation of your account.`,
    },
    {
      title: "Lawful Use",
      text: "Users must abide by all applicable laws and regulations while using Cashifybot. Any unlawful or malicious activities are strictly prohibited.",
    },
    {
      title: "Liability",
      text: "Cashifybot is not liable for the content shared or transactions conducted by users. Users are solely responsible for the legality and appropriateness of their actions on the platform.",
    },
    {
      title: "Service Interruptions",
      text: "Cashifybot reserves the right to interrupt, suspend, or terminate its services at any time for maintenance, upgrades, or any other reasons. Users will be notified whenever possible.",
    },
    {
      title: "Indemnification",
      text: "Users agree to indemnify and hold Cashifybot harmless from any claims, losses, or damages arising from their use of the platform.",
    },
    {
      title: "Dispute Resolution",
      text: "Any disputes arising between Cashifybot and its users will be resolved through arbitration, following the laws and regulations applicable to such matters.",
    },
    {
      title: "Warranty Disclaimer",
      text: "Your use of the Service is at your sole risk. The Service is provided on an 'AS IS' and 'AS AVAILABLE' basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.",
    },
    {
      title: "External Links",
      text: "Our Service may contain links to third-party web sites or services that are not owned or controlled by Cashifybot. Cashifybot has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Cashifybot shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.",
    },
  ];

  return (
    <Stack sx={{ pt: "148px" }}>
      <Header />
      <Stack alignItems={"center"}>
        <Stack
          sx={{ maxWidth: "50%", py: "32px", minHeight: "100vh" }}
          justifyContent={"center"}
        >
          <DualHeading
            h1="Terms & "
            h2="Conditions"
            sx={{ fontSize: "26px" }}
            h2sx={{ color: "#48F" }}
          />
          <DualHeading
            h1="Last updated: "
            h2="August 3rd, 2024"
            sx={{ fontSize: "16px" }}
            h2sx={{ color: "#48F" }}
          />
          <br />
          <br />
          <Typography>
            By accessing or using the Service you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Service.
            <br />
            <br />
            Please read these Terms and Conditions ("Terms", "Terms and
            Conditions") carefully before using the Cashifybot, or
            https://cashifybot.com website, herein referred as the "Service"
            operated by CASHIFYBOT LTD ("us", "we", or "our").
            <br />
            <br />
            Your access to and use of the Service is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who access or use the Service.
          </Typography>
          <br />
          <br />
          <Stack spacing={"24px"}>
            {TERMS.map((t) => (
              <DualHeading
                sx={{ fontSize: "16px" }}
                h1={t.title}
                h1sx={{ color: "#48FD" }}
                h2={": "}
                h3sx={{ color: "#DDD" }}
                h3={t.text}
              />
            ))}
          </Stack>
          <br />
          <br />
          <Typography>
            By using Cashifybot, users agree to these terms and conditions. It
            is recommended to periodically review the terms for any updates or
            changes. Failure to comply with these terms may result in account
            suspension or termination.
          </Typography>
        </Stack>
      </Stack>
      <ContactUs />
      <Footer />
      <GoToTop />
    </Stack>
  );
}
