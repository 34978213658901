import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { Stop } from "@mui/icons-material";

import {
  IC_BROADCAST,
  IC_CODE,
  IC_REFRESH,
  IC_UESR,
  IC_USER_REMOVE,
} from "../../assets/ui";
import { motion } from "framer-motion";
import {
  rtmGetBroadcastStats,
  rtmGetBroadcasts,
  rtmStopBroadcast,
} from "../../core/api/user";
import CreateBroadcastDialog from "../../dialogs/CreateBroadcast";
import { rtmGetProfile } from "../../core/api";
import { Broadcast } from "../../types/Broadcast";
import { PaginatedResponse } from "../../types/PaginatedResponse";

export default function SectionUserBroadcast() {
  const [, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const [bouncedUsers, setBouncedUsers] = useState(0);
  const [subscribedUsers, setSubscribedUsers] = useState(0);
  const [codeUsers, setCodeUsers] = useState(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<PaginatedResponse<Broadcast>>();

  async function loadOnce() {
    try {
      setBusy(true);
      const prof = await rtmGetProfile();
      setProfile(prof);
      const stats = await rtmGetBroadcastStats();
      if (stats) {
        setBouncedUsers(stats.bouncedUsers);
        setCodeUsers(stats.codeUsers);
        setSubscribedUsers(stats.subscribedUsers);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function load(_page: number) {
    try {
      //Load data
      const _bc = await rtmGetBroadcasts(_page);
      if (_bc) {
        setData(_bc);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.error(err);
    }
  }

  async function stopBc(bcId: string) {
    try {
      //Load data
      setBusy(true);
      await rtmStopBroadcast(bcId);
      load(data?.currentPage || 0);
    } catch (err: any) {
      enqueueSnackbar("Error updating data. ", { variant: "error" });
      console.error(err);
    }
    setBusy(false);
  }

  function showCreateDialog() {
    const modal = showModal(CreateBroadcastDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          load(data?.currentPage || 0);
        }
      },
    });
  }

  useEffect(() => {
    setSection(`Broadcast`);
    setSectionDescription(
      `Send broadcast messages to users who interacted with your bot.`
    );
    loadOnce().then(() => load(0));

    const timeout = setInterval(load, 5000);

    return () => {
      clearInterval(timeout);
    };
  }, []);

  function RenderBroadcast(bc: Broadcast) {
    return (
      <TableRow>
        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {bc.type === "instant"
              ? "Instant"
              : moment.unix(bc.schedule!).fromNow()}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing="4px">
            {bc.audience.bouncedUsers && (
              <Chip
                label="Bounced Users"
                size="small"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              />
            )}
            {bc.audience.codeUsers && (
              <Chip
                label="Access Code"
                size="small"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              />
            )}
            {bc.audience.subscribers && (
              <Chip
                label="Subscribers"
                size="small"
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              />
            )}
          </Stack>
        </TableCell>

        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {bc.content.substring(0, 20) + "..."}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(bc.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(bc.updated).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {bc.reached.length} / {bc.users}{" "}
          {bc.failed.length > 0 ? " / " + bc.failed.length + " failed" : ""}
        </TableCell>

        <TableCell>
          {/* Use count reached  */}
          <Chip
            label={bc.status}
            variant="identifier"
            size="small"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          />
        </TableCell>

        <TableCell align="right">
          {bc.status !== "complete" && bc.status !== "stopped" && (
            <IconButton
              onClick={() => stopBc(bc.id)}
              disabled={busy}
              color="error"
              title="Stop"
            >
              <Stop />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        flex: 1,
        display: "flex",
        padding: "12px",
        gap: mobile ? "14px" : "32px",
        flexDirection: "column",
      }}
    >
      <Stack
        direction={"row"}
        rowGap={mobile ? "12px" : "24px"}
        columnGap={mobile ? "12px" : "24px"}
        flexWrap={"wrap"}
      >
        <motion.div
          style={{ width: mobile ? "100%" : undefined }}
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.1, delay: 0.1 }}
          exit={{ scale: 0 }}
        >
          <Paper
            sx={{
              minHeight: "180px",
              minWidth: "300px",
              background: "#FFF1",
              borderRadius: "8px",
              border: "1px solid #FFF0",
              transition: "all .3s",
              ":hover": {
                border: "1px solid #48DA",
              },
              [theme.breakpoints.down("md")]: {
                minWidth: "100%",
                width: "100%",
                minHeight: "auto",
              },
            }}
          >
            <Stack sx={{ p: "24px" }} spacing={"24px"}>
              <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                <SvgIcon
                  sx={{
                    background: "#48D",
                    height: "48px",
                    width: "48px",
                    p: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <IC_UESR />
                </SvgIcon>
                <Typography fontWeight={500} fontSize={20}>
                  Subscribed Users
                </Typography>
              </Stack>
              <Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontWeight={500} fontSize={24}>
                    {subscribedUsers}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </motion.div>

        <motion.div
          style={{ width: mobile ? "100%" : undefined }}
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.1, delay: 0.3 }}
          exit={{ scale: 0 }}
        >
          <Paper
            sx={{
              minHeight: "180px",
              minWidth: "300px",
              background: "#FFF1",
              borderRadius: "8px",
              border: "1px solid #FFF0",
              transition: "all .3s",
              ":hover": {
                border: "1px solid #025",
              },
              [theme.breakpoints.down("md")]: {
                minWidth: "100%",
                width: "100%",
                minHeight: "auto",
              },
            }}
          >
            <Stack sx={{ p: "24px" }} spacing={"24px"}>
              <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                <SvgIcon
                  sx={{
                    background: "#025",
                    height: "48px",
                    width: "48px",
                    p: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <IC_CODE />
                </SvgIcon>
                <Typography fontWeight={500} fontSize={20}>
                  Access Code Users
                </Typography>
              </Stack>
              <Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontWeight={500} fontSize={24}>
                    {codeUsers}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </motion.div>

        <motion.div
          style={{ width: mobile ? "100%" : undefined }}
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.1, delay: 0.4 }}
          exit={{ scale: 0 }}
        >
          <Paper
            sx={{
              minHeight: "180px",
              minWidth: "300px",
              background: "#FFF1",
              borderRadius: "8px",
              border: "1px solid #FFF0",
              transition: "all .3s",
              ":hover": {
                border: "1px solid #F75A",
              },
              [theme.breakpoints.down("md")]: {
                minWidth: "100%",
                width: "100%",
                minHeight: "auto",
              },
            }}
          >
            <Stack sx={{ p: "24px" }} spacing={"24px"}>
              <Stack direction={"row"} spacing={"18px"} alignItems={"center"}>
                <SvgIcon
                  sx={{
                    background: "#F75",
                    height: "48px",
                    width: "48px",
                    p: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <IC_USER_REMOVE />
                </SvgIcon>
                <Typography fontWeight={500} fontSize={20}>
                  Bounced Users
                </Typography>
              </Stack>
              <Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontWeight={500} fontSize={24}>
                    {bouncedUsers}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </motion.div>
      </Stack>

      {mobile && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            [theme.breakpoints.down("md")]: {
              flexDirection: "row",
              gap: "8px",
            },
          }}
        >
          <Button
            variant="contained"
            disabled={busy}
            onClick={() => load(data?.currentPage || 0)}
            color="secondary"
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
          <Button
            disabled={busy}
            variant="contained"
            color="primary"
            startIcon={
              <SvgIcon>
                <IC_BROADCAST />
              </SvgIcon>
            }
            onClick={showCreateDialog}
          >
            Create Broadcast
          </Button>
        </Stack>
      )}

      <PFMTable
        sx={{ flex: 1 }}
        title="History"
        description="List of all the broadcasts you have sent from the bot."
        titleBadge={data?.total + " broadcasts"}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                disabled={busy}
                onClick={() => load(data?.currentPage || 0)}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
              <Button
                disabled={busy}
                variant="contained"
                color="primary"
                startIcon={
                  <SvgIcon>
                    <IC_BROADCAST />
                  </SvgIcon>
                }
                onClick={showCreateDialog}
              >
                Create Broadcast
              </Button>
            </>
          )
        }
        tableHead={
          <TableRow>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Schedule
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Audience
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Message
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Created
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Updated
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Progress
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Status
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        }
        tableBody={<>{data?.data?.map((d) => RenderBroadcast(d))}</>}
      />
    </motion.div>
  );
}
