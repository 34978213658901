/**
 * Defines the roles of the user accounts on Cashify.
 */

export enum Role {
  /**
   * Standard user/customer.
   */
  User = "user",

  /**
   * Administrator can manage the entire platform through admin portal.
   */
  Administrator = "administrator",

  /**
   * User is a business affiliate, who only have access to affiliate tools.
   */
  Affiliate = "affiliate",

  /**
   * User is a blog writer who can write blog posts.
   */
  Blogger = "blogger",
}
