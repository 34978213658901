import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import LinkButton from "../../components/LinkButton";
import { LOGO_HEADER } from "../../assets/brand";
import { useNavigate } from "react-router";

export default function Footer() {
  const desktop = useMediaQuery("(min-width: 720px)");

  const nav = useNavigate();
  const theme = useTheme();
  return (
    <Stack
      id="footer"
      sx={{
        background:
          " linear-gradient(90deg, rgba(139, 90, 219, 0.80) 0%, rgba(38, 164, 227, 0.80) 100%)",
        padding: "48px 24px 48px 24px",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          padding: "24px 18px ",
        },
      }}
    >
      <Stack
        spacing="32px"
        sx={{
          maxWidth: "1280px",
          width: "100%",
          alignSelf: "center",
          [theme.breakpoints.down("tablet")]: {
            padding: "48px 32px",
            gap: "24px",
          },
        }}
      >
        {/* The logo, its menu, and subscription container  */}
        <Stack
          sx={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            //
            [theme.breakpoints.down("desktop")]: {
              flexDirection: "row",
              justifyContent: "space-around",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              justifyContent: "center",
              gap: "32px",
            },
          }}
        >
          <Stack alignItems={desktop ? undefined : "center"}>
            <img
              src={LOGO_HEADER}
              alt="cashify logo"
              style={{
                height: "79px",
                width: "187px",
                objectFit: "contain",
                alignSelf: desktop ? "flex-start" : "center",
              }}
            />
            <Typography
              width={"354px"}
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                fontFamily: "Inter",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "13px",
                  textAlign: "center",
                },
              }}
            >
              Cashifybot and its logo are registered trademarks of CASHIFYBOT
              LTD, Company No. 15776775, United Kingdom.
            </Typography>
          </Stack>
          <Stack
            alignItems={desktop ? "start" : "center"}
            justifyContent={desktop ? "flex-start" : "center"}
            sx={{
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: { gap: "40px" },
            }}
          >
            <nav
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "8px",
              }}
            >
              {" "}
              <LinkButton
                observable="Home"
                label="Home"
                onClick={() => {
                  if (!window.document.getElementById("Home")) nav("/Home");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("Home")?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton
                observable="about"
                label="About Us"
                onClick={() => {
                  if (!window.document.getElementById("about")) nav("/");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("about")?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton
                label="Features"
                observable="features"
                onClick={() => {
                  if (!window.document.getElementById("features")) nav("/");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("features")
                        ?.offsetTop,
                    });
                  }
                }}
              />
              {/* <LinkButton
                observable="Testimonials"
                label="Testimonials"
                onClick={() => {
                  if (!window.document.getElementById("Testimonials"))
                    nav("/#Testimonials");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("Testimonials")
                        ?.offsetTop,
                    });
                  }
                }}
              /> */}
              <LinkButton
                observable="pricing"
                label="Pricing"
                onClick={() => {
                  if (!window.document.getElementById("pricing"))
                    nav("/#pricing");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("pricing")?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton label="Terms" href="/terms" />
              <LinkButton label="Privacy" href="/privacy" />
            </nav>
          </Stack>
          <Stack>
            <nav
              style={{
                alignItems: "flex-end",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "8px",
              }}
            >
              <LinkButton
                label="Documentation"
                href="https://documenter.getpostman.com/view/23844318/2sA3BobCFF"
              />
              <LinkButton
                label="Tutorials"
                href="https://youtube.com/channel/UCewTH2sb2knfPGlJC7KTWGA"
              />
              <LinkButton
                label="Telegram Channel"
                href="https://t.me/CashifybotOfficial"
              />
              <LinkButton label="Help" href="https://t.me/CashifySupportBot" />
            </nav>
          </Stack>
        </Stack>

        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            flexDirection: "row",
          }}
        >
          <Typography textAlign={"center"}>
            Copyright &copy; 2024. All rights reserved.{" "}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
