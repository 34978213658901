import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aNotifications,
  aProfile,
} from "../../core/states";
import { enqueueSnackbar } from "notistack";
import { motion } from "framer-motion";
import {
  rtmGetNotifications,
  rtmGetNotificationsCount,
  rtmGetProfile,
  rtmUpdateNotification,
} from "../../core/api";
import {
  Badge,
  Button,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";
import PFMTable from "../../components/PFMTable";
import {
  IC_AFFILIATE,
  IC_ALERT,
  IC_CREDIT_CARD,
  IC_UESR,
} from "../../assets/ui";
import moment from "moment";
import { Notification } from "../../types/Notification";

export default function SectionUserNotifications() {
  const [, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [unreadCount] = useRecoilState(aNotifications);

  const [, setBusy] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [notiCount, setNotiCount] = useState(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [filter, setFilter] = useState<"all" | "read" | "unread">("all");

  const [data, setData] = useState<Notification[]>([]);

  async function load(page: number) {
    try {
      setBusy(true);
      const _notiCount = await rtmGetNotificationsCount("all");
      setNotiCount(_notiCount);
      const _data = await rtmGetNotifications(filter, page);
      if (_data) {
        setData(_data.data);
        setPageNumber(_data.currentPage);
        setDataCount(_data.total);
        setPageCount(_data.pageCount);
      }
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  async function updateNotification(
    id: "all" | string,
    status: "read" | "unread"
  ) {
    try {
      setBusy(true);
      await rtmUpdateNotification(id, status);
      load(pageNumber);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Notifications`);
    setSectionDescription(
      `View your notifications regarding subscriptions, payments, and bot.`
    );
    load(0);
  }, []);

  useEffect(() => {
    load(pageNumber);
  }, [filter, unreadCount]);

  function RenderNotification(n: Notification) {
    return (
      <TableRow
        sx={{
          height: "60px",
          "& .MuiButton-root": {
            opacity: 0,
            transition: "all .2s",
          },
          ":hover": {
            "& .MuiButton-root": {
              opacity: 1,
            },
          },
        }}
      >
        {/* Icon  */}
        <TableCell>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              gap: "32px",
              [theme.breakpoints.down("md")]: {
                gap: "8px",
              },
            }}
          >
            <Badge variant="dot" color={n.read ? undefined : "primary"} />
            <SvgIcon
              color={
                n.type === "error"
                  ? "error"
                  : n.type === "warning"
                  ? "warning"
                  : "primary"
              }
            >
              {n.type === "info" ||
              n.type === "error" ||
              n.type === "warning" ? (
                <IC_ALERT />
              ) : n.type === "member" ? (
                <IC_UESR />
              ) : n.type === "billing" ? (
                <IC_CREDIT_CARD />
              ) : (
                <IC_AFFILIATE />
              )}
            </SvgIcon>
            <Typography
              color={n.read ? "#94969C" : undefined}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              {n.content}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(n.timestamp).format("MMM DD, YYYY hh:mm:ss A")} (
            {moment.unix(n.timestamp).fromNow()})
          </Typography>
        </TableCell>

        {!mobile && (
          <TableCell>
            <Button
              onClick={() => {
                updateNotification(n.id, n.read ? "unread" : "read");
              }}
              variant="contained"
              size="small"
              sx={{
                width: "150px",
                background: "#48F3",
                color: "#48F",
                ":hover": { background: "#48F5" },
              }}
            >
              {n.read ? "Mark as unread" : "Mark as read"}
            </Button>
          </TableCell>
        )}
      </TableRow>
    );
  }

  return (
    <motion.div
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "32px",
      }}
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
    >
      {unreadCount > 0 && (
        <Stack direction="row" spacing={"32px"} alignItems={"center"}>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            You have {unreadCount} unread notifications.
          </Typography>
          <Button
            onClick={() => updateNotification("all", "read")}
            variant="contained"
            sx={{
              background: "#48F3",
              color: "#48F",
              ":hover": { background: "#48F5" },
              [theme.breakpoints.down("md")]: {
                fontSize: 12,
              },
            }}
          >
            Mark all as read
          </Button>
        </Stack>
      )}

      <Stack>
        <StyledToggleButtonGroup
          sx={{ alignSelf: "flex-start" }}
          exclusive
          value={filter}
          onChange={(c, e) => setFilter(e)}
        >
          <ToggleButton value={"all"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{
                px: "16px",
                [theme.breakpoints.down("md")]: {
                  px: "8px",
                },
              }}
            >
              {filter === "all" && (
                <Badge
                  variant="dot"
                  color={filter === "all" ? "success" : undefined}
                ></Badge>
              )}
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                All
              </Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"unread"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{
                px: "16px",
                [theme.breakpoints.down("md")]: {
                  px: "8px",
                },
              }}
            >
              {filter === "unread" && (
                <Badge
                  variant="dot"
                  color={filter === "unread" ? "success" : undefined}
                ></Badge>
              )}
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                Unread
              </Typography>
            </Stack>
          </ToggleButton>

          <ToggleButton value={"read"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{
                px: "16px",
                [theme.breakpoints.down("md")]: {
                  px: "8px",
                },
              }}
            >
              {filter === "read" && (
                <Badge
                  variant="dot"
                  color={filter === "read" ? "success" : undefined}
                ></Badge>
              )}
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                Read
              </Typography>
            </Stack>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Stack>

      <Stack flex={1} sx={{ position: "relative", overflow: "hidden" }}>
        {notiCount === 0 && (
          <Stack
            sx={{ flex: 1, width: "100%" }}
            justifyContent={"center"}
            spacing={"8px"}
            alignItems={"center"}
          >
            <Typography
              textAlign={"center"}
              fontSize={32}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 16,
                },
              }}
            >
              It's so quiet in here...
            </Typography>
            <Typography
              fontSize={18}
              textAlign={"center"}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                },
              }}
            >
              You do not have any notifications yet. Check back later!
            </Typography>
          </Stack>
        )}
        {notiCount > 0 && (
          <PFMTable
            sx={{ flex: 1 }}
            paginated
            nextPage={(pn) => load(pn)}
            prevPage={(pn) => load(pn)}
            pageNumber={pageNumber}
            totalPages={pageCount}
            tableBody={data.map((d) => RenderNotification(d))}
          />
        )}
      </Stack>
    </motion.div>
  );
}
