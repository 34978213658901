import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../components/LoadingOverlay";
import { RestRequestPasswordReset } from "../../core/rest";
import { LOGO_CASHIFYBOT, LOGO_SIDEBAR } from "../../assets/brand";
import { IC_EMAIL } from "../../assets/ui";
import { logEvent } from "firebase/analytics";
import { FbAnalytics } from "../../authentication/firebase";
import { Player } from "@lottiefiles/react-lottie-player";

export default function PageRecovery() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const [linkSent, setLinkSent] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);

  async function send() {
    try {
      if (!email) {
        setError("email");
        return;
      }
      setBusy(true);
      await RestRequestPasswordReset(email);
      enqueueSnackbar("Password reset email has been sent.");
      setLinkSent(true);
    } catch (err: any) {}
    setBusy(false);
  }
  useEffect(() => {
    logEvent(FbAnalytics, "page_view", { page: "recovery" });
  }, []);

  if (!desktop) {
    return (
      <Stack
        sx={{ minHeight: "100vh", py: "12px" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          style={{
            height: "64px",
            objectFit: "contain",
          }}
          alt="Cashify Logo"
          onClick={() => window.open("/")}
          src={LOGO_SIDEBAR}
        />
        {/* Shown when the link has been sent */}
        {linkSent && (
          <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
            <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
              <SvgIcon sx={{ fontSize: "96px" }}>
                <IC_EMAIL />
              </SvgIcon>
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6" }}
              >
                Check your email!
              </Typography>
              <Typography
                textAlign={"center"}
                width={"360px"}
                sx={{ fontSize: "16px", color: "#94969C" }}
              >
                We've sent a verification link to {email}.
              </Typography>
            </Stack>
            <FormGroup>
              <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
                <Stack
                  justifyContent={"center"}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ mt: "12px" }}
                  spacing="8px"
                >
                  <Typography color="#94969C" fontSize={"14px"}>
                    Didn't receive an email?
                  </Typography>
                  <Link
                    onClick={send}
                    underline="none"
                    sx={{ cursor: "pointer" }}
                    fontSize={"14px"}
                  >
                    Resend
                  </Link>
                </Stack>
                <Stack
                  justifyContent={"start"}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ mt: "12px" }}
                  spacing="8px"
                >
                  <Link href="/login" underline="none" fontSize={"14px"}>
                    &lt;- Back to log in
                  </Link>
                </Stack>
              </Stack>
            </FormGroup>
          </Stack>
        )}
        {/* Shown when the link has not yet been sent  */}
        {!linkSent && (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
              <Typography
                fontSize={"20px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6" }}
              >
                Forgot password?
              </Typography>
              <Typography
                textAlign={"center"}
                sx={{ fontSize: "14px", color: "#94969C" }}
              >
                No worries. We will send you reset instructions.
              </Typography>
            </Stack>
            <Stack
              spacing="18px"
              sx={{ mt: "24px", width: "90%" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <PFMInput
                text={email}
                onUpdate={(t) => setEmail(t)}
                errorText={
                  error === "email" ? "Please enter a valid email." : ""
                }
                label="Email"
                important
                disabled={busy}
                placeholder="Enter your email "
                startIcon={IC_EMAIL}
                onReturn={send}
              />
              <Button
                onClick={send}
                sx={{ mt: "4px", height: "42px" }}
                size="large"
                disabled={busy}
                variant="contained"
              >
                {busy && (
                  <Player
                    autoplay
                    loop
                    src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                    style={{ height: "100px" }}
                  />
                )}
                {!busy && "Reset password"}
              </Button>
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <Stack sx={{ height: "100vh" }}>
      <img
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "100px",
        }}
        alt="Cashify Logo"
        src={LOGO_SIDEBAR}
      />
      {/* Shown when the link has been sent */}
      {linkSent && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <SvgIcon sx={{ fontSize: "96px" }}>
              <IC_EMAIL />
            </SvgIcon>
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Check your email!
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              We've sent a verification link to {email}.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Typography color="#94969C" fontSize={"14px"}>
                  Didn't receive an email?
                </Typography>
                <Link
                  onClick={send}
                  underline="none"
                  sx={{ cursor: "pointer" }}
                  fontSize={"14px"}
                >
                  Resend
                </Link>
              </Stack>
              <Stack
                justifyContent={"start"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
      {/* Shown when the link has not yet been sent  */}
      {!linkSent && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Forgot password?
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              No worries. We will send you reset instructions.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <PFMInput
                text={email}
                onUpdate={(t) => setEmail(t)}
                errorText={
                  error === "email" ? "Please enter a valid email." : ""
                }
                label="Email"
                important
                disabled={busy}
                placeholder="Enter your email "
                startIcon={IC_EMAIL}
                onReturn={send}
              />
              <Button
                onClick={send}
                sx={{ mt: "4px", height: "42px" }}
                size="large"
                disabled={busy}
                variant="contained"
              >
                {busy && (
                  <Player
                    autoplay
                    loop
                    src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                    style={{ height: "100px" }}
                  />
                )}
                {!busy && "Reset password"}
              </Button>
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
    </Stack>
  );
}
