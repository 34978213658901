import { Stack, SxProps, Typography } from "@mui/material";
import React, { ReactElement } from "react";

function ScreenShotsText(props: {
  discription?: string;
  name?: string;
  avatar?: any;
  mainSx?: SxProps;
  sx?: SxProps;
  dot?: ReactElement;
}) {
  return (
    <Stack
      lineHeight={"normal"}
      gap={"10px"}
      alignItems={"flex-start"}
      padding={"16px"}
      borderRadius={"30px 30px 30px 0px"}
      sx={props.mainSx}
      direction={"row"}
    >
      <Stack>
        <Typography>
          <img src={props.avatar} alt="" />
          {/* <IC_AVATAR /> */}
        </Typography>
      </Stack>
      <Stack sx={props.sx}>
        <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
          {/* Shahid Ali */}
          {props.name}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "18px",
            letterSpacing: " -0.36px",
          }}
        >
          {props.discription}
          {/* How can a freelance developer like me offer a monthly retainer for my
          services? */}
        </Typography>
        <Typography>{props.dot} </Typography>
      </Stack>
      {/* <Typography
        sx={{
          padding: "16px 24px 16px 24px",
          width: " 273px",
          color: "#FFF",
          fontSize: " 16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: "-0.32px",
          background: "#383588",
          borderRadius: "  30px 30px 30px 0px",
          display: "inline-flex",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        I’m a fitness trainer and I want to offer paid access to my workout
        plans, diet guides, and fitness videos
      </Typography> */}
    </Stack>
  );
}

export default ScreenShotsText;
