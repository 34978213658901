import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { LOGO_SIDEBAR } from "../assets/brand";

export default function ErrorPage() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      sx={{ height: "100vh", width: "100vw" }}
      alignItems={"center"}
      justifyContent={"center"}
      spacing={"24px"}
    >
      <Helmet>
        <title>Cashifybot | 404</title>
        <meta name="description" content="404 page not found." />
      </Helmet>
      {!desktop && (
        <img
          style={{
            height: "64px",
            objectFit: "contain",
          }}
          alt="Cashify Logo"
          onClick={() => window.open("/")}
          src={LOGO_SIDEBAR}
        />
      )}
      {desktop && (
        <img
          style={{
            position: "fixed",
            top: "64px",
            left: "80px",
            pointerEvents: "none",
            height: "100px",
          }}
          alt="Cashify Logo"
          src={LOGO_SIDEBAR}
        />
      )}
      <Stack alignItems={"center"}>
        <Typography component={"h1"} fontSize={40}>
          404
        </Typography>
        <Typography>The page you are looking for does not exist. </Typography>
      </Stack>
      <Stack direction={"row"} spacing={"8px"} sx={{ mt: "20px" }}>
        <Button size="large" href="/" variant="contained">
          Home
        </Button>
        <Button size="large" href="/dashboard" variant="outlined">
          Dashboard
        </Button>
      </Stack>
    </Stack>
  );
}
