import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import PFMInput from "../../components/PFMInput";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { IC_REFRESH, IC_SEARCH } from "../../assets/ui";
import { motion } from "framer-motion";
import { rtmGetPlatformTransactions } from "../../core/api/admin";
import { Transaction } from "../../types/Transaction";

export default function SectionAdminTransactions() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [data, setData] = useState<Transaction[]>([]);

  async function load(page: number) {
    try {
      setBusy(true);
      const _data = await rtmGetPlatformTransactions(page, search);
      if (_data) {
        setData(_data.data);
        setPageNumber(_data.currentPage);
        setDataCount(_data.total);
        setPageCount(_data.pageCount);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Platform Transactions`);
    setSectionDescription(`View the transactions for users' payments profile.`);
    load(0);
  }, []);

  function RenderData(u: Transaction) {
    const amnt = parseStripeAmount(u.amount);
    const sym = getCurrencySymbol(u.currency);
    return (
      <TableRow>
        <TableCell align="left">
          <Typography color="#94969C">
            {moment.unix(u.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">{u.type}</Typography>
        </TableCell>
        <TableCell>{u.invoiceId}</TableCell>
        <TableCell>
          <TableCell>{u.ownerId}</TableCell>
        </TableCell>
        <TableCell>
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      style={{ overflow: "hidden", flex: 1, display: "flex", padding: "12px" }}
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
    >
      <PFMTable
        sx={{ flex: 1 }}
        title="Transactions"
        description="List of all the transactions."
        paginated
        pageNumber={pageNumber}
        totalPages={pageCount}
        nextPage={(n) => load(n)}
        prevPage={(n) => load(n)}
        titleBadge={data.length + " Transactions"}
        actionButtons={
          <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
            <PFMInput
              placeholder="Search..."
              startIcon={IC_SEARCH}
              text={search}
              onUpdate={(t) => setSearch(t)}
              onReturn={() => {
                load(pageNumber);
              }}
            />
            <Button
              size="large"
              variant="contained"
              disabled={busy}
              onClick={() => load(pageNumber)}
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Invoice ID</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderData(d))}</>}
      />
    </motion.div>
  );
}
