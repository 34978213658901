import React, { useEffect, useState } from "react";
import {
  Avatar,
  Paper,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { aCurrentSection, aCurrentSectionDescription } from "../../core/states";
import { IC_AFFILIATE_BALANCE, IC_PAID, IC_PENDING } from "../../assets/ui";

import { enqueueSnackbar } from "notistack";
import { motion } from "framer-motion";
import { Payout } from "../../types/Affiliation/Payout";
import { User } from "../../types/User";
import { rtmListPayoutsAdmin } from "../../core/api/admin";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import moment from "moment";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import PayoutDetailsDialog from "../../dialogs/PayoutDetails";

export default function SectionAdminPayouts() {
  const [busy, setBusy] = useState(false);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [payouts, setPayouts] = useState<(Payout & { user: User })[]>([]);

  const [balances, setBalances] = useState("");
  const [pending, setPending] = useState("");
  const [paid, setPaid] = useState("");

  const { showModal } = useModal();

  async function load(page: number) {
    try {
      setBusy(true);
      const _payouts = await rtmListPayoutsAdmin();
      setPayouts(_payouts);
      // Calculate cards
      const _pendingPayouts = _payouts.filter((p) => p.status === "pending");
      const _paidPayouts = _payouts.filter((p) => p.status === "complete");
      // To get balances, we need to get affiliates
      const _pending = _pendingPayouts.reduce(
        (pv, cv, ci) => pv + cv.amount,
        0
      );
      const _paid = _paidPayouts.reduce((pv, cv, ci) => pv + cv.amount, 0);
      const _paidAmnt = parseStripeAmount(_paid);
      const _pendingAmt = parseStripeAmount(_pending);
      setPaid(`$${_paidAmnt.dollars}.${_paidAmnt.cents}`);
      setPending(`$${_pendingAmt.dollars}.${_pendingAmt.cents}`);
    } catch (err: any) {
      console.error("Error loading payouts data. ", err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  function RenderPayout(pay: Payout & { user: User }) {
    const amnt = parseStripeAmount(pay.amount);
    return (
      <TableRow
        onClick={() => {
          const _modal = showModal(PayoutDetailsDialog, {
            closeHandler(result) {
              _modal.destroy();
            },
            data: pay,
          });
        }}
        sx={{
          cursor: "pointer",
          ":hover": {
            background: "#FFF3",
          },
        }}
      >
        <TableCell>{pay.id}</TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={pay.user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {pay.user.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {pay.user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>{pay.status}</TableCell>
        <TableCell>
          {moment.unix(pay.created).format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell>
          {pay.method === "wise" ? "Wise Payments" : "Binance Pay"}
        </TableCell>
        <TableCell>
          {getCurrencySymbol(pay.currency)}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  useEffect(() => {
    setSection(`Payouts`);
    setSectionDescription(`View and manage pending and completed payouts.`);
    load(0);
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, translateX: -20 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 20 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            p: "12px",
            height: "100%",
            transition: "all 1s",
          }}
          spacing={"32px"}
        >
          <Stack
            direction={"row"}
            rowGap={"24px"}
            columnGap={"24px"}
            flexWrap={"wrap"}
          >
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.1 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  transition: "all .3s",
                  border: "1px solid #FFF0",
                  ":hover": {
                    border: "1px solid #48FA",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#48F",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_AFFILIATE_BALANCE />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Balances
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}>
                        $0.0
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.2 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  border: "1px solid #FFF0",
                  borderRadius: "8px",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #448A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#448",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_PENDING />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Pending
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}>
                        {pending}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.3 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  border: "1px solid #FFF0",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #0A5A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#0A5",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_PAID />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Total Paid
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}>
                        {paid}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
          </Stack>
          <PFMTable
            sx={{ p: 0, m: 0, bgcolor: "#FFF1", flex: 1 }}
            title="Payouts"
            description="List of all the payouts"
            tableHead={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            }
            tableBody={payouts.map((r) => RenderPayout(r))}
          />
        </Stack>
      </motion.div>
    </>
  );
}
