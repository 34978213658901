import React, { useEffect, useState } from "react";
import { DialogProps } from "./props";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import PFMRadio from "../components/PFMRadio";
import { enqueueSnackbar } from "notistack";

import { IC_LOCK_ADD } from "../assets/ui";
import { DateTimeField } from "@mui/x-date-pickers";
import { rtmCreateAccessCode, rtmGetTelegramChats } from "../core/api/user";
import { TelegramChat } from "../types/TelegramChat";

const TRIAL_DURATIONS = [
  { label: "1 Hour", seconds: 3600 },
  { label: "2 Hours", seconds: 3600 * 2 },
  { label: "4 Hours", seconds: 3600 * 4 },
  { label: "8 Hours", seconds: 3600 * 8 },
  { label: "12 Hours", seconds: 3600 * 12 },
  { label: "24 Hours", seconds: 3600 * 24 },
  { label: "2 Days", seconds: 3600 * 48 },
  { label: "5 Days", seconds: 3600 * 24 * 5 },
  { label: "7 Days", seconds: 3600 * 24 * 7 },
  { label: "14 Days", seconds: 3600 * 24 * 14 },
  { label: "30 Days", seconds: 3600 * 24 * 30 },
];

export default function CreateAccessCodeDialog(props: DialogProps<any>) {
  const [description, setDescription] = useState("");
  const [chats, setChats] = useState<AutocompleteOption[]>([]);
  const [selectedChats, setSelectedChats] = useState<AutocompleteOption[]>([]);
  const [code, setCode] = useState("");
  const [maxUses, setMaxUses] = useState(0);
  const [expiry, setExpiry] = useState<any>();
  const [trial, setTrial] = useState(false);
  const [trialDuration, setTrialDuration] = useState<any>();

  const [busy, setBusy] = useState(false);

  async function createSubscription() {
    try {
      // Validate
      if (!code) {
        enqueueSnackbar("Please enter a unique access code.", {
          variant: "error",
        });
        return;
      }
      if (!selectedChats || selectedChats.length === 0) {
        enqueueSnackbar("Please assign at least 1 chat.", {
          variant: "error",
        });
        return;
      }

      setBusy(true);
      const _chats: Partial<TelegramChat>[] = [];
      for (let p of selectedChats) {
        _chats.push({
          id: p.id,
          title: p.label,
        });
      }
      // Add the subscription
      await rtmCreateAccessCode({
        chats: _chats as any,
        code: code,
        description: description,
        expires: expiry ? expiry.unix() : undefined,
        max_uses: maxUses,
        trial: trial,
        trial_duration: trialDuration?.seconds || 0,
      });
      enqueueSnackbar("Access code has been created. ", { variant: "success" });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error creating a new access code. Error: " + err.message,
        {
          variant: "error",
        }
      );
      console.error(err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      setBusy(true);
      // Load the chats
      const _chats = await rtmGetTelegramChats();
      const opts: AutocompleteOption[] = [];
      if (_chats) {
        for (let c of _chats) {
          opts.push({
            label: c.title,
            icon: c.icon,
            id: c.id,
            username: c.username,
          });
        }
      }
      setChats(opts);
    } catch (err: any) {
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_LOCK_ADD />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Create Access Code
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Add a new access code to your bot
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px", width: "580px" }}>
          <Stack spacing={"24px"} sx={{ px: "24px" }}>
            <PFMInput
              label="Description"
              placeholder="Write a short description for your code..."
              text={description}
              onUpdate={(t) => setDescription(t)}
              disabled={busy}
            />
            <Stack>
              <PFMAutoComplete
                multiple
                key={chats.length}
                options={chats}
                label="Assign Chats"
                placeholder="Select chats.."
                onUpdate={(sel) => setSelectedChats(sel as any)}
                value={selectedChats}
                important
                helperText="Send a message in the channel after adding the bot, and make sure your bot has access to messages."
              />
              <Typography
                onClick={load}
                color="primary"
                sx={{
                  textDecoration: "underline",
                  px: "12px",
                  cursor: "pointer",
                }}
              >
                Refresh
              </Typography>
            </Stack>

            <PFMInput
              text={code}
              onUpdate={(p) => setCode(p)}
              label="Code"
              placeholder="Enter a unique access code"
              important
            />

            <PFMInput
              text={maxUses?.toString()}
              onUpdate={(p) =>
                parseInt(p) >= 0 ? setMaxUses(parseInt(p)) : setMaxUses(0)
              }
              label="Max Uses"
              placeholder="Number of times this code can be used"
            />

            <Stack spacing={"4px"}>
              <Typography>Expiry</Typography>
              <DateTimeField
                value={expiry}
                onChange={(e) => setExpiry(e as any)}
                variant="filled"
                disablePast
                size="small"
                clearable
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  borderRadius: "8px",
                  //height: "44px",
                  minHeight: "44px",
                  //maxHeight: "44px",
                  px: "0",
                }}
                inputProps={{
                  style: {
                    paddingRight: "14px",
                    paddingLeft: "14px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                }}
              />
            </Stack>

            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                Access Type
              </Typography>
              <RadioGroup radioGroup="accessKind">
                <PFMRadio
                  checked={trial}
                  onUpdate={(c) => setTrial(true)}
                  label="Trial"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
                <PFMRadio
                  checked={!trial}
                  onUpdate={(c) => setTrial(false)}
                  label="Free Forever"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
              </RadioGroup>
              {trial && (
                <PFMAutoComplete
                  options={TRIAL_DURATIONS}
                  value={trialDuration}
                  onUpdate={(v: any) => setTrialDuration(v)}
                  label="Trial Duration"
                  placeholder="Set trial duration..."
                  important
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={busy}
          onClick={props.closeHandler}
          color="secondary"
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={createSubscription}
          color="primary"
          variant="contained"
          fullWidth
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
