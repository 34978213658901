import React from "react";
import { Stack } from "@mui/material";
import Header from "./header";
import Hero from "./hero";
import Features from "./features";
import Demo from "./demo";
import Pricing from "./pricing";
import ContactUs from "./contact";
import Footer from "./footer";
import GoToTop from "./fab";
import { Helmet } from "react-helmet";
import Migration from "./migration";
import CookieConsentForm from "../../components/CookieConsent";
import { setCookie } from "../../core/helper";
import ScreenShot from "./screenshot";
import About from "./about";
import States from "./States";
import Paymentsystem from "./paymentsystem";
import FAQ from "./faq";
import TelegramHeading from "./TelegramHeading";
import GetStartBanner from "./GetStartBanner";
import CreaterText from "./CreaterText";
import LandingThemeManager from "./ThemeManager";
import Tools from "./components/Tools";
import ColorHeading from "./components/GetStart";
const ogData = {
  title: "Cashifybot | Telegram Subscriptions Bot",
  description:
    "Monetize your Telegram groups and channels effortlessly with our user-friendly subscription bot. Seamlessly manage subscriptions, subscribers, and analytics all in one easy-to-navigate web application.",
  url: "https://cashifybot.com",
  image: "https://cashifybot.com/brand/logo.png",
  siteName: "Cashifybot",
};
export default function LandingPage() {
  // Set cookie based on source
  const url = new URLSearchParams(window.location.search);
  if (url.has("src")) {
    setCookie("traffic_utm_", url.get("src")?.toString() || "", 30);
    if (url.get("src")?.toString() === "reddit") {
      (window as any).rdt("track", "PageVisit");
    } else if (url.get("src")?.toString() === "google") {
      (window as any).gtag?.("event", "page_view", {
        send_to: "AW-394810679/eb3hCK60t8gZELeqobwB",
      });
    }
  }
  return (
    <LandingThemeManager>
      <Stack sx={{ width: "100%" }}>
        <Helmet>
          <title>{ogData.title}</title>
          <meta name="description" content={ogData.description} />
          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={ogData.title} />
          <meta property="og:description" content={ogData.description} />
          <meta property="og:url" content={ogData.url} />
          <meta property="og:image" content={ogData.image} />
          <meta property="og:site_name" content={ogData.siteName} />
        </Helmet>
        <CookieConsentForm />
        <Header />
        <Hero />

        <ScreenShot />
        <CreaterText />
        <ColorHeading title="Introducing " title2="Cashifybot " />
        <About />
        <Tools />
        <Features />
        <Demo />
        <States />
        {/* <Testimonials /> */}
        <Paymentsystem />
        <ColorHeading
          title="Get started for free"
          description="No upfront fees. You only incur a small fee for each successful transaction."
          mainSx={{ padding: " 48px 0px" }}
          sx={{
            background:
              "conic-gradient(from -90deg at 50% 0%, #1B57F0 0deg, #894AB0 360deg);",
          }}
        />
        <Pricing />
        <TelegramHeading />
        <Migration />

        <FAQ />
        <GetStartBanner />
        <ContactUs />
        <Footer />
        <GoToTop />
      </Stack>
    </LandingThemeManager>
  );
}
