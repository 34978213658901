import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import PFMInput from "../../components/PFMInput";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { IC_INVOICE, IC_REFRESH, IC_SEARCH } from "../../assets/ui";
import { motion } from "framer-motion";
import { rtmGetInvoices } from "../../core/api/user";
import { Download } from "@mui/icons-material";
import { rtmGetProfile } from "../../core/api";
import { Invoice } from "../../types/Invoice";

export default function SectionUserInvoices() {
  const [, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [busy, setBusy] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [data, setData] = useState<Invoice[]>([]);

  async function load(page: number) {
    try {
      setBusy(true);
      const _data = await rtmGetInvoices(page, search);
      if (_data) {
        setData(_data.data);
        setPageNumber(_data.currentPage);
        setDataCount(_data.total);
        setPageCount(_data.pageCount);
      }
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Invoices`);
    setSectionDescription(`View the invoices created for your subscribers.`);
    load(0);
  }, []);

  function RenderData(u: Invoice) {
    const amnt = parseStripeAmount(
      u.source === "stripe"
        ? u.data?.amount_paid || u.data?.amount_due
        : u.data.amount.fiat.amount * 100
    );
    const sym = getCurrencySymbol(
      u.source === "stripe" ? u.data?.currency : u.data.amount.fiat.currency
    );
    let _fee = sym;
    if (u.source === "crypto") {
      _fee += u.data.fee?.fiat?.amount || "0.0";
    } else {
      const _stripeAmnt = parseStripeAmount(u.fee);
      _fee += _stripeAmnt.dollars + "." + _stripeAmnt.cents;
    }

    return (
      <TableRow>
        {!mobile && (
          <TableCell>
            <Chip
              label={u.id}
              variant="identifier"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(u.id);
                enqueueSnackbar("Invoice ID copied to clipboard!");
              }}
            />
          </TableCell>
        )}
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(u.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Avatar src={u.customerInfo?.image} />
            <Stack>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                {u.customerInfo?.name}
              </Typography>
              {u.customerInfo?.username && (
                <Typography
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: 8,
                    },
                  }}
                >
                  @{u.customerInfo?.username}
                </Typography>
              )}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {_fee}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          <Chip label={u.status} variant="identifier" size="small" />
        </TableCell>
        <TableCell>
          <Stack direction={"row"}>
            <IconButton
              title="View Invoice"
              onClick={() => {
                if (u.source === "stripe") {
                  window.open(u.data.hosted_invoice_url, "_blank");
                } else {
                  window.open(
                    "https://cashifybot.com/invoice/" + u.data.id,
                    "_blank"
                  );
                }
              }}
            >
              <SvgIcon>
                <IC_INVOICE />
              </SvgIcon>
            </IconButton>
            {u.source === "stripe" && (
              <IconButton
                title="Download PDF"
                onClick={() => {
                  if (u.source === "stripe") {
                    window.open(u.data.invoice_pdf, "_blank");
                  }
                }}
              >
                <SvgIcon>
                  <Download />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "12px",
      }}
    >
      {mobile && (
        <Stack direction="row" justifyContent={"flex-end"} spacing={"8px"}>
          <PFMInput
            placeholder="Search..."
            startIcon={IC_SEARCH}
            text={search}
            onUpdate={(t) => setSearch(t)}
            onReturn={() => {
              load(pageNumber);
            }}
          />
          <Button
            variant="contained"
            disabled={busy}
            onClick={() => load(pageNumber)}
            color="secondary"
            size="small"
            sx={{ width: "140px" }}
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
        </Stack>
      )}
      <PFMTable
        sx={{ flex: 1 }}
        title="Invoices"
        description="List of all the invoices."
        paginated
        pageNumber={pageNumber}
        totalPages={pageCount}
        nextPage={(pn) => load(pn)}
        prevPage={(pn) => load(pn)}
        titleBadge={data.length + " Invoices"}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
              <PFMInput
                placeholder="Search..."
                startIcon={IC_SEARCH}
                text={search}
                onUpdate={(t) => setSearch(t)}
                onReturn={() => {
                  load(pageNumber);
                }}
              />
              <Button
                size="large"
                variant="contained"
                disabled={busy}
                onClick={() => load(pageNumber)}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
            </Stack>
          )
        }
        tableHead={
          <TableRow>
            {!mobile && <TableCell>ID</TableCell>}
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Time
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Customer
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Fee
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Amount
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderData(d))}</>}
      />
    </motion.div>
  );
}
