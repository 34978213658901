import { Stack, SvgIcon } from "@mui/material";
import React from "react";

export default function IconContainer(props: any) {
  return (
    <Stack
      sx={{
        width: "48px",
        height: "48px",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <SvgIcon {...props}>{props.children}</SvgIcon>
    </Stack>
  );
}
