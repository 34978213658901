import React, { useEffect, useState } from "react";
import { DialogProps } from "./props";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import PFMRadio from "../components/PFMRadio";
import { enqueueSnackbar } from "notistack";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { IC_EDIT_CREDIT_CARD } from "../assets/ui";
import {
  rtmEditSubscription,
  rtmGetProducts,
  rtmGetTelegramChats,
} from "../core/api/user";
import { Subscription } from "../types/Subscription";
import { TelegramChat } from "../types/TelegramChat";
import { Product } from "../types/Product";

export default function EditSubscriptionDialog(
  props: DialogProps<Subscription>
) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [chats, setChats] = useState<AutocompleteOption[]>([]);
  const [selectedChats, setSelectedChats] = useState<AutocompleteOption[]>([]);
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("usd");
  const [kind, setKind] = useState<"onetime" | "recurring">("onetime");
  const [schedule, setSchedule] = useState("");
  const [products, setProducts] = useState<AutocompleteOption[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    AutocompleteOption[]
  >([]);

  const [trial, setTrial] = useState(0);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(false);

  async function updateSubscription() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a subscription name.", {
          variant: "error",
        });
        return;
      }
      if (
        (!selectedChats || selectedChats.length === 0) &&
        (!selectedProducts || selectedProducts.length === 0)
      ) {
        enqueueSnackbar("Please assign at least 1 chat or a product.", {
          variant: "error",
        });
        return;
      }
      if (!price) {
        enqueueSnackbar("Please enter the price for this subscription.", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const _chats: Partial<TelegramChat>[] = [];
      for (let p of selectedChats) {
        _chats.push({
          id: p.id,
        });
      }

      const _prods: Partial<Product>[] = [];
      for (let p of selectedProducts) {
        _prods.push({ id: p.id, name: p.label });
      }
      // Add the subscription
      await rtmEditSubscription({
        ...props.data,
        name: name,
        kind: kind,
        price: {
          amount: Math.floor(parseFloat(price) * 100),
          currency: currency,
        },
        chats: _chats as any,
        products: _prods as any,
        description: description,
        schedule:
          kind === "recurring" ? (schedule.toLowerCase() as any) : undefined,
        trial: kind === "recurring" ? trial : undefined,
      });
      enqueueSnackbar("Subscription has been updated. ", {
        variant: "success",
      });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error creating a new subscription. Error: " + err.message,
        {
          variant: "error",
        }
      );
    }
    setBusy(false);
  }

  async function load() {
    try {
      // Load the chats
      const _chats = await rtmGetTelegramChats();
      const opts: AutocompleteOption[] = [];
      const selOpts: AutocompleteOption[] = [];
      const selProds: AutocompleteOption[] = [];
      if (_chats) {
        for (let c of _chats) {
          opts.push({
            label: c.title,
            icon: c.icon,
            id: c.id,
            username: c.username,
          });
        }
      }
      setChats(opts);
      for (let p of props.data?.chats || []) {
        const _o = opts.find((o) => o.id === p.id);
        if (_o) {
          selOpts.push(_o);
        }
      }
      setSelectedChats(selOpts);

      const _prods = await rtmGetProducts();
      let prods: AutocompleteOption[] = [];
      if (_prods) {
        for (let p of _prods) {
          prods.push({
            label: p.name,
            id: p.id,
          });
        }
      }
      setProducts(prods);

      for (let p of props.data?.products || []) {
        const _o = prods.find((o) => o.id === p.id);
        if (_o) {
          selProds.push(_o);
        }
      }
      setSelectedProducts(selProds);
    } catch (err: any) {
      console.error(err);
    }
  }

  useEffect(() => {
    // Load subscription into inputs
    setName(props.data?.name || "");
    const pi = parseStripeAmount(props.data?.price.amount || 0);
    setDescription(props.data?.description || "");
    setPrice(pi.dollars + "." + pi.cents);
    setCurrency(props.data?.price.currency || "usd");
    setTrial(props.data?.trial || 0);
    setKind(props.data?.kind || "onetime");
    setSchedule(props.data?.schedule as any);
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <IconContainer>
            <IC_EDIT_CREDIT_CARD />
          </IconContainer>
          <Stack>
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <Typography fontSize={18} fontWeight={600}>
                Edit Subscription
              </Typography>
              <Tooltip title="Click to copy subscription id.">
                <Chip
                  onClick={() =>
                    navigator.clipboard.writeText(props.data?.id || "")
                  }
                  size="small"
                  label={props.data?.id}
                  variant="identifier"
                />
              </Tooltip>
            </Stack>
            <Typography fontSize={14} fontWeight={400}>
              Update an existing subscription model.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px", width: "580px" }}>
          <Stack spacing={"24px"} sx={{ px: "24px" }}>
            <PFMInput
              label="Subscription Name"
              placeholder="Name your subscription..."
              important
              text={name}
              onUpdate={(t) => setName(t)}
              disabled={busy}
            />
            <PFMInput
              label="Description"
              placeholder="Write a short description for this model. It will be shown to the users in the subscription details screen. "
              important
              multiline
              rows={4}
              text={description}
              onUpdate={(t) => setDescription(t)}
              disabled={busy}
            />
            <PFMAutoComplete
              multiple
              key={chats.length}
              options={chats}
              label="Assign Channels"
              placeholder="Select channels.."
              onUpdate={(sel) => setSelectedChats(sel as any)}
              value={selectedChats}
              helperText="Use this to provide access to the channels with this subscription. Send a message in the channel after adding the bot, and make sure your bot has access to messages."
            />
            <Stack>
              <PFMAutoComplete
                multiple
                key={products.length}
                options={products}
                label="Assign Products"
                placeholder="Select products.."
                helperText="Use this to provide access to your products with this subscription. "
                onUpdate={(sel) => setSelectedProducts(sel as any)}
                value={selectedProducts}
              />
              <Typography
                onClick={load}
                color="primary"
                sx={{
                  textDecoration: "underline",
                  px: "12px",
                  cursor: "pointer",
                }}
              >
                Refresh
              </Typography>
            </Stack>

            <PFMInput
              startAdornment={
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ width: "48px", height: "100%" }}
                >
                  <Typography>{getCurrencySymbol(currency)}</Typography>
                </Stack>
              }
              text={price}
              onUpdate={(p) => setPrice(p)}
              label="Subscription Price"
              placeholder="500"
              important
            />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                Type of subscription
              </Typography>
              <RadioGroup radioGroup="subscriptionKind">
                <PFMRadio
                  checked={kind === "onetime"}
                  onUpdate={(c) => setKind("onetime")}
                  label="One-time"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
                <PFMRadio
                  checked={kind === "recurring"}
                  onUpdate={(c) => setKind("recurring")}
                  label="Recurring"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
              </RadioGroup>
              {kind === "recurring" && (
                <PFMAutoComplete
                  options={[
                    { label: "Daily" },
                    { label: "Weekly" },
                    { label: "Monthly" },
                    { label: "Every 3 months" },
                    { label: "Every 6 months" },
                    { label: "Yearly" },
                  ]}
                  value={{ label: schedule }}
                  onUpdate={(v: any) => setSchedule(v.label)}
                  label="Recurring Schedule"
                  placeholder="Select a schedule..."
                  important
                />
              )}

              {/* Add trial option for recurring  */}
              {kind === "recurring" && (
                <PFMInput
                  sx={{ pt: "12px" }}
                  label="Trial Period (Stripe Only)"
                  placeholder="7"
                  text={trial.toString()}
                  helperText="Enter the trial period in number of days, or 0 to disable trial."
                  onUpdate={(c) => setTrial(parseInt(c) || 0)}
                  endAdornment={
                    <Select
                      variant="standard"
                      disableUnderline
                      value={"days"}
                      sx={{ background: "transparent" }}
                    >
                      <MenuItem value={"days"}>Days</MenuItem>
                    </Select>
                  }
                />
              )}
            </Stack>
          </Stack>
          <Divider />
          <Stack direction={"row"} sx={{ width: "100%", px: "32px" }}>
            <Typography
              fontSize={20}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Activity
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            justifyContent={"space-between"}
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Last modified
            </Typography>
            <Typography fontSize={14} color="#94969C">
              {moment
                .unix(props.data?.modifiedTimestamp!)
                .format("MMM DD, YYYY HH:mm:ss")}{" "}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            justifyContent={"space-between"}
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              sx={{ minWidth: "150px" }}
            >
              Created
            </Typography>
            <Typography fontSize={14} color="#94969C">
              {moment
                .unix(props.data?.createdTimestamp!)
                .format("MMM DD, YYYY HH:mm:ss")}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={busy}
          onClick={props.closeHandler}
          color="secondary"
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={updateSubscription}
          color="primary"
          variant="contained"
          fullWidth
        >
          Update Subscription
        </Button>
      </DialogActions>
    </Dialog>
  );
}
