export type TransactionalEmail = {
  key:
    | "email-verification"
    | "welcome"
    | "password-reset"
    | "new-signin"
    | "post-password-reset"
    | "invoice-created"
    | "invoice-paid"
    | "payment-failed"
    | "payout-requested"
    | "payout-processing"
    | "payout-completed";
  template: string;
  from?: string;
  subject: string;
};

export enum TransactionalEmailKey {
  emailVerification = "email-verification",
  welcome = "welcome",
  passwordReset = "password-reset",
  newSignin = "new-signin",
  postPasswordReset = "post-password-reset",
  invoiceCreated = "invoice-created",
  invoicePaid = "invoice-paid",
  paymentFailed = "payment-failed",
  payoutRequested = "payout-requested",
  payoutProcessing = "payout-processing",
  payoutCompleted = "payout-completed",
}
