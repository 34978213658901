import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function States() {
  const theme = useTheme();

  return (
    <Stack alignItems={"center"} spacing={"32px"} padding={"150px 0"}>
      <Typography
        sx={{
          justifyContent: "center",
          fontSize: "50px",
          [theme.breakpoints.down("desktop")]: {
            fontSize: "40px",
          },

          [theme.breakpoints.down("tablet")]: {
            fontSize: "24px",
          },
        }}
      >
        Join Our Expanding User Base
      </Typography>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "48px",
          [theme.breakpoints.down("desktop")]: {
            flexDirection: "row",
            gap: "48px",
          },

          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
          },
        }}
      >
        <Stack
          sx={{
            gap: "48px",
            flexDirection: "row",

            [theme.breakpoints.down("desktop")]: {
              flexDirection: "row",
              gap: "48px",
            },

            [theme.breakpoints.down("tablet")]: {
              flexDirection: "column",
              gap: "32px",
            },
          }}
        >
          <Stack>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "50px",
                fontWeight: "400",
                textAlign: "center",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "40px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "35px",
                },
              }}
            >
              $100,000+
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "center",
                background:
                  "linear-gradient(97deg, #FFF -31.45%, #94ABFC 91.35%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "20px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Current Volume
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "50px",
                fontWeight: "400",
                textAlign: "center",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "40px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "35px",
                },
              }}
            >
              13500+
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "center",
                background:
                  "linear-gradient(97deg, #FFF -31.45%, #94ABFC 91.35%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "20px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Transactions Fulfilled
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "50px",
                fontWeight: "400",
                textAlign: "center",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "40px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "35px",
                },
              }}
            >
              1200+
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "center",
                background:
                  "linear-gradient(97deg, #FFF -31.45%, #94ABFC 91.35%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "20px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Active Subscriptions
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "50px",
                fontWeight: "400",
                textAlign: "center",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "40px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "35px",
                },
              }}
            >
              1000+
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "center",
                background:
                  "linear-gradient(97deg, #FFF -31.45%, #94ABFC 91.35%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                [theme.breakpoints.down("desktop")]: {
                  fontSize: "20px",
                },

                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Active Access Codes
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
