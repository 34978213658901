import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";

function FeaturesCard(props: {
  title?: string;
  description?: string;
  sx?: SxProps;
  imgSrc?: any;
  imgSX?: SxProps;
  textSx?: SxProps;
  ImgSrcSX?: CSSProperties;
}) {
  const theme = useTheme();

  return (
    <Stack
      // sx={props.sx}
      overflow={"hidden"}
      // sx={{
      //   background: "#296888",
      //   alignItems: "center",
      //   borderRadius: "20px",
      //   justifyContent: "start",
      //   flexDirection: "row",
      //   gap: "32px",
      //   [theme.breakpoints.down("desktop")]: {
      //     flexDirection: "row",
      //     gap: "20px",
      //   },

      //   [theme.breakpoints.down("tablet")]: {
      //     flexDirection: "column",
      //     gap: "8px",
      //     textAlign: "center",
      //     alignItems: "center",
      //   },
      // }}
      sx={props.sx}
    >
      <Stack
        spacing={"16px"}
        sx={{
          padding: "32px 32px 0px 48px",
          width: "400px",
          [theme.breakpoints.down("desktop")]: { width: "100%" },

          [theme.breakpoints.down("tablet")]: { width: "100%" },
        }}
      >
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "30px",
            fontWeight: "500",
            letterSpacing: " -0.6px",
            lineHeight: "100%",
            [theme.breakpoints.down("desktop")]: {
              fontSize: "20px",
              fontWeight: "500",
            },

            [theme.breakpoints.down("tablet")]: {
              fontSize: "18px",
              fontWeight: "500",
            },
          }}
        >
          {props.title}
          {/* Accept payments through popular crypto chains & coins */}
        </Typography>

        <Typography
          sx={{
            color: "#FFF",
            fontSize: "15px",
            fontWeight: "300",
            [theme.breakpoints.down("desktop")]: {
              fontSize: "8px",
              fontWeight: "400",
            },

            [theme.breakpoints.down("tablet")]: {
              fontSize: "10px",
              fontWeight: "400",
            },
          }}
        >
          {props.description}
          {/* Accept payments through popular crypto chains & coins */}
        </Typography>
      </Stack>

      {/* img */}
      <Stack
        sx={{
          minWidth: "550px",
          alignItems: "center",
          justifyContent: "center",
          padding: "130px 48px 0 48px",
          [theme.breakpoints.down("desktop")]: {
            height: "350px",
            minWidth: "350px",
          },
          [theme.breakpoints.down("tablet")]: {
            minWidth: "294px",
            width: "294px",
            height: "139px",
            padding: "44px 44px 0 44px",
          },
        }}
      >
        {" "}
        <img src={props.imgSrc} alt="" style={{ width: "100%" }} />
        {/* <IC_DASHBOARDIMG width="100%" /> */}
      </Stack>
    </Stack>
  );
}

export default FeaturesCard;
