import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import { IC_COPY, IC_USER_ADD } from "../assets/ui";
import { rtmAddUser } from "../core/api/admin";
import { User } from "../types/User";

export default function AddUserDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [role, setRole] = useState("");

  const [busy, setBusy] = useState(false);

  const [addedUser, setAddedUser] = useState<{
    user: User;
    password: string;
  }>();

  async function addUser() {
    try {
      setBusy(true);
      const u = await rtmAddUser({
        email: email,
        name: name,
        role: role as any,
        comments: comments,
      });
      if (u) {
        enqueueSnackbar("User added successfully. ", { variant: "success" });
        setAddedUser(u);
      }
    } catch (err: any) {
      enqueueSnackbar("Error adding a new user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_USER_ADD />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Add User
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Fill in the user's details and give them a role.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <PFMInput
                text={name}
                onUpdate={(t) => setName(t)}
                fullWidth
                placeholder="John Doe"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Email Address
              </Typography>
              <PFMInput
                text={email}
                onUpdate={(t) => setEmail(t)}
                fullWidth
                placeholder="someone@example.com"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Role
              </Typography>
              <PFMAutoComplete
                options={[
                  { label: "Administrator" },
                  { label: "User" },
                  { label: "Affiliate" },
                ]}
                placeholder="Select a role.."
                onUpdate={(v) => setRole((v as any)?.label || "")}
                value={{ label: role } as any}
                important
              />
            </Stack>

            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Comments
              </Typography>
              <PFMInput
                onUpdate={(t) => setComments(t)}
                multiline
                text={comments}
                rows={4}
                fullWidth
                placeholder="Add comments, reasons, or notes (optional)"
              />
            </Stack>
            {addedUser && (
              <>
                <Divider />
                <Alert>
                  <Typography>User added successfully!</Typography>
                  <PFMInput
                    sx={{ height: "72px", width: "100%" }}
                    label="Password"
                    fullWidth
                    text={addedUser.password}
                    endAdornment={
                      <IconButton
                        onClick={(te) => {
                          navigator.clipboard?.writeText(addedUser.password);
                          enqueueSnackbar("Password copied to clipboard.");
                        }}
                      >
                        <IC_COPY />
                      </IconButton>
                    }
                  />
                </Alert>
              </>
            )}
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={busy}
              onClick={addUser}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Add User
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
