import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { IC_PAID } from "../assets/ui";

import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { rtmGetProfile } from "../core/api";
import { Affiliate } from "../types/Affiliation/Affiliate";
import {
  rtmGetAffiliateProfile,
  rtmRequestAffiliatePayout,
} from "../core/api/user";
import { IMG_BINANCE, IMG_WISE } from "../assets/brands";
import PFMInput from "../components/PFMInput";
import {
  BinanceDetails,
  Payout,
  WiseDetails,
} from "../types/Affiliation/Payout";

export default function RequestAffiliatePayoutDialog(
  props: DialogProps<Payout>
) {
  const [, setAffiliate] = useState<Affiliate>();
  const [, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const theme = useTheme();
  const lastPayout = props.data;
  const [method, setMethod] = useState<"wise" | "crypto">("wise");
  const [wiseDetails, setWiseDetails] = useState<WiseDetails>({} as any);
  const [binanceDetails, setBinanceDetails] = useState<BinanceDetails>(
    {} as any
  );

  async function confirm() {
    try {
      setBusy(true);
      const r = await rtmRequestAffiliatePayout({
        method: method === "wise" ? "wise" : "binance",
        details: method === "crypto" ? binanceDetails : wiseDetails,
      } as any);
      if (r) {
        enqueueSnackbar("Request submitted successfully!", {
          variant: "success",
        });
        props.closeHandler(true);
      } else {
        enqueueSnackbar("Error processing your request. ", {
          variant: "error",
        });
      }
    } catch (err: any) {
      enqueueSnackbar("Error requesting payout. Please try later.", {
        variant: "error",
      });
      console.error("Error requesting payout. ", err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      const prof = await rtmGetProfile();
      const aff = await rtmGetAffiliateProfile();
      if (aff) {
        setAffiliate(aff);
      }
      setProfile(prof);
      if (lastPayout) {
        if (lastPayout.method === "binance") {
          setMethod("crypto");
          setBinanceDetails(lastPayout.details);
        } else {
          setMethod("wise");
          setWiseDetails(lastPayout.details);
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try later. ", {
        variant: "error",
      });
      console.error("Error loading payout dialog. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <SvgIcon>
            <IC_PAID />
          </SvgIcon>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Request Payout
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Confirm the payout details to request a withdrawal of your funds.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="16px" sx={{ p: "32px", pb: "32px" }}>
          {/* Here we show the two custom radios with Stripe Connect, and Cryptocurrency  */}
          <Typography>Please select your preferred payout method</Typography>
          <Stack
            onClick={() => setMethod("wise")}
            spacing="8px"
            sx={{
              transition: "all .3s",
              cursor: "pointer",
              py: "12px",
              px: "24px",
              borderRadius: "8px",
              border:
                method === "wise"
                  ? `2px solid ${theme.palette.primary.main}`
                  : "2px solid #FFF4",
            }}
          >
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <img
                src={IMG_WISE}
                alt="Wise"
                style={{
                  height: "64px",
                  objectFit: "contain",
                  alignSelf: "start",
                }}
              />
              <Typography> Payments</Typography>
            </Stack>
            {method === "wise" && (
              <Stack spacing={"8px"}>
                <PFMInput
                  text={wiseDetails?.email}
                  onUpdate={(t) => setWiseDetails({ ...wiseDetails, email: t })}
                  label="Registered Email"
                  placeholder="Enter your registered wise email..."
                />
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    text={wiseDetails?.iban}
                    onUpdate={(t) =>
                      setWiseDetails({ ...wiseDetails, iban: t })
                    }
                    label="IBAN #"
                    placeholder="Enter your IBAN number..."
                  />
                  <PFMInput
                    text={wiseDetails?.swift}
                    onUpdate={(t) =>
                      setWiseDetails({ ...wiseDetails, swift: t })
                    }
                    label="Swift Code"
                    placeholder="Enter your bank's swift code.."
                  />
                </Stack>
                <Typography>
                  Additional details required in some cases
                </Typography>
                <PFMInput
                  text={wiseDetails?.fullName}
                  onUpdate={(t) =>
                    setWiseDetails({ ...wiseDetails, fullName: t })
                  }
                  label="Full Name"
                  placeholder="Enter Your Full Name"
                />
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    label="Address"
                    placeholder="Enter your address"
                    text={wiseDetails?.address}
                    onUpdate={(t) =>
                      setWiseDetails({ ...wiseDetails, address: t })
                    }
                  />
                  <PFMInput
                    label="City"
                    placeholder="Enter name of your city"
                    text={wiseDetails?.city}
                    onUpdate={(t) =>
                      setWiseDetails({ ...wiseDetails, city: t })
                    }
                  />
                </Stack>
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    text={wiseDetails?.zip}
                    onUpdate={(t) => setWiseDetails({ ...wiseDetails, zip: t })}
                    label="ZIP Code"
                    placeholder="Enter your area zip code"
                  />
                  <PFMInput
                    label="Country"
                    text={wiseDetails?.country}
                    onUpdate={(t) =>
                      setWiseDetails({ ...wiseDetails, country: t })
                    }
                    placeholder="Enter name of your country or State"
                  />
                </Stack>
              </Stack>
            )}
          </Stack>

          <Stack
            onClick={() => setMethod("crypto")}
            spacing="8px"
            sx={{
              transition: "all .3s",
              cursor: "pointer",
              py: "12px",
              px: "24px",
              borderRadius: "8px",
              border:
                method === "crypto"
                  ? `2px solid ${theme.palette.primary.main}`
                  : "2px solid #FFF4",
            }}
          >
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <img
                src={IMG_BINANCE}
                alt="BinancePay"
                style={{
                  height: "96px",
                  objectFit: "contain",
                  alignSelf: "start",
                }}
              />
              <Typography>Pay</Typography>
            </Stack>

            {method === "crypto" && (
              <Stack spacing={"8px"}>
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    text={binanceDetails?.email}
                    onUpdate={(t) =>
                      setBinanceDetails({ ...binanceDetails, email: t })
                    }
                    label="Email Address"
                    placeholder="Enter your registered binance email..."
                  />
                  <PFMInput
                    text={binanceDetails?.payId}
                    onUpdate={(t) =>
                      setBinanceDetails({ ...binanceDetails, payId: t })
                    }
                    label="Binance Pay ID"
                    placeholder="Enter your binance pay id..."
                  />
                </Stack>
                <PFMInput
                  text={binanceDetails?.notes}
                  onUpdate={(t) =>
                    setBinanceDetails({ ...binanceDetails, notes: t })
                  }
                  label="Additional Notes"
                  multiline
                  rows={3}
                  placeholder="Enter additional notes or instructions..."
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={"row"}
          sx={{ width: "100%", px: "32px" }}
          spacing="12px"
        >
          <Button
            disabled={busy}
            onClick={props.closeHandler}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={busy}
            size="large"
            fullWidth
            onClick={confirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
