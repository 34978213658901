import {
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import YouTubePlayer from "react-player/youtube";
import { motion } from "framer-motion";
export default function Demo() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const theme = useTheme();

  return (
    <Stack
      id="demo"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        py: "48px",
        [theme.breakpoints.down("desktop")]: {
          py: "48px",
        },
        [theme.breakpoints.down("tablet")]: {
          py: "48px",
        },
      }}
    >
      <Stack>
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        >
          <Typography
            sx={{
              fontSize: "50px",
              fontWeight: 300,
              [theme.breakpoints.down("desktop")]: {
                fontSize: "40px",
              },

              [theme.breakpoints.down("tablet")]: {
                fontSize: "20px",
              },
            }}
          >
            Watch our tutorial to help you get started{" "}
          </Typography>
        </motion.div>
      </Stack>
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          [theme.breakpoints.down("tablet")]: {
            width: "80%",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Paper
          style={{
            borderRadius: "8px",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: desktop ? undefined : "80%",
          }}
        >
          <YouTubePlayer
            style={{ maxWidth: "100%" }}
            controls
            url={
              "https://www.youtube.com/watch?v=Ba595bCVhFU&ab_channel=Cashifybot"
            }
          />
        </Paper>
      </Stack>
    </Stack>
  );
}
