import { ensureAuthentication, getRtmClient } from ".";
import { Payout } from "../../types/Affiliation/Payout";
import { BotStatus } from "../../types/BotStatus";
import {
  Config,
  PaymentsConfiguration,
  PlatformConfiguration,
} from "../../types/Config";
import { Email } from "../../types/Email";
import { EmailTemplate } from "../../types/EmailTemplate";
import { Invoice } from "../../types/Invoice";
import { LogMessage } from "../../types/LogMessage";
import { Audience } from "../../types/Mailing/Audience";
import { Contact } from "../../types/Mailing/Contact";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { PlatformFeedback } from "../../types/PlatformFeedback";
import { PlatformUpdate } from "../../types/PlatformUpdate";
import { Account } from "../../types/Stripe/StripeAccount";
import { TelegramChat } from "../../types/TelegramChat";
import { TelegramConfig } from "../../types/TelegramConfig";
import { TelegramUser } from "../../types/TelegramUser";
import { Transaction } from "../../types/Transaction";
import {
  TransactionalEmail,
  TransactionalEmailKey,
} from "../../types/TransactionalEmail";
import { User } from "../../types/User";

/**
 * Lists all the users on the platform, sorted by registeration time in descending. Requires administrator role.
 * @param pageNumber
 * @returns
 */
export async function rtmListUsers(props: {
  page: number;
  filter?: {
    type: "status" | "role" | "plan" | "balanceGte" | "balanceLte";
    value: any;
  };
  sort?: {
    type:
      | "lastSigninTimestamp"
      | "registeredTimestamp"
      | "name"
      | "plan"
      | "business.crypto.balance"
      | "status"
      | "role";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  await ensureAuthentication();
  const users = await getRtmClient().CallWait<PaginatedResponse<User>>(
    "rtmListUsers",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return users;
}

/**
 * Creates a new user with specified details.
 * @returns
 */
export async function rtmAddUser(user: Partial<User>) {
  await ensureAuthentication();
  const newuser = await getRtmClient().CallWait<{
    user: User;
    password: string;
  }>("rtmAddUser", user);
  return newuser;
}

/**
 * Returns the current platform configuration.
 * @returns
 */
export async function rtmGetPlatformConfig() {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<
    Config & { value: PlatformConfiguration }
  >("rtmGetPlatformConfig");
  return prof;
}

/**
 * Returns the currently running deployments, with user infoes.
 */
export async function rtmGetDeployments() {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<
    {
      user: User;
      deployment: any;
      status: BotStatus;
      config?: TelegramConfig;
    }[]
  >("rtmGetDeployments");
  return prof;
}

/**
 * Updates the current platform config and returns the updated value.
 * @param cfg
 * @returns
 */
export async function rtmSetPlatformConfig(cfg: PlatformConfiguration) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<
    Config & { value: PlatformConfiguration }
  >("rtmSetPlatformConfig", cfg);
  return _sub;
}

export async function rtmSetDeploymentState(
  deployId: string,
  state: "pause" | "resume" | "delete"
) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<Boolean>(
    "rtmSetDeploymentState",
    deployId,
    state
  );
  return _sub;
}

export async function rtmUpdateDeployment(deployId: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<Boolean>(
    "rtmUpdateDeployment",
    deployId
  );
  return _sub;
}

export async function rtmListPayoutsAdmin() {
  await ensureAuthentication();
  const users = await getRtmClient().CallWait<(Payout & { user: User })[]>(
    "rtmListPayoutsAdmin"
  );
  return users;
}

export async function rtmUpdatePayout(payout: Payout) {
  await ensureAuthentication();
  const pay = await getRtmClient().CallWait<boolean>("rtmUpdatePayout", payout);
  return pay;
}

export async function rtmGetPaymentsConfig() {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<
    Config & { value: PaymentsConfiguration }
  >("rtmGetPaymentsConfig");
  return prof;
}

export async function rtmSetPaymentsConfig(cfg: PaymentsConfiguration) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<
    Config & { value: PaymentsConfiguration }
  >("rtmSetPaymentsConfig", cfg);
  return _sub;
}

export async function rtmSetFlag(flag: string, value: any) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>(
    "rtmSetFlag",
    flag,
    value
  );
  return _sub;
}

export async function rtmGetFlag(flag: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<any>("rtmGetFlag", flag);
  return _sub;
}

export async function rtmUpdateAllDeployments() {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>(
    "rtmUpdateAllDeployments"
  );
  return _sub;
}

export async function rtmTouchConfigured() {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>("rtmTouchConfigured");
  return _sub;
}

export async function rtmDisableUser(uid: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>("rtmDisableUser", uid);
  return _sub;
}

export async function rtmEnableUser(uid: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>("rtmEnableUser", uid);
  return _sub;
}

export async function rtmSuspendUser(uid: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>("rtmSuspendUser", uid);
  return _sub;
}

export async function rtmGetWallets() {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<{
    [key: string]: {
      fee: string;
      balances: {
        [key: string]: string;
      };
    };
  }>("rtmGetWallets");

  return r;
}

/**
 * Returns the users' invoices, paginated.
 * @param pageNumber
 * @param search
 * @returns
 */
export async function rtmGetPlatformInvoicesA(
  pageNumber: number,
  search?: string
) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<Invoice>>(
    "rtmGetPlatformInvoicesA",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

export async function rtmGetPlatformTransactions(
  pageNumber: number,
  search?: string
) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<Transaction>>(
    "rtmGetPlatformTransactions",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

export async function rtmGetTelegramChatsA(
  pageNumber: number,
  search?: string
) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<TelegramChat>>(
    "rtmGetTelegramChatsA",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

/**
 * Returns the list of subscribers.
 * @param pageNumber
 * @param search
 * @returns
 */
export async function rtmGetTelegramUsers(pageNumber: number, search?: string) {
  await ensureAuthentication();
  const _cmd = await getRtmClient().CallWait<PaginatedResponse<TelegramUser>>(
    "rtmGetTelegramUsers",
    {
      page: pageNumber,
      search: search,
    }
  );
  return _cmd;
}

export async function rtmImpersonate(uid: string) {
  const _rtm = await getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmImpersonate", uid);
  return _result;
}

// Email templates

export async function rtmGetEmailCampaigns() {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<Email[]>("rtmGetEmailCampaigns");
  return _result;
}

export async function rtmGetEmailTemplates() {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<EmailTemplate[]>("rtmGetEmailTemplates");
  return _result;
}

export async function rtmGetEmailTemplate(id: string) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<EmailTemplate>("rtmGetEmailTemplate", id);
  return _result;
}

export async function rtmUpdateTemplate(temp: Partial<EmailTemplate>) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmUpdateTemplate", temp);
  return _result;
}

export async function rtmDeleteTemplate(id: string) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmDeleteTemplate", id);
  return _result;
}

export async function rtmGetEstimatedAudience(audience: string) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<number>(
    "rtmGetEstimatedAudience",
    audience
  );
  return _result;
}

export async function rtmUpdateEmail(temp: Partial<Email>) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<Email>("rtmUpdateEmail", temp);
  return _result;
}

export async function rtmDeleteCampaign(id: string) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmDeleteCampaign", id);
  return _result;
}

export async function rtmSetTransactionEmail(
  temp: Partial<TransactionalEmail>
) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmSetTransactionEmail", temp);
  return _result;
}

export async function rtmDeleteTransactionEmail(key: TransactionalEmailKey) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>(
    "rtmDeleteTransactionEmail",
    key
  );
  return _result;
}

export async function rtmGetTransactionalEmails() {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<TransactionalEmail[]>(
    "rtmGetTransactionalEmails"
  );
  return _result;
}

export async function rtmTestEmailTemplate(
  template: string,
  address: string,
  variables: any
) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmTestEmailTemplate", {
    template,
    address,
    variables,
  });
  return _result;
}

export async function rtmGetDashboardStats() {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<{
    topCustomers: { uid: string; user: User; volume: number; sales: number }[];
    monthlySignups: number;
    monthlySalesChart: {
      [key: number]: {
        sales: {
          gbp: number;
          usd: number;
          eur: number;
        };
        volume: {
          usd: number;
          gbp: number;
          eur: number;
        };
      };
    };
    monthlySales: {
      usd: number;
      eur: number;
      gbp: number;
    };
    monthlyVolume: {
      usd: number;
      eur: number;
      gbp: number;
    };
    activeSubscriptions: number;
    totalSales: {
      usd: number;
      eur: number;
      gbp: number;
    };
    totalVolume: {
      usd: number;
      eur: number;
      gbp: number;
    };
    openInvoices: Invoice[];
  }>("rtmGetDashboardStats");
  return _result;
}

export async function rtmCreatePlatformUpdateA(
  update: Partial<PlatformUpdate>
) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>(
    "rtmCreatePlatformUpdateA",
    update
  );
  return _result;
}

export async function rtmGetPlatformUpdatesA() {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<PlatformUpdate[]>(
    "rtmGetPlatformUpdatesA"
  );
  return _result;
}

export async function rtmGetFeedbacksA() {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<(PlatformFeedback & { user: User })[]>(
    "rtmGetFeedbacksA"
  );
  return _result;
}

export async function rtmWithdrawBalance(network: string, token?: string) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<string>("rtmWithdrawBalance", {
    token,
    network,
  });
  return _result;
}

// Get Logs
export async function rtmGetLogs(props: {
  page: number;
  filter?: {
    type: "type" | "source" | "uid";
    value: any;
  };
  sort?: {
    type: "created" | "type" | "source" | "uid";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  await ensureAuthentication();
  const _logs = await getRtmClient().CallWait<PaginatedResponse<LogMessage>>(
    "rtmGetLogs",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _logs;
}

// Mailing
export async function rtmGetAudiences() {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<Audience[]>("rtmGetAudiences");
  return _result;
}

export async function rtmCreateAudience(aud: Partial<Audience>) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<Audience>("rtmCreateAudience", aud);
  return _result;
}

export async function rtmUpdateAudience(aud: Partial<Audience>) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmUpdateAudience", aud);
  return _result;
}

export async function rtmCreateContact(contact: Partial<Contact>) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<Contact>("rtmCreateContact", contact);
  return _result;
}

export async function rtmUpdateContact(contact: Partial<Contact>) {
  const _rtm = getRtmClient();
  const _result = await _rtm.CallWait<boolean>("rtmUpdateContact", contact);
  return _result;
}

export async function rtmGetContacts(props: {
  page: number;
  filter?: {
    type: "subscribed";
    value: boolean;
  };
  sort?: {
    type: "created" | "updated" | "email" | "subscribed" | "firstName";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  await ensureAuthentication();
  const _contacts = await getRtmClient().CallWait<PaginatedResponse<Contact>>(
    "rtmGetContacts",
    {
      page: props.page,
      filter: props.filter,
      sort: props.sort,
      search: props.search,
    }
  );
  return _contacts;
}

export async function rtmGetStripeAccounts(options: {
  starting_after?: string;
  ending_before?: string;
}) {
  await ensureAuthentication();
  const _contacts = await getRtmClient().CallWait<{
    data: { user?: User; account: Account }[];
    hasMore: boolean;
  }>("rtmGetStripeAccounts", options);
  return _contacts;
}

export async function rtmDeleteStripeAccount(account: string) {
  await ensureAuthentication();
  const _contacts = await getRtmClient().CallWait<boolean>(
    "rtmDeleteStripeAccount",
    account
  );
  return _contacts;
}
