import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  Divider,
  Stack,
  SvgIcon,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { IC_ALERT } from "../../../assets/ui";
import PFMInput from "../../../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../../../components/PFMAutoComplete";
import { CurrencyBitcoin, Warning } from "@mui/icons-material";
import { IC_STRIPE } from "../../../assets/landing";
import {
  rtmCreateStripeAccount,
  rtmGetStripeDashboard,
  rtmGetStripeProfile,
  rtmUpdatePayments,
} from "../../../core/api/user";
import { rtmGetProfile } from "../../../core/api";
import { User } from "../../../types/User";
import { Role } from "../../../types/Role";
import { StyledToggleButtonGroup } from "../../../components/ToggleButtonGroup";
import { AppConfig } from "../../../config";

export default function SectionUserSettingsPayments() {
  const [busy, setBusy] = useState(false);

  const [profile, setProfile] = useState<User>();
  const [stripeProfile, setStripeProfile] = useState<any>();

  const theme = useTheme();

  const [selectedNetwork, setSelectedNetwork] = useState<
    "ethereum" | "arbitrum" | "polygon" | "tron"
  >("ethereum");

  const [selectedCoins, setSelectedCoins] = useState<
    { label: string; id: string; network: string; currency: string }[]
  >([]);

  const isStripeActive = useMemo(() => {
    return (
      stripeProfile &&
      stripeProfile.capabilities &&
      stripeProfile.capabilities.card_payments === "active" &&
      stripeProfile.capabilities.transfers === "active" &&
      stripeProfile.payouts_enabled
    );
  }, [stripeProfile]);

  const [ALLCOINS] = useState<AutocompleteOption[]>([...AppConfig.currencies]);

  async function save() {
    try {
      setBusy(true);
      // save the details
      profile!.wallets.accepted_currencies = selectedCoins;
      await rtmUpdatePayments(profile?.wallets);
      enqueueSnackbar("Profile updated successfully.", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function load() {
    try {
      setBusy(true);
      const prof = await rtmGetProfile();
      // Load network
      // setAddress(prof.business?.crypto?.wallet || "");
      // setNetwork(NETWORKS.find((n) => n.id === prof.business?.crypto?.network));
      // setCoin(ALLCOINS.find((c) => c.id === prof.business?.crypto?.currency));
      if (prof.wallets && prof.wallets?.accepted_currencies?.length > 0) {
        setSelectedCoins(
          ALLCOINS.filter(
            (c) =>
              prof.wallets!.accepted_currencies.filter((ac) => ac?.id === c?.id)
                ?.length > 0
          ) as any
        );
      } else {
        setSelectedCoins([]);
      }
      setProfile(prof);

      // Load stripe
      const sProf = await rtmGetStripeProfile();
      setStripeProfile(sProf);
    } catch (err: any) {
      enqueueSnackbar("Error loading profile. Please try again.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  async function stripeDashboard() {
    try {
      setBusy(true);
      const link = await rtmGetStripeDashboard();
      window.open(link, "_blank");
    } catch (err: any) {
      enqueueSnackbar("Error generating link. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function updateStripe() {
    try {
      setBusy(true);
      // Use onboarding flow
      const link = await rtmCreateStripeAccount();
      window.open(link, "_blank");
    } catch (err: any) {
      enqueueSnackbar("Error generating link. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack sx={{ py: "12px", px: "32px" }} alignItems={"start"} spacing="32px">
      {profile && profile.role !== Role.Affiliate && (
        <>
          <Stack spacing={"0px"} alignItems={"start"}>
            <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
              <SvgIcon sx={{ width: "64px", height: "64px", p: 0, m: 0 }}>
                <IC_STRIPE />
              </SvgIcon>
              <Typography fontSize={18} fontWeight={500}>
                Connect
              </Typography>
            </Stack>
            <Stack spacing={"16px"}>
              <Typography fontSize={14} variant="caption">
                Accept credit/debit card payments in over 43+ countries using
                Stripe Connect.
              </Typography>

              {/* If we are connected and active, we show  */}
              {isStripeActive && (
                <>
                  <Typography display={"inline"}>
                    Connected with{" "}
                    <Typography display={"inline"} color="primary">
                      {stripeProfile?.email}
                    </Typography>
                  </Typography>
                  <Stack direction={"row"} spacing={"8px"}>
                    <Button
                      onClick={stripeDashboard}
                      variant="contained"
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: 10,
                        },
                      }}
                    >
                      Express Dashboard
                    </Button>
                    <Button
                      onClick={updateStripe}
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: 10,
                        },
                      }}
                    >
                      Update Information
                    </Button>
                  </Stack>
                </>
              )}

              {/* If the user has signed up already, but not active, we show warning, and option to update info  */}
              {!isStripeActive && profile?.business?.stripe?.accountId && (
                <>
                  <Typography
                    color={"error"}
                    sx={{ alignItems: "center", display: "flex", gap: "8px" }}
                  >
                    <SvgIcon>
                      <Warning />
                    </SvgIcon>
                    Please update your information or contact support.
                  </Typography>
                  <Button
                    disabled={busy}
                    onClick={updateStripe}
                    sx={{
                      alignSelf: "start",
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                    variant="contained"
                  >
                    Update Information
                  </Button>
                </>
              )}

              {/* And if the user has not yet signed up, we show Signup button.  */}
              {!profile?.business?.stripe && (
                <Stack direction={"row"} spacing="12px">
                  <Button
                    disabled={busy}
                    onClick={updateStripe}
                    variant="contained"
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                  >
                    Signup
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Divider sx={{ width: "100%" }} />
        </>
      )}

      <Stack spacing={"8px"}>
        <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
          <Typography fontSize={24}>Accepting Crypto</Typography>
          <CurrencyBitcoin />
        </Stack>
        <Typography
          fontSize={14}
          textAlign={"start"}
          variant="caption"
          sx={{ maxWidth: "50%" }}
        >
          Accept payments worldwide with our blockchain integration, accepting
          most popular currencies through multiple networks, automatically
          transferred to your preferred wallet.
          <br /> <br />
          Below you can enable or disable the specific currencies for your bot.
          <br />
          <br />
          <span style={{ color: "#FFF" }}>
            Note: If you want to disable a specific network, simply leave the
            wallet address for that network empty.
          </span>
        </Typography>
      </Stack>

      <Stack spacing={"24px"}>
        <StyledToggleButtonGroup
          sx={{
            [theme.breakpoints.down("md")]: {
              alignSelf: "flex-start",
            },
          }}
          exclusive
          value={selectedNetwork}
          onChange={(c, e) => setSelectedNetwork(e ? e : selectedNetwork)}
        >
          <ToggleButton value={"ethereum"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{
                px: "16px",
                [theme.breakpoints.down("md")]: {
                  px: "8px",
                },
              }}
            >
              <Badge
                variant="dot"
                color={selectedNetwork === "ethereum" ? "success" : undefined}
              ></Badge>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                Ethereum
              </Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"arbitrum"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{
                px: "16px",
                [theme.breakpoints.down("md")]: {
                  px: "8px",
                },
              }}
            >
              <Badge
                variant="dot"
                color={selectedNetwork === "arbitrum" ? "success" : undefined}
              ></Badge>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                Arbitrum
              </Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"polygon"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{
                px: "16px",
                [theme.breakpoints.down("md")]: {
                  px: "8px",
                },
              }}
            >
              <Badge
                variant="dot"
                color={selectedNetwork === "polygon" ? "success" : undefined}
              ></Badge>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                Polygon (Matic)
              </Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"tron"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{
                px: "16px",
                [theme.breakpoints.down("md")]: {
                  px: "8px",
                },
              }}
            >
              <Badge
                variant="dot"
                color={selectedNetwork === "tron" ? "success" : undefined}
              ></Badge>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                Tron
              </Typography>
            </Stack>
          </ToggleButton>
        </StyledToggleButtonGroup>

        {selectedNetwork === "ethereum" && (
          <Stack
            spacing={"16px"}
            alignItems={"start"}
            sx={{ maxWidth: "500px" }}
          >
            <PFMInput
              text={profile?.wallets?.ethereum}
              onUpdate={(t) =>
                setProfile({
                  ...profile,
                  wallets: { ...(profile?.wallets || {}), ethereum: t },
                } as any)
              }
              placeholder="Enter your ethereum wallet address..."
              label="Ethereum Wallet Address"
            />
            <Typography>Enabled Currencies for Ethereum</Typography>
            <Stack
              direction={"row"}
              rowGap={"8px"}
              columnGap={"8px"}
              flexWrap={"wrap"}
            >
              {/* Add each coin of this network */}
              {ALLCOINS.filter((c) => c.network === "ethereum").map((c) => (
                <Button
                  onClick={() => {
                    if (
                      selectedCoins.filter((sc) => sc.id === c.id).length > 0
                    ) {
                      // remove
                      setSelectedCoins([
                        ...selectedCoins.filter((sc) => sc.id !== c.id),
                      ]);
                    } else {
                      // add
                      setSelectedCoins([
                        ...selectedCoins,
                        ALLCOINS.find((ac) => ac.id === c.id) as any,
                      ]);
                    }
                  }}
                  color={
                    selectedCoins.filter((sc) => sc.id === c.id).length > 0
                      ? "primary"
                      : "secondary"
                  }
                  variant="outlined"
                >
                  {c.label} ({c.id})
                </Button>
              ))}
            </Stack>
            <Alert
              icon={
                <SvgIcon>
                  <IC_ALERT />
                </SvgIcon>
              }
              variant="outlined"
              color="warning"
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                Enter your wallet address for Ethereum (ERC-20) only, and
                double-check the address so you don't lose the funds!
              </Typography>
            </Alert>
          </Stack>
        )}

        {selectedNetwork === "arbitrum" && (
          <Stack
            spacing={"16px"}
            alignItems={"start"}
            sx={{ maxWidth: "500px" }}
          >
            <PFMInput
              text={profile?.wallets?.arbitrum}
              onUpdate={(t) =>
                setProfile({
                  ...profile,
                  wallets: { ...(profile?.wallets || {}), arbitrum: t },
                } as any)
              }
              placeholder="Enter your Arbitrum One wallet address..."
              label="Arbitrum Wallet Address"
            />
            <Typography>Enabled Currencies for Arbitrum One</Typography>
            <Stack
              direction={"row"}
              rowGap={"8px"}
              columnGap={"8px"}
              flexWrap={"wrap"}
            >
              {/* Add each coin of this network */}
              {ALLCOINS.filter((c) => c.network === "arbitrum").map((c) => (
                <Button
                  onClick={() => {
                    if (
                      selectedCoins.filter((sc) => sc.id === c.id).length > 0
                    ) {
                      // remove
                      setSelectedCoins([
                        ...selectedCoins.filter((sc) => sc.id !== c.id),
                      ]);
                    } else {
                      // add
                      setSelectedCoins([
                        ...selectedCoins,
                        ALLCOINS.find((ac) => ac.id === c.id) as any,
                      ]);
                    }
                  }}
                  color={
                    selectedCoins.filter((sc) => sc.id === c.id).length > 0
                      ? "primary"
                      : "secondary"
                  }
                  variant="outlined"
                >
                  {c.label} ({c.id})
                </Button>
              ))}
            </Stack>
            <Alert
              icon={
                <SvgIcon>
                  <IC_ALERT />
                </SvgIcon>
              }
              variant="outlined"
              color="warning"
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                Enter your wallet address for Arbitrum One (ERC-20) only, and
                double-check the address so you don't lose the funds!
              </Typography>
            </Alert>
          </Stack>
        )}

        {selectedNetwork === "polygon" && (
          <Stack
            spacing={"16px"}
            alignItems={"start"}
            sx={{ maxWidth: "500px" }}
          >
            <PFMInput
              text={profile?.wallets?.polygon}
              onUpdate={(t) =>
                setProfile({
                  ...profile,
                  wallets: { ...(profile?.wallets || {}), polygon: t },
                } as any)
              }
              placeholder="Enter your Polygon (Matic) wallet address..."
              label="Matic Wallet Address"
            />
            <Typography>Enabled Currencies for Polygon (Matic)</Typography>
            <Stack
              direction={"row"}
              rowGap={"8px"}
              columnGap={"8px"}
              flexWrap={"wrap"}
            >
              {/* Add each coin of this network */}
              {ALLCOINS.filter((c) => c.network === "polygon").map((c) => (
                <Button
                  onClick={() => {
                    if (
                      selectedCoins.filter((sc) => sc.id === c.id).length > 0
                    ) {
                      // remove
                      setSelectedCoins([
                        ...selectedCoins.filter((sc) => sc.id !== c.id),
                      ]);
                    } else {
                      // add
                      setSelectedCoins([
                        ...selectedCoins,
                        ALLCOINS.find((ac) => ac.id === c.id) as any,
                      ]);
                    }
                  }}
                  color={
                    selectedCoins.filter((sc) => sc.id === c.id).length > 0
                      ? "primary"
                      : "secondary"
                  }
                  variant="outlined"
                >
                  {c.label} ({c.id})
                </Button>
              ))}
            </Stack>
            <Alert
              icon={
                <SvgIcon>
                  <IC_ALERT />
                </SvgIcon>
              }
              variant="outlined"
              color="warning"
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                Enter your wallet address for Polygon (Matic) only, and
                double-check the address so you don't lose the funds!
              </Typography>
            </Alert>
          </Stack>
        )}

        {selectedNetwork === "tron" && (
          <Stack
            spacing={"16px"}
            alignItems={"start"}
            sx={{ maxWidth: "500px" }}
          >
            <PFMInput
              text={profile?.wallets?.tron}
              onUpdate={(t) =>
                setProfile({
                  ...profile,
                  wallets: { ...(profile?.wallets || {}), tron: t },
                } as any)
              }
              placeholder="Enter your Tron wallet address..."
              label="Tron Wallet Address"
            />
            <Typography>Enabled Currencies for Tron (TRC-20)</Typography>
            <Stack
              direction={"row"}
              rowGap={"8px"}
              columnGap={"8px"}
              flexWrap={"wrap"}
            >
              {/* Add each coin of this network */}
              {ALLCOINS.filter((c) => c.network === "tron").map((c) => (
                <Button
                  onClick={() => {
                    if (
                      selectedCoins.filter((sc) => sc.id === c.id).length > 0
                    ) {
                      // remove
                      setSelectedCoins([
                        ...selectedCoins.filter((sc) => sc.id !== c.id),
                      ]);
                    } else {
                      // add
                      setSelectedCoins([
                        ...selectedCoins,
                        ALLCOINS.find((ac) => ac.id === c.id) as any,
                      ]);
                    }
                  }}
                  color={
                    selectedCoins.filter((sc) => sc.id === c.id).length > 0
                      ? "primary"
                      : "secondary"
                  }
                  variant="outlined"
                >
                  {c.label} ({c.id})
                </Button>
              ))}
            </Stack>
            <Alert
              icon={
                <SvgIcon>
                  <IC_ALERT />
                </SvgIcon>
              }
              variant="outlined"
              color="warning"
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                Enter your wallet address for Tron Network only, and
                double-check the address so you don't lose the funds!
              </Typography>
            </Alert>
          </Stack>
        )}
      </Stack>

      <Button
        onClick={save}
        size="large"
        disabled={busy}
        sx={{ alignSelf: "start" }}
        variant="contained"
      >
        Save
      </Button>
    </Stack>
  );
}
