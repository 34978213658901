import _ic_customize from "./002-customization.png";
import _ic_dashboard from "./004-dashboard.png";
import _ic_payment from "./009-no-credit-card.png";
import _ic_stripe from "./stripe.svg";
import _ic_subscription from "./003-subscription.png";
import _ic_unlock from "./006-easy-access.png";
import _ic_affiliate from "./affiliate.svg";
import _screenshot_dashboard from "./dashboard-screenshot.png";
import _ic_crypto from "./crypto.png";
import _affiliate_screenshot from "./affiliate-dashboard-screenshot.jpeg";
import _ic_shopping from "./shopping-bag.png";
import _ic_trials from "./free-trial.png";

export const IC_SHOPPING = _ic_shopping;
export const IC_CUSTOMIZE = _ic_customize;
export const IC_DASHBOARD = _ic_dashboard;
export const IC_PAYMENT = _ic_payment;
export const IC_STRIPE = _ic_stripe;
export const IC_SUBSCRIPTION = _ic_subscription;
export const IC_UNLOCK = _ic_unlock;
export const IC_AFFILIATE = _ic_affiliate;
export const SCR_DASHBOARD = _screenshot_dashboard;
export const IC_CRYPTO = _ic_crypto;
export const SCR_AFFILIATE_DASHBOARD = _affiliate_screenshot;
export const IC_TRIALS = _ic_trials;
