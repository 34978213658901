import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";

import { IC_REFRESH, IC_SEARCH } from "../../assets/ui";
import {
  rtmGetDeployments,
  rtmGetPlatformConfig,
  rtmSetDeploymentState,
  rtmTouchConfigured,
  rtmUpdateAllDeployments,
  rtmUpdateDeployment,
} from "../../core/api/admin";
import PFMInput from "../../components/PFMInput";
import { PlatformConfiguration } from "../../types/Config";
import { User } from "../../types/User";
import { BotStatus } from "../../types/BotStatus";
import { TelegramConfig } from "../../types/TelegramConfig";

export default function SectionAdminDeployments() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);
  const [cfg, setCfg] = useState<PlatformConfiguration>();
  const [searchRaw, setSearchRaw] = useState("");
  const [search, setSearch] = useState("");

  const [_data, setData] = useState<
    {
      user: User;
      deployment: any;
      status: BotStatus;
      config?: TelegramConfig;
    }[]
  >([]);

  const data = useMemo(() => {
    if (search) {
      return _data
        .filter(
          (d) =>
            d.user.email.includes(search) ||
            d.user.name.includes(search) ||
            d.user.uid.includes(search) ||
            d.config?.username?.includes(search)
        )
        .sort((a, b) => (a.config ? -1 : 1));
    }
    return _data.sort((a, b) => (a.config ? -1 : 1));
  }, [_data, search]);

  const { showModal } = useModal();

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _cfg = await rtmGetPlatformConfig();
      if (_cfg) {
        setCfg(_cfg.value);
      }
      const deps = await rtmGetDeployments();
      console.log(deps);
      setData(deps);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  async function updateDeployment(
    depId: string,
    state: "pause" | "resume" | "delete"
  ) {
    try {
      setBusy(true);
      await rtmSetDeploymentState(depId, state);
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating deployment. Please try again. ", {
        variant: "error",
      });
      console.error("Error updating a deployment's state. ", err);
    }
    setBusy(false);
  }

  async function updateImage(depId: string) {
    try {
      setBusy(true);
      await rtmUpdateDeployment(depId);
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating deployment. Please try again. ", {
        variant: "error",
      });
      console.error("Error updating a deployment's state. ", err);
    }
    setBusy(false);
  }

  async function updateAll() {
    try {
      setBusy(true);
      await rtmUpdateAllDeployments();
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating deployments. Please try again. ", {
        variant: "error",
      });
      console.error("Error updating a deployment's state. ", err);
    }
    setBusy(false);
  }

  async function touchConfigured() {
    try {
      setBusy(true);
      await rtmTouchConfigured();
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating deployments. Please try again. ", {
        variant: "error",
      });
      console.error("Error updating a deployment's state. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Deployments & Services`);
    setSectionDescription(
      `View and manage currently running services on cluster.`
    );
    load();
  }, []);

  function RenderDeployment(data: {
    user: User;
    deployment: any;
    status: BotStatus;
    config?: TelegramConfig;
  }) {
    return (
      <TableRow>
        <TableCell>
          <Typography color="#94969C">
            {data.config ? "@" + data.config.username : "Not Configured"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={data.user?.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {data.user?.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {data.user?.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            sx={{ textTransform: "capitalize" }}
            color={data.deployment?.spec?.replicas === 0 ? "error" : "default"}
            label={
              data.deployment?.spec?.replicas === 0
                ? "Stopped"
                : data.status?.status
            }
          />
        </TableCell>
        <TableCell>
          <Typography color="#94969C">{data?.status?.image}</Typography>
        </TableCell>
        <TableCell>
          {data?.deployment?.metadata?.creationTimestamp && (
            <Typography color="#94969C">
              {moment(
                new Date(data.deployment.metadata.creationTimestamp)
              ).format("MMM DD, YYYY hh:mm:ss a")}
            </Typography>
          )}
        </TableCell>

        <TableCell align="right">
          <Stack direction={"row"} spacing="8px">
            <Button
              color="error"
              disabled={busy}
              onClick={() =>
                updateDeployment(data.deployment.metadata.name, "delete")
              }
            >
              Delete
            </Button>
            {data.status.image !== cfg?.image && Boolean(cfg?.image) && (
              <Button
                disabled={busy}
                onClick={() => updateImage(data.deployment.metadata.name)}
                title={`Update the image to current default: ${cfg?.image}`}
              >
                Update Image
              </Button>
            )}
            {data.status.status === "running" &&
              data.deployment?.spec?.replicas > 0 && (
                <Button
                  disabled={busy}
                  onClick={() =>
                    updateDeployment(data.deployment.metadata.name, "pause")
                  }
                  color="warning"
                >
                  Stop
                </Button>
              )}
            {data.status.status === "unknown" &&
              data.deployment?.spec?.replicas === 0 && (
                <Button
                  color="success"
                  disabled={busy}
                  onClick={() =>
                    updateDeployment(data.deployment.metadata.name, "resume")
                  }
                >
                  Start
                </Button>
              )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1} spacing={"8px"}>
      <Stack direction={"row"} spacing={"8px"}>
        <Button
          disabled={busy}
          sx={{ height: "48px" }}
          onClick={updateAll}
          variant="contained"
          color="secondary"
        >
          Update All
        </Button>
        <Button
          disabled={busy}
          sx={{ height: "48px" }}
          onClick={touchConfigured}
          variant="contained"
          color="secondary"
        >
          Touch Configured
        </Button>
      </Stack>
      <PFMTable
        sx={{ height: "100%" }}
        busy={busy}
        titleBadge={data.length + " Deployments"}
        title="Deployments"
        description="List of all the deployments on the cluster."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <PFMInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={IC_SEARCH}
              placeholder="Search user..."
            />
            <Button
              disabled={busy}
              sx={{ width: "200px", height: "48px" }}
              onClick={() => load()}
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Created</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderDeployment(d))}</>}
      />
    </Stack>
  );
}
