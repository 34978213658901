import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

export default function PFMSelect(props: {
  options?: { label: string; id: string }[];
  value?: string;
  onChange?: (value?: { label: string; id: string }) => void;
  placeholder?: string;
}) {
  const handleChange = (event: SelectChangeEvent) => {
    props.onChange?.(props.options?.find((f) => f?.id === event.target.value));
  };

  return (
    <FormControl sx={{ minWidth: 20, border: 0 }} size="small">
      <Select
        id="demo-select-small"
        value={props.value}
        onChange={handleChange}
        sx={{
          height: "30px",
          width: "120px",
          borderRadius: "15px",
          textTransform: "capitalize",
        }}
        disableUnderline
        inputProps={{
          style: {
            border: 0,
            outline: "none",
          },
        }}
      >
        {props.placeholder && (
          <MenuItem disabled value="">
            <em>{props.placeholder}</em>
          </MenuItem>
        )}
        {props.options?.map((o) => (
          <MenuItem sx={{ textTransform: "capitalize" }} value={o.id}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
