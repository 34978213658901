import { Stack, Typography } from "@mui/material";
import React from "react";
import { LOGO_SIDEBAR } from "../../assets/brand";

export default function Maintenance() {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: "50px",
      }}
    >
      <img
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "72px",
        }}
        alt="Cashify Logo"
        src={LOGO_SIDEBAR}
      />
      <Typography
        sx={{
          fontSize: "30px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        MAINTENANCE MODE{" "}
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        The site is currently under maintenance. Please try again in a few
        minutes.
      </Typography>
    </Stack>
  );
}
