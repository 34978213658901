import { atom } from "recoil";
import { User } from "../types/User";
import { BotStatus } from "../types/BotStatus";
import { PaymentsConfiguration } from "../types/Config";

const localStorageEffect =
  (key: any) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const aCurrentSection = atom({
  key: "cashify_current_section",
  default: "Dashboard",
});
export const aCurrentSectionDescription = atom({
  key: "cashify_current_section_desc",
  default: "Welcome",
});

export const aProfile = atom<User | null>({
  key: "cashify_profile",
  default: null,
});

export const aBotStatus = atom<BotStatus>({
  default: undefined,
  key: "cashify_bot_status",
});

export const aPaymentsConfig = atom<PaymentsConfiguration>({
  key: "cashify_payments_config",
  default: undefined,
});

export const aNotifications = atom<number>({
  key: "cashify_notifications_count",
  default: 0,
});

export const aWindowState = atom<"hidden" | "visible">({
  default: "hidden",
  key: "aWindowState",
  effects: [localStorageEffect("aWindowState")],
});

export const aChatId = atom<string>({
  default: undefined,
  key: "aChatId",
  effects: [localStorageEffect("aChatId")],
});

export const aUserInfo = atom<any>({
  default: undefined,
  key: "aUserInfo",
  effects: [localStorageEffect("aUserInfo")],
});

export const aShowSidebar = atom<boolean>({
  key: "cashify_sidebar_visible",
  default: false,
});
