import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { aCurrentSection, aPaymentsConfig, aProfile } from "../core/states";
import { Navigate, Route, Routes } from "react-router";
import Sidebar from "../components/Sidebar";
import SectionAdminDashboard from "../sections/admin/dashboard";
import SectionAdminUsers from "../sections/admin/users";
import SectionUserDashboard from "../sections/user/dashboard";
import { useModal } from "mui-modal-provider";
import SectionUserSubscriptions from "../sections/user/subscriptions";
import SectionUserSubscribers from "../sections/user/subscribers";
import SectionUserInvoices from "../sections/user/invoices";
import SectionAdminSettings from "../sections/admin/settings";
import SectionUserSettings from "../sections/user/settings";
import SectionAdminDeployments from "../sections/admin/deployments";
import SectionUserCodes from "../sections/user/codes";
import { rtmGetPaymentsConfig, rtmGetProfile } from "../core/api";
import SectionAdminPayouts from "../sections/admin/payouts";
import { rtmGetBotStats, rtmGetTelegramConfig } from "../core/api/user";
import SectionUserNotifications from "../sections/user/notifications";
import SectionAdminNotifications from "../sections/admin/notifications";
import SectionUserPartnership from "../sections/user/partner";
import SectionUserAffiliateTools from "../sections/user/affiliate";
import ReconfigureBotDialog from "../dialogs/ReconfigureBotDialog";
import SectionUserBroadcast from "../sections/user/broadcast";
import { enqueueSnackbar } from "notistack";
import SectionAdminWallets from "../sections/admin/wallets";
import SectionAdminInvoices from "../sections/admin/invoices";
import SectionAdminTransactions from "../sections/admin/transactions";
import SectionAdminTelegramUsers from "../sections/admin/telegramusers";
import SectionUserProducts from "../sections/user/products";
import SectionUserSettingsProfile from "../sections/user/settings/profile";
import SectionUserSettingsPayments from "../sections/user/settings/payments";
import SectionUserSettingsConfig from "../sections/user/settings/configuration";
import SectionAdminEmails from "../sections/admin/emails/mailing";
import { Role } from "../types/Role";
import SectionAdminEmailTemplates from "../sections/admin/emails/templates";
import SectionAdminEmailTransactional from "../sections/admin/emails/transactional";
import OnboardingDialog from "../dialogs/Onboarding";
import AppBar from "../components/AppBar";
import SectionUserCoupons from "../sections/user/coupons";
import SectionAdminFeedbacks from "../sections/admin/feedbacks";
import SectionAdminLogs from "../sections/admin/logs";
import SectionAdminStripeAccounts from "../sections/admin/accounts";

export default function PageDashboard() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const [section] = useRecoilState(aCurrentSection);
  const [profile, setProfile] = useRecoilState(aProfile);
  const { showModal } = useModal();
  const [connectionError] = useState(false);
  const [, setConfig] = useRecoilState(aPaymentsConfig);

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      const cfg = await rtmGetPaymentsConfig();
      setConfig(cfg?.value);
      if (prof && prof.role === Role.User) {
        await rtmGetBotStats(); // Starts the bot if it is not already running.
        // Get bot config
        if (prof.boarded) {
          const botCfg = await rtmGetTelegramConfig();
          // Reconfigure dialog is shown only if bot token is set, but no username is in config for the bot.
          // Indicating that the login was failed.
          if (botCfg && botCfg.token && !botCfg.username) {
            // Show re-configure dialog
            const modal = showModal(ReconfigureBotDialog, {
              closeHandler(result) {
                modal.destroy();
              },
            });
          }
        } else {
          // Show onboarding dialog
          const modal = showModal(OnboardingDialog, {
            closeHandler(result) {
              modal.destroy();
            },
          });
        }
      }
    } catch (err: any) {
      console.log(err);
      enqueueSnackbar("Error loading your profile. ", { variant: "error" });
    }
  }

  useEffect(() => {
    loadProfile();
  }, []);

  // if (profile && !profile.boarded && profile.role === Role.User) {
  //   // Navigate to onboarding
  //   return <Navigate to={"/onboarding"} />;
  // }

  return (
    <Stack
      sx={{ height: "100vh", maxWidth: "1920px" }}
      direction={desktop ? "row" : "column"}
    >
      {connectionError && (
        <Stack
          sx={{
            position: "absolute",
            background: "gold",
            color: "black",
            width: "100%",
          }}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing="8px"
        >
          <Typography>Connection interrupted. Reconnecting...</Typography>
          <CircularProgress size={"20px"} />
        </Stack>
      )}
      <Sidebar />
      <Stack flex={1} overflow={"hidden"}>
        {section && <AppBar />}

        <Stack flex={1} overflow={"auto"}>
          {profile && profile.role === Role.Administrator && (
            <Routes>
              <Route path="/dashboard" element={<SectionAdminDashboard />} />
              <Route path="/users" element={<SectionAdminUsers />} />
              <Route
                path="/deployments"
                element={<SectionAdminDeployments />}
              />
              <Route path="/logs" element={<SectionAdminLogs />} />
              <Route path="/feedbacks" element={<SectionAdminFeedbacks />} />
              <Route path="/payouts" element={<SectionAdminPayouts />} />
              <Route
                path="/accounts"
                element={<SectionAdminStripeAccounts />}
              />
              <Route path="/settings" element={<SectionAdminSettings />} />
              <Route
                path="/emails/campaigns"
                element={<SectionAdminEmails />}
              />
              <Route
                path="/emails/templates"
                element={<SectionAdminEmailTemplates />}
              />
              <Route
                path="/emails/transactional"
                element={<SectionAdminEmailTransactional />}
              />
              <Route
                path="/notifications"
                element={<SectionAdminNotifications />}
              />
              <Route path="/wallets" element={<SectionAdminWallets />} />
              <Route path="/invoices" element={<SectionAdminInvoices />} />
              <Route
                path="/transactions"
                element={<SectionAdminTransactions />}
              />
              <Route
                path="/telegramusers"
                element={<SectionAdminTelegramUsers />}
              />

              <Route path="/*" element={<SectionAdminDashboard />} />
            </Routes>
          )}
          {profile && profile.role === Role.User && (
            <Routes>
              <Route path="/dashboard" element={<SectionUserDashboard />} />
              <Route
                path="/subscriptions"
                element={<SectionUserSubscriptions />}
              />
              <Route path="/products" element={<SectionUserProducts />} />
              <Route path="/subscribers" element={<SectionUserSubscribers />} />
              <Route path="/codes" element={<SectionUserCodes />} />
              <Route path="/coupons" element={<SectionUserCoupons />} />
              <Route path="/broadcast" element={<SectionUserBroadcast />} />
              <Route path="/invoices" element={<SectionUserInvoices />} />
              <Route path="/partner" element={<SectionUserPartnership />} />
              <Route
                path="/affiliation"
                element={<SectionUserAffiliateTools />}
              />
              {/* <Route path="/billing" element={<SectionUserBilling />} /> */}
              {/* <Route path="/settings" element={<SectionUserSettings />} /> */}
              <Route
                path="/settings/profile"
                element={<SectionUserSettingsProfile />}
              />
              <Route
                path="/settings/payments"
                element={<SectionUserSettingsPayments />}
              />
              <Route
                path="/settings/configuration"
                element={<SectionUserSettingsConfig />}
              />
              <Route
                path="/notifications"
                element={<SectionUserNotifications />}
              />
              <Route path="/*" element={<SectionUserDashboard />} />
            </Routes>
          )}
          {profile && profile.role === Role.Affiliate && (
            <Routes>
              <Route path="/dashboard" element={<SectionUserPartnership />} />
              <Route
                path="/settings"
                element={<SectionUserSettingsProfile />}
              />
              <Route
                path="/notifications"
                element={<SectionUserNotifications />}
              />
              <Route path="/*" element={<Navigate to={"/dashboard"} />} />
            </Routes>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
