import { SxProps, Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
export default function DualHeading(props: {
  h1: string;
  h2: string;
  h3?: string;
  sx?: SxProps;
  h1sx?: React.HTMLAttributes<HTMLSpanElement>;
  h2sx?: React.HTMLAttributes<HTMLSpanElement>;
  h3sx?: React.HTMLAttributes<HTMLSpanElement>;
  component?: any;
}) {
  return (
    <Typography sx={props.sx} component={props.component}>
      <motion.div
        style={{ display: "inline" }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        <span style={props.h1sx}>{props.h1}</span>
      </motion.div>{" "}
      <motion.div
        style={{ display: "inline" }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.25 }}
      >
        <span style={props.h2sx}>{props.h2}</span>
      </motion.div>
      <span style={props.h3sx}>{props.h3}</span>
    </Typography>
  );
}
