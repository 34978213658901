import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

import { IC_REFRESH, IC_STORE_ADD } from "../../assets/ui";
import { motion } from "framer-motion";
import { rtmGetProducts, rtmUpdateProduct } from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import CreateProductDialog from "../../dialogs/CreateProduct";
import DeleteProductDialog from "../../dialogs/DeleteProductDialog";
import EditProductDialog from "../../dialogs/EditProduct";
import { Product } from "../../types/Product";

export default function SectionUserProducts() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<any[]>([]);

  async function updateProduct(p: Product) {
    try {
      setBusy(true);
      await rtmUpdateProduct(p);
      enqueueSnackbar("Product has been updated. ", {
        variant: "success",
      });
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating a product. Error: " + err.message, {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _prods = await rtmGetProducts();
      setData(_prods || []);
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.error(err);
    }
    setBusy(false);
  }

  function showCreateDialog() {
    const modal = showModal(CreateProductDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          load();
        }
      },
    });
  }

  function showEditDialog(p: Product) {
    const modal = showModal(EditProductDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          load();
        }
      },
      data: p,
    });
  }
  function showDeleteDialog(prod: Product) {
    const modal = showModal(DeleteProductDialog, {
      async closeHandler(result) {
        if (result) {
          // proceed to delete the subscription
          prod.status = "archived";
          await updateProduct(prod);
          load();
        }
        modal.destroy();
      },
      data: prod,
    });
  }

  async function showDisableDialog(prod: Product) {
    // If subscription is disabled, we enable it.
    if (prod.status === "active") {
      // Enable
      prod.status = "draft";
      await updateProduct(prod);
      return;
    } else {
      // Otherwise
      prod.status = "active";
      await updateProduct(prod);
      return;
    }
  }

  useEffect(() => {
    setSection(`Products`);
    setSectionDescription(`Manage your products catalog.`);
    load();
  }, []);

  function RenderProduct(p: Product) {
    return (
      <TableRow>
        {!mobile && (
          <TableCell>
            <Typography color="#94969C">{p.id}</Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {p.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {p.description?.substring(0, 20)}...
          </Typography>
        </TableCell>
        <TableCell>
          {p.status === "archived" && (
            <Chip
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
              label="Archived"
              variant="outlined"
              color="warning"
              size="small"
            />
          )}
          {p.status === "active" && (
            <Chip
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
              label="Active"
              variant="outlined"
              color="success"
              size="small"
            />
          )}
          {p.status === "draft" && (
            <Chip
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
              label="Draft"
              variant="outlined"
              color="default"
              size="small"
            />
          )}
        </TableCell>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(p.updated).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {p.generateLicenseKeys
              ? p.licenseKeyLimit! > 0
                ? p.licenseKeyLimit + " uses"
                : "Unlimited Uses"
              : "Disabled"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {p.files && p.files.length > 0
              ? p.files.length + " Files"
              : "No Files"}
          </Typography>
        </TableCell>

        <TableCell align="right">
          {p.status === "active" && (
            <Button
              disabled={busy}
              onClick={() => showDisableDialog(p)}
              color="warning"
              title="Disable"
            >
              Disable
            </Button>
          )}
          {p.status === "draft" && (
            <Button
              disabled={busy}
              onClick={() => showDisableDialog(p)}
              color="success"
              title="Enable"
            >
              Enable
            </Button>
          )}
          {p.status !== "archived" && (
            <Button
              disabled={busy}
              onClick={() => showDeleteDialog(p)}
              color="error"
              title="Delete"
            >
              Delete
            </Button>
          )}
          {p.status !== "archived" && (
            <Button
              disabled={busy}
              onClick={() => showEditDialog(p)}
              title="Edit"
            >
              Edit
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "12px",
      }}
    >
      {mobile && (
        <Stack direction="row" justifyContent={"flex-end"} spacing={"8px"}>
          <Button
            variant="contained"
            disabled={busy}
            onClick={load}
            color="secondary"
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
          <Button
            disabled={busy}
            variant="contained"
            color="primary"
            startIcon={
              <SvgIcon>
                <IC_STORE_ADD />
              </SvgIcon>
            }
            onClick={showCreateDialog}
          >
            Create Product
          </Button>
        </Stack>
      )}
      <PFMTable
        sx={{ flex: 1 }}
        title="Products"
        description="List of all the products in your store."
        titleBadge={data.length + " Products"}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                disabled={busy}
                onClick={load}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
              <Button
                disabled={busy}
                variant="contained"
                color="primary"
                startIcon={
                  <SvgIcon>
                    <IC_STORE_ADD />
                  </SvgIcon>
                }
                onClick={showCreateDialog}
              >
                Create Product
              </Button>
            </>
          )
        }
        tableHead={
          <TableRow>
            {!mobile && <TableCell>ID</TableCell>}
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Description
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Last Modified
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Licensing
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Files
            </TableCell>
            <TableCell
              align="right"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderProduct(d))}</>}
      />
    </motion.div>
  );
}
