import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Add, Close, ExpandMoreRounded } from "@mui/icons-material";
export default function FAQ() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const [openAccordoin, setOpenAccordion] = useState<Boolean[]>([]);
  const theme = useTheme();
  return (
    <Stack
      direction={desktop ? "column" : "column"}
      spacing={desktop ? "70px" : "12px"}
      sx={{
        maxWidth: "1280px",
        width: "100%",
        alignSelf: "center",
        py: "48px",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {},
      }}
    >
      <Stack
        flex={1}
        spacing={"16px"}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <Typography
          sx={{
            padding: "8px 16px",
            background: "rgba(255, 255, 255, 0.10)",
            borderRadius: "5px",
          }}
        >
          FAQS
        </Typography>

        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "300",
            textAlign: "start",
            lineHeight: "100%",
            letterSpacing: "-1px",
            [theme.breakpoints.down("desktop")]: { fontSize: "40px" },
            [theme.breakpoints.down("tablet")]: {
              fontSize: "30px",
              textAlign: "center",
            },
          }}
        >
          Frequently Asked Questions
        </Typography>
      </Stack>
      <Stack flex={1}>
        <Accordion
          onChange={(e, checked) => {
            openAccordoin[0] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[0]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "#000",
            border: "none",
            borderBottom: openAccordoin[1] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[0] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              Does it automatically remove users when they are no longer
              subscribed?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Of course! The main purpose of Cashifybot is to restrict access to
              your channels to paid users only. However, you can also allow
              specific people to join without any subscriptions using Access
              Codes.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[1] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[1]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "#000",
            border: "none",
            borderBottom: openAccordoin[1] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[1] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              How can I migrate my subscribers from existing/other subscription
              bots?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              If you are already using another subscription management bot,
              follow the steps below to easily migrate your subscribers to
              cashifybot.
            </Typography>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              1. Set up a group/channel with Cashifybot. <br />
              2. Create an access code with a 1-month expiration.
              <br /> 3. Give the access code to your subscribers, and instruct
              them to use it with Cashifybot to join your new channel/group.
              <br /> 4. Instruct your subscribers to purchase a subscription
              before the code expires with Cashifybot.
              <br /> 5. Unsubscribe everyone from your old subscription
              management.
            </Typography>
            <br />
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Alternatively, you can also create a subscription with a 1 month
              free trial for your existing subscribers.{" "}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[2] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[2]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "#000",
            border: "none",
            borderBottom: openAccordoin[2] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[2] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              What kind of products can I sell?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Cashifybot allows you to sell digital assets, including files,
              links, and license keys. It also provides an API for validating
              license keys from your software applications.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[3] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[3]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "#000",
            border: "none",
            borderBottom: openAccordoin[3] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[3] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              Can I use Cashifybot with multiple channels or groups?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Of course! Cashifybot enables you to include multiple
              channels/groups in each subscription, allowing you to use multiple
              channels/groups in different subscriptions as well.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[4] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[4]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "#000",
            border: "none",
            borderBottom: openAccordoin[4] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[4] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              How do I get paid?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              Our customers are paid directly through Stripe Connect, as well as
              through various crypto currency networks, including Ethereum,
              Arbitrum One, Tron, and Polygon (Matic).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[5] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[5]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "#000",
            border: "none",
            borderBottom: openAccordoin[5] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[5] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              What are the payment methods for end-users?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              All payment methods supported by Stripe are available for
              end-users. This includes Credit/Debit Card, Stripe, Pay with
              PayPal, and more. Furthermore, you can also accept payments in
              popular crypto currencies on 4 different networks.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[6] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[6]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "#000",
            border: "none",
            borderBottom: openAccordoin[6] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[6] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              Can I issue refunds to Telegram users?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              No, Cashifybot does not currently support refund functionality.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[7] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[7]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "transparent",
            border: "none",
            borderBottom: openAccordoin[7] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[7] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              Does Cashifybot have a refund policy?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              No, Cashifybot does not have any refund policies.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          onChange={(e, checked) => {
            openAccordoin[8] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
          sx={{
            background: openAccordoin[8]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "transparent",
            border: "none",
            borderBottom: openAccordoin[8] ? "none" : "0.5px solid #FFF",
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[8] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              What is the minimum payout limit?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              None! Our payment system sends payouts instantly to your Stripe
              Connect, and/or crypto wallets as soon as you make a sale.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          sx={{
            background: openAccordoin[9]
              ? "linear-gradient(90deg, #764CBA 0%, #654EC5 100%)"
              : "transparent",
            border: "none",
            borderBottom: openAccordoin[9] ? "none" : "0.5px solid #FFF",
          }}
          onChange={(e, checked) => {
            openAccordoin[9] = checked;
            setOpenAccordion([...openAccordoin]);
          }}
        >
          <AccordionSummary expandIcon={openAccordoin[9] ? <Close /> : <Add />}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "25px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              }}
            >
              What content types are allowed for sale?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "20px",
                fontFamily: "Outfit",
                [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "15px",
                },
              }}
            >
              If you are using Stripe, please refer to Stripe's terms and
              conditions. At Cashifybot, we have a zero-tolerance policy for
              explicit material, including gore, child abuse, and rape porn
              videos. We take these issues very seriously, and any accounts
              found providing such content will be immediately disabled without
              warning, and reported to the relevant authorities.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Stack>
  );
}
