import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { IC_ALERT, IC_USER_SETTINGS } from "../assets/ui";
import { rtmEnableUser } from "../core/api/admin";
import { User } from "../types/User";

export default function EnableUserDialog(props: DialogProps<User>) {
  const [notify, setNotify] = useState<boolean>(false);

  const [busy, setBusy] = useState(false);

  async function enableUser() {
    try {
      setBusy(true);
      await rtmEnableUser(props.data?.uid!);
      enqueueSnackbar(
        `User account for ${props.data?.name} was activated successfully.`,
        {
          variant: "success",
        }
      );
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error updating user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_USER_SETTINGS />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Enable User
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Re-activate user account.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Email
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Role
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.role}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Comments
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.comments || "N/A"}
              </Typography>
            </Stack>

            <Alert color="warning" variant="filled" icon={<IC_ALERT />}>
              <AlertTitle>Note</AlertTitle>
              <Typography>
                Any paused subscriptions of this user on Stripe will be
                activated immediately, unless if they are cancelled already.
              </Typography>
            </Alert>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Nevermind
            </Button>
            <Button
              onClick={enableUser}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Activate User
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
