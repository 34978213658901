import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";

export default function TextCard(props: { title?: string; Number: string }) {
  const theme = useTheme();
  return (
    <Stack direction={"row"} spacing="10px" sx={{ alignItems: "start" }}>
      <Typography
        fontSize={"20px"}
        fontWeight={900}
        color={"rgba(255, 255, 255, 0.70)"}
        sx={{
          [theme.breakpoints.down("desktop")]: {
            fontSize: "15px",
          },
        }}
      >
        {props.Number}
      </Typography>
      <Stack>
        <Typography
          fontSize={"18px"}
          fontWeight={400}
          color={"#fff"}
          sx={{
            [theme.breakpoints.down("desktop")]: {
              fontSize: "12px",
            },
          }}
        >
          {props.title}
        </Typography>
      </Stack>
    </Stack>
  );
}
