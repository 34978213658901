export const DEFAULT_PRIVACY_POLICY = `Welcome to our service. Here's an overview of how we handle your data:

<b>1. Information We Collect</b>
- <b>Stripe Integration:</b> For payments, we integrate with Stripe. Refer to Stripe's Privacy Policy for their data practices.
- <b>Telegram User Data:</b> We collect and use Telegram user data, including user names and IDs, to provide our services.

<b>2. Data Deletion Requests</b>
- Users should contact the bot owner directly to request data deletion.

<b>3. Telegram Interaction</b>
- Interactions with our bot are subject to Telegram's terms and policies.

<b>4. Updates and Modifications</b>
- This policy may be updated periodically. Please review it regularly.

<b>5. Consent</b>
- By using our bot, you accept this privacy policy.

By using our bot, you acknowledge that you have read and understood this privacy policy.
`;
