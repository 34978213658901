import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import {
  IC_AVATAR1,
  IC_AVATAR3,
  IC_AVATAR4,
  IC_AVATAR5,
  IC_DOTGROUP,
  IC_SCREENSHOT,
} from "../../assets/ui";
import ColorImg from "../../assets/ui/colorpic.png";
import { IC_AVATAR2 } from "../../assets/ui";
import { motion, useScroll, useTransform } from "framer-motion";
import ScreenShotsText from "./components/ScreenShotsText";

export default function ScreenShot() {
  const desktop = useMediaQuery("(min-width: 1024px)");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const scroll = useScroll();

  const transform = useTransform(
    scroll.scrollYProgress,
    [0, 0.1],
    ["40px", "-40px"]
  );

  return (
    <Stack
      direction={"row"}
      sx={{
        backgroundImage: `url(${ColorImg}) `,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
        alignItems: "start",
        justifyContent: "center",
        pb: "48px",
      }}
    >
      {/* Image */}
      <Stack
        sx={{
          minWidth: "207px",
          width: "207px",
          height: "431px",
          alignItems: "center",
          justifyContent: "center",
          zindex: 1,
          position: "absolute",
          [theme.breakpoints.down("desktop")]: {
            height: "438px",
            minWidth: "218px",
          },
          [theme.breakpoints.down("tablet")]: {
            minWidth: "145px",
            width: "145px",
            height: "294px",
          },
        }}
      >
        <motion.div style={{ display: "block", translateY: transform }}>
          <img src={IC_SCREENSHOT} alt="" style={{ width: "100%" }} />
        </motion.div>
        {/* <IC_DASHBOARDIMG width="100%" /> */}
      </Stack>
      <Stack
        // spacing={desktop ? "100px" : "8px"}
        // direction={desktop ? "row" : "column"}
        // paddingTop={desktop ? "100px" : "400px"}
        sx={{
          flexDirection: "row",
          pt: "100px",
          gap: "100px",
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            gap: "8px",
            pt: "400px",
          },
        }}
      >
        <Stack
          spacing={desktop ? "50px" : "8px"}
          zIndex={2}
          sx={{
            [theme.breakpoints.down("tablet")]: {
              px: "24px",
            },
          }}
        >
          <motion.div
            style={{
              transformOrigin: "0 100%",
            }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <ScreenShotsText
              mainSx={{
                background: "#085473",
                boxShadow: " 4px -4px 4px 2px rgba(24, 26, 78, 0.25)",
                width: "360px",
                [theme.breakpoints.down("tablet")]: {
                  width: "auto",
                },
              }}
              avatar={<IC_AVATAR1 />}
              name="David Thompson"
              discription=" Hi, I’m an online teacher and I need a payment system for my courses."
            />
          </motion.div>
          <motion.div
            style={{
              transformOrigin: mobile ? "100% 100%" : "0 100%",
            }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.25 }}
            viewport={{ once: true }}
          >
            <ScreenShotsText
              mainSx={{
                background: "#20457D",
                boxShadow: " 4px -4px 4px 2px rgba(24, 26, 78, 0.25)",
                width: "360px",
                [theme.breakpoints.down("tablet")]: {
                  borderRadius: "30px 30px 0px 30px",
                  flexDirection: "row-reverse",
                  justifyContent: "flex-end",
                  width: "auto",
                },
              }}
              sx={{
                [theme.breakpoints.down("tablet")]: {
                  alignItems: "flex-end",
                  textAlign: "end",
                },
              }}
              avatar={<IC_AVATAR2 />}
              name="Ahmed Khan"
              discription="How can a freelance developer like me offer a monthly retainer for my services?"
            />
          </motion.div>
          <motion.div
            style={{
              transformOrigin: "0 100%",
            }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            viewport={{ once: true }}
          >
            <ScreenShotsText
              mainSx={{
                background: "#383588",
                width: "360px",
                [theme.breakpoints.down("tablet")]: {
                  borderRadius: "30px 30px 30px 0px",
                  width: "auto",
                },
              }}
              avatar={<IC_AVATAR3 />}
              name="Carlos Rivera"
              discription=" How can a freelance developer like me offer a monthly retainer for my
          services?"
            />
          </motion.div>
        </Stack>

        <Stack
          spacing={desktop ? "50px" : "8px"}
          zIndex={2}
          sx={{
            [theme.breakpoints.down("tablet")]: {
              px: "24px",
            },
          }}
        >
          <motion.div
            style={{
              transformOrigin: "100% 100%",
            }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.75 }}
            viewport={{ once: true }}
          >
            <ScreenShotsText
              mainSx={{
                background: "#0F5076",
                boxShadow: "  -4px -4px 4px 2px rgba(37, 32, 96, 0.25)",
                flexDirection: "row-reverse",
                borderRadius: "30px 30px 0px 30px",
                gap: "10px",
                width: "360px",
                [theme.breakpoints.down("tablet")]: {
                  borderRadius: "30px 30px 0px 30px",
                  width: "auto",
                },
              }}
              sx={{
                alignItems: "flex-end",
                padding: " 0 0 0 16px",
                textAlign: "end",
              }}
              avatar={<IC_AVATAR4 />}
              name="Aisha Williams"
              discription=" How can a freelance developer like me offer a monthly retainer for my
          services?"
            />
          </motion.div>
          <motion.div
            style={{
              transformOrigin: mobile ? "0% 100%" : "100% 100%",
            }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 1 }}
            viewport={{ once: true }}
          >
            <ScreenShotsText
              mainSx={{
                background: "#2D3C83",
                boxShadow: "  -4px -4px 4px 2px rgba(37, 32, 96, 0.25)",
                flexDirection: "row-reverse",
                borderRadius: "30px 30px 0px 30px",
                gap: "10px",
                width: "360px",
                [theme.breakpoints.down("tablet")]: {
                  borderRadius: "30px 30px 30px 0px",
                  flexDirection: "row",
                  width: "auto",
                },
              }}
              sx={{
                alignItems: "flex-end",
                padding: " 0 0 0 16px",
                justifyContent: "flex-start",
                textAlign: "end",
                width: "360px",
                [theme.breakpoints.down("tablet")]: {
                  alignItems: "flex-start",
                  textAlign: "start",
                  width: "auto",
                },
              }}
              avatar={<IC_AVATAR5 />}
              name="Alex Johnson"
              discription="I’m a trading strategist looking to offer premium trading signals and insights to my clients."
            />
          </motion.div>
          <motion.div
            style={{
              transformOrigin: "100% 100%",
              alignItems: "end",
              display: "flex",
              justifyContent: "flex-end",
            }}
            viewport={{ once: true }}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 1.25 }}
          >
            <ScreenShotsText
              mainSx={{
                background: "#2D3C83",
                flexDirection: "row-reverse",
                borderRadius: "30px 30px 0px 30px",
                gap: "10px",
                width: "130px",
                alignSelf: "flex-end",
                padding: "24px 32px",
                [theme.breakpoints.down("tablet")]: {
                  alignSelf: "flex-end",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
              sx={{
                padding: " 0 0 0 16px",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                [theme.breakpoints.down("tablet")]: {
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
              dot={<IC_DOTGROUP />}
            />
          </motion.div>
        </Stack>
      </Stack>
    </Stack>
  );
}
