/* eslint react-hooks/exhaustive-deps: 0  */
import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  ToggleButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aPaymentsConfig,
  aProfile,
} from "../../core/states";
import { useModal } from "mui-modal-provider";
import {
  IC_ALERT,
  IC_BALANCE,
  IC_CREDIT_CARD,
  IC_PAID,
  IC_USERS,
} from "../../assets/ui";
import {
  Bar,
  BarChart,
  Legend,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
} from "recharts";
import moment from "moment";
import PFMTable from "../../components/PFMTable";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";

import { enqueueSnackbar } from "notistack";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { motion } from "framer-motion";
import BalanceErrorDialog from "../../dialogs/BalanceError";
import { rtmGetProfile } from "../../core/api";
import { rtmGetStats } from "../../core/api/user";
import { Invoice } from "../../types/Invoice";
import { ActiveSubscription } from "../../types/ActiveSubscription";

export default function SectionUserDashboard() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [config] = useRecoilState(aPaymentsConfig);

  const [range, setRange] = useState<"subscriptions" | "invoices">(
    "subscriptions"
  );
  const [chartData, setChartData] = useState<
    {
      day: string;
      subscribers: number;
      subscriptions: number;
    }[]
  >([]);

  const [stats, setStats] = useState<{
    subscribers: {
      total: number;
      $new: number;
    };
    subscriptions: {
      total: number;
      expiring: number;
      recent: ActiveSubscription[];
    };
    invoices: {
      paid: number;
      open: number;
      recent: Invoice[];
    };
    analytics: {
      newSubscriptions: number;
      totalSubscriptions: number;
      date: number;
    }[];
    balances: {
      pendingWithdraw: number;
    };
  }>();

  const invoicesPaid = useMemo(() => {
    const amnt = parseStripeAmount(stats?.invoices.paid || 0);
    return `${getCurrencySymbol(profile?.currency!)}${amnt.dollars}.${
      amnt.cents
    }`;
  }, [stats]);

  const invoicesOpen = useMemo(() => {
    const amnt = parseStripeAmount(stats?.invoices.open || 0);
    return `${getCurrencySymbol(profile?.currency!)}${amnt.dollars}.${
      amnt.cents
    }`;
  }, [stats]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { showModal } = useModal();

  async function load() {
    try {
      const _stats = await rtmGetStats();
      setStats(_stats);
      const _data: {
        day: string;
        subscribers: number;
        subscriptions: number;
      }[] = [];
      // Load the chart data
      for (let aof of _stats.analytics || []) {
        // The date is in unix format. So load it now
        const _date = moment.unix(aof.date);
        _data.push({
          day: _date.format("MMM DD, YYYY"),
          subscribers: aof.newSubscriptions,
          subscriptions: aof.totalSubscriptions,
        });
      }
      setChartData(_data);

      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      console.error("Error loading dashboard data. ", err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    setSection(`Dashboard`);
    setSectionDescription(`Welcome back, ${profile?.name}!`);
    load();
  }, []);

  function RenderRecentSubscriptionsHeader() {
    return (
      <TableRow>
        <TableCell>User</TableCell>
        <TableCell>Subscription</TableCell>
        <TableCell>Updated</TableCell>
        <TableCell>Expiry</TableCell>
        <TableCell>Price</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>
    );
  }

  function RenderRecentInvoicesHeader() {
    return (
      <TableRow>
        {!mobile && <TableCell>ID</TableCell>}
        <TableCell>User</TableCell>
        <TableCell>Time</TableCell>
        <TableCell>Fee</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>
    );
  }

  function RenderSubscription(sub: ActiveSubscription) {
    const amnt = parseStripeAmount(sub.subscription?.price.amount || 0);
    const sym = getCurrencySymbol(sub.subscription?.price.currency || "usd");
    const exp =
      sub.source === "stripe"
        ? moment.unix(sub.data.current_period_end)
        : sub.data.currentPeriodEnd
        ? moment.unix(sub.data.currentPeriodEnd!)
        : undefined;

    const str = moment.unix(sub.updated);
    return (
      <TableRow>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Avatar src={sub.user?.image} />
            <Stack>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                {sub.user?.name}
              </Typography>
              {sub.user?.username && (
                <Typography
                  sx={{
                    color: "#858585",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 8,
                    },
                  }}
                >
                  @{sub.user?.username}
                </Typography>
              )}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            color: "#858585",
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sub.subscription?.name}
        </TableCell>

        <TableCell
          sx={{
            color: "#858585",
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {str?.format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell
          sx={{
            color: "#858585",
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {exp && exp.isValid()
            ? exp!.format("MMM DD, YYYY hh:mm:ss a")
            : "Never"}
        </TableCell>
        <TableCell
          sx={{
            color: "#858585",
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
            label={sub.status}
            size="small"
            variant="identifier"
          />
        </TableCell>
      </TableRow>
    );
  }

  function RenderInvoice(inv: Invoice) {
    const amnt = parseStripeAmount(
      inv.source === "stripe"
        ? inv.data?.amount_paid || inv.data?.amount_due
        : inv.data.amount.fiat.amount * 100
    );
    const sym = getCurrencySymbol(
      inv.source === "stripe"
        ? inv.data?.currency
        : inv.data.amount.fiat.currency
    );

    let _fee = sym;
    if (inv.source === "crypto") {
      _fee += inv.data.fee?.fiat?.amount || "0.0";
    } else {
      const _stripeAmnt = parseStripeAmount(inv.fee);
      _fee += _stripeAmnt.dollars + "." + _stripeAmnt.cents;
    }
    return (
      <TableRow>
        {!mobile && (
          <TableCell>
            <Chip
              label={inv.source === "stripe" ? inv.data.id : inv.data.id}
              variant="identifier"
              size="small"
            />
          </TableCell>
        )}
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Avatar src={inv.customerInfo?.image} />
            <Stack>
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                {inv.customerInfo?.name}
              </Typography>
              {inv.customerInfo?.username && (
                <Typography
                  sx={{
                    color: "#858585",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 8,
                    },
                  }}
                >
                  @{inv.customerInfo?.username}
                </Typography>
              )}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            color: "#858585",
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {moment.unix(inv.created).format("MMM DD, YYYY hh:mm:ss a")}
        </TableCell>
        <TableCell
          sx={{
            color: "#858585",
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {_fee}
        </TableCell>

        <TableCell
          sx={{
            color: "#858585",
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
            label={inv.status}
            size="small"
            variant="identifier"
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        flex: 1,
        display: "flex",
        padding: "12px",
        gap: "28px",
        flexDirection: "column",
      }}
    >
      <Stack
        direction={"row"}
        rowGap={mobile ? "12px" : "24px"}
        columnGap={mobile ? "12px" : "24px"}
        flexWrap={"wrap"}
      >
        <motion.div
          style={{ width: mobile ? "100%" : undefined }}
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.1, delay: 0.2 }}
          exit={{ scale: 0 }}
        >
          <Paper
            sx={{
              minHeight: "180px",
              minWidth: "300px",
              background: "#0d0d0d",
              borderRadius: "8px",
              border: "1px solid #FFF0",
              transition: "all .3s",
              ":hover": {
                border: "1px solid #0A5A",
              },
              [theme.breakpoints.down("md")]: {
                minWidth: "100%",
                width: "100%",
                minHeight: "auto",
              },
            }}
          >
            <Stack
              sx={{
                p: "24px",
                gap: "24px",
                [theme.breakpoints.down("md")]: {
                  p: "12px",
                  gap: "12px",
                },
              }}
            >
              <Stack direction={"row"} spacing={"14px"} alignItems={"center"}>
                <SvgIcon
                  sx={{
                    background: "#0A5",
                    height: "48px",
                    width: "48px",
                    p: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <IC_PAID />
                </SvgIcon>
                <Typography fontWeight={500} fontSize={20}>
                  Earnings
                </Typography>
              </Stack>
              <Stack spacing={"4px"}>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontWeight={500} fontSize={24}>
                    {invoicesPaid}
                  </Typography>
                  <Typography>Volume this month</Typography>
                </Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontSize={14} sx={{ color: "#858585" }}>
                    {invoicesOpen}
                  </Typography>
                  <Typography fontSize={14} sx={{ color: "#858585" }}>
                    Currently pending
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </motion.div>

        <motion.div
          style={{ width: mobile ? "100%" : undefined }}
          viewport={{ once: true }}
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.1, delay: 0.3 }}
          exit={{ scale: 0 }}
        >
          <Paper
            sx={{
              minHeight: "180px",
              minWidth: "300px",
              background: "#0d0d0d",
              borderRadius: "8px",
              transition: "all .3s",
              border: "1px solid #FFF0",
              ":hover": {
                border: "1px solid #48FA",
              },
              [theme.breakpoints.down("md")]: {
                minWidth: "100%",
                width: "100%",
                minHeight: "auto",
              },
            }}
          >
            <Stack
              sx={{
                p: "24px",
                gap: "24px",
                [theme.breakpoints.down("md")]: {
                  p: "12px",
                  gap: "12px",
                },
              }}
            >
              <Stack direction={"row"} spacing={"14px"} alignItems={"center"}>
                <SvgIcon
                  sx={{
                    background: "#48F",
                    height: "48px",
                    width: "48px",
                    p: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <IC_USERS />
                </SvgIcon>
                <Typography fontWeight={500} fontSize={20}>
                  Subscribers
                </Typography>
              </Stack>
              <Stack spacing={"4px"}>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontWeight={500} fontSize={24}>
                    {stats?.subscribers.total || "0"}
                  </Typography>
                  <Typography>Active subscribers</Typography>
                </Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontSize={14} sx={{ color: "#858585" }}>
                    {stats?.subscribers.$new}
                  </Typography>
                  <Typography fontSize={14} sx={{ color: "#858585" }}>
                    New this month
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </motion.div>

        <motion.div
          style={{ width: mobile ? "100%" : undefined }}
          viewport={{ once: true }}
          initial={{ scale: 0.8 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.1, delay: 0.4 }}
          exit={{ scale: 0 }}
        >
          <Paper
            sx={{
              minHeight: "180px",
              minWidth: "300px",
              background: "#0d0d0d",
              border: "1px solid #FFF0",
              borderRadius: "8px",
              transition: "all .3s",
              ":hover": {
                border: "1px solid #448A",
              },
              [theme.breakpoints.down("md")]: {
                minWidth: "100%",
                width: "100%",
                minHeight: "auto",
              },
            }}
          >
            <Stack
              sx={{
                p: "24px",
                gap: "24px",
                [theme.breakpoints.down("md")]: {
                  p: "12px",
                  gap: "12px",
                },
              }}
            >
              <Stack direction={"row"} spacing={"14px"} alignItems={"center"}>
                <SvgIcon
                  sx={{
                    background: "#448",
                    height: "48px",
                    width: "48px",
                    p: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <IC_CREDIT_CARD />
                </SvgIcon>
                <Typography fontWeight={500} fontSize={20}>
                  Active Subscriptions
                </Typography>
              </Stack>
              <Stack spacing="4px">
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontWeight={500} fontSize={24}>
                    {stats?.subscriptions.total || "0"}
                  </Typography>
                  <Typography>Active</Typography>
                </Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                  <Typography fontSize={14} sx={{ color: "#858585" }}>
                    {stats?.subscriptions.expiring}
                  </Typography>
                  <Typography fontSize={14} sx={{ color: "#858585" }}>
                    Will expire today
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </motion.div>
      </Stack>

      <Paper
        sx={{
          height: "400px",
          p: "26px",
          background: "#0d0d0d",
          borderRadius: "8px",
          [theme.breakpoints.down("md")]: {
            p: "12px",
          },
        }}
      >
        <Stack sx={{ height: "100%" }} spacing={"0px"}>
          <Typography fontSize={"24px"} sx={{ fontWeight: 500 }}>
            Subscriptions
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography
              fontSize={"14px"}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 16,
                },
              }}
            >
              Over time
            </Typography>
            <div
              style={{
                height: "5px",
                width: "5px",
                background: "#858585",
                borderRadius: "100px",
              }}
            />
            <Typography
              fontSize={"14px"}
              sx={{
                color: "#858585",
                [theme.breakpoints.down("md")]: {
                  fontSize: 12,
                },
              }}
            >
              for the month of {moment().startOf("month").format("MMMM YYYY")}
            </Typography>
          </Stack>
          <Divider sx={{ py: "4px" }} />
          <Stack flex={1} sx={{ pt: "4px" }}>
            {chartData && (
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <BarChart data={chartData}>
                  <defs>
                    <linearGradient
                      id="grad1"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{
                          stopColor: "#48F",
                        }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: "#448",
                        }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="grad2"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{
                          stopColor: "#095",
                        }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: "#090",
                        }}
                      />
                    </linearGradient>
                  </defs>
                  <ChartTooltip
                    cursor={false}
                    contentStyle={{
                      background: "#303030",
                      border: "0px solid #707070",
                      borderRadius: "8px",
                      fontFamily: "Poppins",
                      boxShadow: "0px 0px 5px 0px #4488FF",
                    }}
                    itemStyle={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#BBB",
                    }}
                    content={({ payload, label }) => {
                      return (
                        <Stack
                          spacing={"16px"}
                          sx={{
                            width: "100%",
                            background: "#202020",
                            px: "14px",
                            py: "8px",
                            borderRadius: "8px",
                            border: "1px solid #88F",
                          }}
                          justifyContent={"center"}
                          alignItems={"start"}
                        >
                          <Typography>{label}</Typography>
                          {payload?.map((p) => {
                            return (
                              <Stack
                                spacing={"8px"}
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <div
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "4px",
                                    background:
                                      p.name === "subscribers"
                                        ? "linear-gradient(180deg, #48F, #448)"
                                        : "linear-gradient(180deg, #090, #050)",
                                  }}
                                />
                                <Typography>{p.value}</Typography>
                                <Typography>
                                  {p.name === "subscribers"
                                    ? "Unique Subscribers"
                                    : "Active Subscriptions"}
                                </Typography>
                              </Stack>
                            );
                          })}
                        </Stack>
                      );
                    }}
                  />
                  {!mobile && <YAxis axisLine={false} tickLine={false} />}
                  <XAxis dataKey={"day"} axisLine={false} tickLine={false} />
                  <Bar
                    spacing={mobile ? "0px" : "10px"}
                    dataKey={"subscribers"}
                    stackId={"a"}
                    background={<Rectangle fill="#FFF2" radius={2} />}
                    enableBackground={"true"}
                    radius={2}
                    barSize={mobile ? 6 : 12}
                    style={{
                      fill: "url(#grad1)",
                    }}
                  />
                  <Bar
                    spacing={mobile ? "0px" : "10px"}
                    dataKey={"subscriptions"}
                    stackId={"b"}
                    background={<Rectangle fill="#FFF2" radius={2} />}
                    enableBackground={"true"}
                    radius={2}
                    barSize={mobile ? 6 : 12}
                    style={{
                      fill: "url(#grad2)",
                    }}
                  />
                  <Legend
                    content={({ payload }) => {
                      return (
                        <Stack
                          direction={"row"}
                          sx={{
                            mt: "16px",
                            width: "100%",
                            gap: "16px",
                            [theme.breakpoints.down("md")]: {
                              gap: "8px",
                            },
                          }}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          {payload?.map((p) => {
                            return (
                              <Stack
                                spacing={"8px"}
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <div
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "2px",
                                    background:
                                      p.value === "subscribers"
                                        ? "linear-gradient(180deg, #48F, #448)"
                                        : "linear-gradient(180deg, #090, #050)",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    [theme.breakpoints.down("md")]: {
                                      fontSize: 10,
                                    },
                                  }}
                                >
                                  {p.value === "subscribers"
                                    ? "Unique Subscribers"
                                    : "Active Subscriptions"}
                                </Typography>
                              </Stack>
                            );
                          })}
                        </Stack>
                      );
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Stack>
        </Stack>
      </Paper>
      <PFMTable
        sx={{ p: 0, m: 0, bgcolor: "#0d0d0d" }}
        title="Recent Activity"
        description="Recent subscriptions, invoices, etc."
        actionButtons={
          <Stack>
            <StyledToggleButtonGroup
              exclusive
              value={range}
              onChange={(c, e) => setRange(e)}
            >
              <ToggleButton value={"subscriptions"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{
                    px: "16px",
                    [theme.breakpoints.down("md")]: {
                      px: "8px",
                    },
                  }}
                >
                  {range === "subscriptions" && (
                    <Badge
                      variant="dot"
                      color={range === "subscriptions" ? "success" : undefined}
                    ></Badge>
                  )}
                  <Typography
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: "10px",
                      },
                    }}
                  >
                    Recent Subscriptions
                  </Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={"invoices"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{
                    px: "16px",
                    [theme.breakpoints.down("md")]: {
                      px: "8px",
                    },
                  }}
                >
                  {range === "invoices" && (
                    <Badge
                      variant="dot"
                      color={range === "invoices" ? "success" : undefined}
                    ></Badge>
                  )}
                  <Typography
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: "10px",
                      },
                    }}
                  >
                    Recent Invoices
                  </Typography>
                </Stack>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>
        }
        tableHead={
          range === "subscriptions" ? (
            RenderRecentSubscriptionsHeader()
          ) : range === "invoices" ? (
            RenderRecentInvoicesHeader()
          ) : (
            <></>
          )
        }
        tableBody={
          range === "subscriptions"
            ? stats?.subscriptions.recent.map((r) => RenderSubscription(r))
            : stats?.invoices.recent.map((i) => RenderInvoice(i))
        }
      />
    </motion.div>
  );
}
