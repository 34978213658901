import React from "react";
import { Stack } from "@mui/material";
import { LOGO_SIDEBAR } from "../assets/brand";
import { easeInOut, motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";

export default function LoadingView() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        zIndex: 100,
      }}
    >
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <motion.img
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 2,
            ease: easeInOut,
          }}
          animate={{
            maskImage: [
              "linear-gradient(to right,  transparent, black 0%, transparent)",
              "linear-gradient(to right,  transparent, black 100%, transparent)",
            ],
            filter: ["grascale(100%)", "grayscale(0%)"],
          }}
          className="logo"
          style={{
            pointerEvents: "none",
            objectFit: "contain",
            height: desktop ? "100px" : "64px",
          }}
          alt="Cashify Logo"
          src={LOGO_SIDEBAR}
        />
      </Stack>
    </Stack>
  );
}
