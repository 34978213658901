import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  stackClasses,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_EMAIL, IC_ROCKET, IC_SCHEDULE } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { Add, Edit } from "@mui/icons-material";
import { DateTimeField } from "@mui/x-date-pickers";
import moment from "moment";
import { Email } from "../types/Email";
import { EmailTemplate } from "../types/EmailTemplate";
import {
  rtmGetEmailTemplates,
  rtmGetEstimatedAudience,
  rtmListUsers,
  rtmUpdateEmail,
} from "../core/api/admin";
import { useModal } from "mui-modal-provider";
import EditTemplateDialog from "./EditTemplate";
import PFMInput from "../components/PFMInput";
import { AutocompleteOption } from "../components/PFMAutoComplete";

export default function EditCampaignDialog(props: DialogProps<Email>) {
  const [data, setData] = useState<Partial<Email>>(props.data || {});

  const [type, setType] = useState<"scheduled" | "instant">("instant");
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate>();
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [busy, setBusy] = useState(false);
  const [schedule, setSchedule] = useState<any>(moment().add(1, "hour"));
  const [audienceSize, setAudienceSize] = useState(0);

  const [users, setUsers] = useState<AutocompleteOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<AutocompleteOption[]>([]);
  const [userSelectionText, setUserSelectionText] = useState("");

  const { showModal } = useModal();

  async function loadEstimatedAudience() {
    try {
      // load email templates
      if (data.audience) {
        const _aud = await rtmGetEstimatedAudience(data.audience);
        setAudienceSize(_aud);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data for email dialog.", err);
    }
  }

  function createTemplate() {
    const modal = showModal(EditTemplateDialog, {
      closeHandler(result) {
        modal.destroy();
        load();
      },
    });
  }

  async function save() {
    try {
      if (!selectedTemplate) {
        enqueueSnackbar("Please select a template. ", { variant: "error" });
        return;
      }
      if (!data.subject) {
        enqueueSnackbar("Please type a subject for the email. ", {
          variant: "error",
        });
        return;
      }
      if (!data.audience) {
        enqueueSnackbar("Please select the audience. ", { variant: "error" });
        return;
      }
      // Save broadcast.
      setBusy(true);

      await rtmUpdateEmail({
        id: data.id,
        audience: data.audience,
        audienceCount: audienceSize,
        content: selectedTemplate?.content!,
        schedule: type === "instant" ? undefined : schedule.unix(),
        subject: data.subject,
        template: selectedTemplate.name,
        customAudience: selectedUsers.map((su) => su.id),
      });

      enqueueSnackbar("Campaign has been created. ", { variant: "success" });

      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error creating campaign. Please try again. ", {
        variant: "error",
      });
      console.error("Error creating campaign. ", err);
    }
    setBusy(false);
  }

  async function loadUsers() {
    try {
      setBusy(true);
      const _users = await rtmListUsers({
        search: userSelectionText,
        page: 0,
        sort: {
          type: "name",
          value: "asc",
        },
      });
      let _out: AutocompleteOption[] = [];
      for (let _u of _users.data) {
        _out.push({
          id: _u.uid,
          label: _u.email,
          data: _u,
        });
      }
      setUsers(_out);
    } catch (err: any) {
      enqueueSnackbar("Error loading users. ", { variant: "error" });
      console.error("Error loading users. ", err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      // load email templates
      const _temps = await rtmGetEmailTemplates();
      if (_temps) {
        setTemplates(_temps);
      }
      if (props.data?.id) {
        if (props.data.schedule) {
          setSchedule(moment.unix(props.data.schedule));
          setType("scheduled");
        }
        setSelectedTemplate(
          _temps.find((t) => t.name === props.data?.template)
        );
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data for email dialog.", err);
    }
  }

  function RenderTemplate(t: EmailTemplate) {
    return (
      <Stack
        onClick={() => setSelectedTemplate(t)}
        sx={{
          width: "250px",
          height: "300px",
          border:
            selectedTemplate?.id === t.id
              ? "1px solid #48F"
              : "1px solid #FFF3",
          borderRadius: "12px",
          position: "relative",
          overflow: "hidden",
          ":hover": {
            [`& .${stackClasses.root}`]: {
              opacity: 1,
            },
          },
        }}
        alignItems={"start"}
        justifyContent={"flex-end"}
        spacing={"8px"}
      >
        <img
          src={t.thumbnail}
          alt={t.name}
          style={{
            flex: 1,
            objectFit: "contain",
            width: "100%",
          }}
        />
        <Typography fontSize={18} sx={{ padding: "8px" }}>
          {t.name}
        </Typography>
      </Stack>
    );
  }

  function RenderSelectedTemplate() {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: selectedTemplate?.content || "" }}
      />
    );
  }

  useEffect(() => {
    loadUsers();
    load();
  }, []);

  useEffect(() => {
    loadEstimatedAudience();
  }, [data.audience]);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullScreen
      maxWidth={"md"}
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_EMAIL />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Create an Email Capmaign
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Send an email to users on Cashifybot.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
          <PFMInput
            label="Subject"
            text={data.subject}
            placeholder="Enter a subject..."
            onUpdate={(t) => setData({ ...data, subject: t })}
          />
          {/* Affiliation Kind  */}
          <Stack spacing={"4px"}>
            <Typography>When do you want to start this campaign?</Typography>
            <Stack
              direction={"row"}
              spacing={"8px"}
              sx={{ alignSelf: "start" }}
            >
              <Stack
                onClick={() => setType("instant")}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    type === "instant" ? "1px solid #48F" : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                  minWidth: "450px",
                }}
              >
                <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                  <SvgIcon>
                    <IC_ROCKET />
                  </SvgIcon>
                  <Typography fontWeight={500} fontSize={20}>
                    Instant
                  </Typography>
                </Stack>
                <Typography fontSize={14}>
                  Instantly start the campaign instantly for selected audience.
                </Typography>
              </Stack>

              <Stack
                onClick={() => setType("scheduled")}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    type === "scheduled" ? "1px solid #48F" : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                }}
              >
                <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                  <SvgIcon>
                    <IC_SCHEDULE />
                  </SvgIcon>

                  <Typography fontWeight={500} fontSize={20}>
                    Scheduled
                  </Typography>
                </Stack>
                <Typography fontSize={14}>
                  Automatically start on a specified time.
                </Typography>
              </Stack>
              {type === "scheduled" && (
                <Stack justifyContent={"center"}>
                  <DateTimeField
                    variant="filled"
                    disablePast
                    value={schedule}
                    onChange={(e) => setSchedule(e)}
                    label="Schedule Email"
                    sx={{
                      opacity: 1,
                      backdropFilter: "none",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "8px",
                      },
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack spacing={"4px"}>
            <Typography>
              Target Audience <Chip label={audienceSize} />
            </Typography>
            <Stack direction={"row"} spacing={"8px"}>
              <Stack
                onClick={() => setData({ ...data, audience: "everyone" })}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    data.audience === "everyone"
                      ? "1px solid #48F"
                      : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Everyone
                </Typography>
                <Typography fontSize={14}>
                  All the users on the platform.
                </Typography>
              </Stack>
              <Stack
                onClick={() => setData({ ...data, audience: "not-boarded" })}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    data.audience === "not-boarded"
                      ? "1px solid #48F"
                      : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Not Boarded
                </Typography>
                <Typography fontSize={14}>
                  Users who are not boarded yet.
                </Typography>
              </Stack>
              <Stack
                onClick={() => setData({ ...data, audience: "active" })}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    data.audience === "active"
                      ? "1px solid #48F"
                      : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Active Users
                </Typography>
                <Typography fontSize={14}>
                  Users who have at least 1 active subscriber.
                </Typography>
              </Stack>
              <Stack
                onClick={() => setData({ ...data, audience: "no-customers" })}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    data.audience === "no-customers"
                      ? "1px solid #48F"
                      : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Non-Beneficial
                </Typography>
                <Typography fontSize={14}>
                  Active users with no subscribers.
                </Typography>
              </Stack>
              <Stack
                onClick={() => setData({ ...data, audience: "inactive" })}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    data.audience === "inactive"
                      ? "1px solid #48F"
                      : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Inactive
                </Typography>
                <Typography fontSize={14}>
                  Users who have not used the dashboard in 14 days.
                </Typography>
              </Stack>
              <Stack
                onClick={() => setData({ ...data, audience: "custom" })}
                flex={1}
                sx={{
                  cursor: "pointer",
                  border:
                    data.audience === "custom"
                      ? "1px solid #48F"
                      : "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
              >
                <Typography fontWeight={500} fontSize={20}>
                  Custom
                </Typography>
                <Typography fontSize={14}>Search and select users.</Typography>
              </Stack>
            </Stack>
          </Stack>
          {/* For custom audience, we render users input. */}
          {data.audience === "custom" && (
            <Autocomplete
              options={users}
              freeSolo
              multiple
              onChange={(ev, values) => {
                setSelectedUsers(values as any);
              }}
              value={selectedUsers}
              fullWidth
              ListboxProps={{
                sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
              }}
              sx={{
                borderRadius: "50px",
              }}
              size="small"
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  {...(params as any)}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    hiddenLabel: true,
                    sx: { borderRadius: "8px" },
                  }}
                  hiddenLabel
                  helperText={"Select and add users to the audience. "}
                  placeholder={"Search for a user..."}
                  onChange={(t) => setUserSelectionText(t.currentTarget.value)}
                  value={userSelectionText}
                />
              )}
              renderOption={(props, option, state, ownerState) => (
                <MenuItem {...props}>
                  <Stack
                    direction={"row"}
                    sx={{ px: "24px", py: "8px" }}
                    spacing={"8px"}
                    alignItems={"center"}
                  >
                    <Typography fontSize={12} sx={{ opacity: 0.6 }}>
                      {option.data.name}
                    </Typography>
                    <Typography>{option.label}</Typography>
                  </Stack>
                </MenuItem>
              )}
            />
          )}
          {/* Render Templates  */}
          <Stack spacing={"8px"}>
            <Typography>Select a template or write from scratch</Typography>
            <Stack
              direction={"row"}
              rowGap={"8px"}
              columnGap={"8px"}
              flexWrap={"wrap"}
            >
              {templates.map((t) => RenderTemplate(t))}
              <Stack
                onClick={createTemplate}
                sx={{
                  width: "250px",
                  height: "300px",
                  cursor: "pointer",
                  border: "1px solid #FFF3",
                  borderRadius: "12px",
                  px: "14px",
                  py: "8px",
                  position: "relative",
                }}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={"8px"}
              >
                <SvgIcon>
                  <Add />
                </SvgIcon>
                <Typography fontSize={18} fontWeight={"600"}>
                  Create a Template
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={"32px"}>
            <Typography>Preview</Typography>
            <RenderSelectedTemplate />
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          size="large"
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={busy}
          onClick={save}
          size="large"
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
