import React from "react";
import { CssBaseline, Stack } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#4488FF",
      "600": "#4488FF",
    },
    info: {
      main: "#7A5AF8",
      "600": "#7A5AF8",
    },
    secondary: {
      main: "#7A5AF8",
    },
    background: {
      default: "#000",
    },
  },
  typography: {
    fontFamily: "Red Hat Display",
    caption: {
      color: "#858585",
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          ":before": {
            backgroundColor: "#0000",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expanded: {
          background: " linear-gradient(90deg, #764CBA 0%, #654EC5 100%)",
        },
        root: {},
      },
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      desktop: 1024,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function LandingThemeManager(props: any) {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
