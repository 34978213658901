import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import STRIPEIMGPNG from "../../assets/ui/StripeImg.png";
import { useNavigate } from "react-router";
import CryptoCard from "../../assets/ui/CryptoImage.png";

import ACCESSCODEIMAGE from "../../assets/ui/AccessCode.png";
import DASHBOARD from "../../assets/ui/DashboardImage.png";
import TOOLSIMAGE from "../../assets/ui/Tools.png";
import ASSETSIMAGE from "../../assets/ui/digitaAssets.png";
import BOARDCASTIMAGE from "../../assets/ui/TelegramBroadcast.png";
import CREATEPLANIMAGE from "../../assets/ui/createplan.png";
import FeaturesCard from "./components/FeaturesCard";

export default function Features() {
  const theme = useTheme();
  return (
    <Stack
      id="features"
      sx={{
        pt: "140px",
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack
          sx={{
            gap: "24px",
            [theme.breakpoints.down("desktop")]: {
              gap: "20px",
            },
            [theme.breakpoints.down("tablet")]: {
              gap: "12px",
              maxWidth: "80%",
            },
          }}
        >
          <Typography
            sx={{
              padding: "8px 16px",
              background: "rgba(255, 255, 255, 0.10)",
              borderRadius: "5px",
              alignSelf: "center",
              fontSize: "20px",
              fontWeight: "700",
              [theme.breakpoints.down("desktop")]: {
                fontSize: "10px",
              },
              [theme.breakpoints.down("tablet")]: {
                fontSize: "10px",
              },
            }}
          >
            FEATURES
          </Typography>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              width: "100%",
              borderRadius: "20px 20px ",
              paddingTop: "32px",
              [theme.breakpoints.down("tablet")]: {
                gap: "8px",
              },
            }}
          >
            <FeaturesCard
              sx={{
                background: "#5F32A2",
                alignItems: "center",
                borderRadius: "20px",
                justifyContent: "start",
                flexDirection: "row",
                gap: "32px",
                [theme.breakpoints.down("desktop")]: {
                  flexDirection: "row",
                  gap: "20px",
                },

                [theme.breakpoints.down("tablet")]: {
                  flexDirection: "column",
                  gap: "8px",
                  textAlign: "center",
                  alignItems: "center",
                },
              }}
              title="Blockchain Integration"
              description="With our Smart Contracts integration on popular networks, Cashifybot enables you to effortlessly accept payments globally in multiple cryptocurrencies with instant payouts to your preferred wallet."
              imgSrc={CryptoCard}
            />
          </Stack>
          <FeaturesCard
            sx={{
              background: "#5C68B4",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "start",
              flexDirection: "row-reverse",
              gap: "32px",
              [theme.breakpoints.down("desktop")]: {
                flexDirection: "row",
                gap: "20px",
              },

              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
                gap: "8px",
                textAlign: "center",
                alignItems: "center",
              },
            }}
            title="Stripe Connect™️"
            description="With Stripe Connect integration, you can seamlessly accept payments through credit/debit card, stripe, PayPal, and 30+ different payment methods directly to your bank account, all automated for your convenience."
            imgSrc={STRIPEIMGPNG}
          />
          <FeaturesCard
            sx={{
              background: "#3A73A1",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "start",
              flexDirection: "row",
              gap: "32px",
              [theme.breakpoints.down("desktop")]: {
                flexDirection: "row",
                gap: "20px",
              },

              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
                gap: "8px",
                textAlign: "center",
                alignItems: "center",
              },
            }}
            title="All your transactions in one place"
            description="Centralize all your transactions and data in one streamlined dashboard. Access your payment data exactly how you prefer, whenever it suits you. Dive deep into analytics and make informed decisions with confidence."
            imgSrc={DASHBOARD}
          />
          <FeaturesCard
            sx={{
              background: "#6563B0",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "start",
              flexDirection: "row-reverse",
              gap: "32px",
              [theme.breakpoints.down("desktop")]: {
                flexDirection: "row",
                gap: "20px",
              },

              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
                gap: "8px",
                textAlign: "center",
                alignItems: "center",
              },
            }}
            title="Create Subscription Plans"
            description="Design and customize subscription models to fit your business needs. Cashifybot simplifies subscription management by automatically generating dynamic Stripe subscriptions, helping you maintain an uncluttered Stripe dashboard. You can also create one-time payment subscriptions effortlessly."
            imgSrc={CREATEPLANIMAGE}
          />
          <FeaturesCard
            sx={{
              background: "#4E659C",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "start",
              flexDirection: "row",
              gap: "32px",
              [theme.breakpoints.down("desktop")]: {
                flexDirection: "row",
                gap: "20px",
              },

              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
                gap: "8px",
                textAlign: "center",
                alignItems: "center",
              },
            }}
            title="Sell Digital Assets Easily"
            description="Easily sell your digital products, such as files, templates, or license keys, directly through Telegram using Cashifybot’s built-in store integration. Developers can also tap into Cashifybot's public API to integrate licensing features with their apps or bots."
            imgSrc={ASSETSIMAGE}
          />
          <FeaturesCard
            sx={{
              background: "#6E5FA9",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "start",
              flexDirection: "row-reverse",
              gap: "32px",
              [theme.breakpoints.down("desktop")]: {
                flexDirection: "row",
                gap: "20px",
              },

              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
                gap: "8px",
                textAlign: "center",
                alignItems: "center",
              },
            }}
            title="Create Access Codes"
            description="Generate access codes to provide free, special, or trial access to specific users for designated channels or groups. Cashifybot allows you to set limits, create expiration dates, and even remove users after a set period—perfect for offering controlled trial access without payments."
            imgSrc={ACCESSCODEIMAGE}
          />
          <FeaturesCard
            sx={{
              background: "#296888",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "start",
              flexDirection: "row",
              gap: "32px",
              [theme.breakpoints.down("desktop")]: {
                flexDirection: "row",
                gap: "20px",
              },

              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
                gap: "8px",
                textAlign: "center",
                alignItems: "center",
              },
            }}
            title="Expand Your Reach on Telegram with  Broadcasts"
            description="Engage your audience by sending targeted broadcasts to users who have interacted with your bot. Whether they're subscribed, using access codes, or simply engaged with your bot, Cashifybot helps you turn these interactions into conversions, amplifying your reach on Telegram."
            imgSrc={BOARDCASTIMAGE}
          />
          <FeaturesCard
            sx={{
              background: "#426899",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "start",
              flexDirection: "row-reverse",
              gap: "32px",
              [theme.breakpoints.down("desktop")]: {
                flexDirection: "row",
                gap: "20px",
              },

              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
                gap: "8px",
                textAlign: "center",
                alignItems: "center",
              },
            }}
            title="Grow Your Community Using Our Affiliate Tools"
            description="Boost your channel’s growth by utilizing Cashifybot’s affiliate tools. Encourage your subscribers to refer new members and reward them for their efforts. With these powerful tools, you can expand your reach and increase engagement efficiently."
            imgSrc={TOOLSIMAGE}
          />{" "}
        </Stack>
      </Stack>
    </Stack>
  );
}
