import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import { motion } from "framer-motion";
import {
  aCurrentSection,
  aCurrentSectionDescription,
} from "../../../core/states";
import PFMTable from "../../../components/PFMTable";
import { IC_EDIT, IC_EDIT_EMAIL, IC_REFRESH } from "../../../assets/ui";
import { Email } from "../../../types/Email";
import { useModal } from "mui-modal-provider";
import EditCampaignDialog from "../../../dialogs/EditCampaign";
import {
  rtmDeleteCampaign,
  rtmGetEmailCampaigns,
} from "../../../core/api/admin";
import moment from "moment";
import { Delete } from "@mui/icons-material";

export default function SectionAdminEmails() {
  const { showModal } = useModal();
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<Email[]>([]);

  async function load() {
    try {
      setBusy(true);
      const camps = await rtmGetEmailCampaigns();
      setData(camps);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  async function deleteCampaign(e: Email) {
    try {
      setBusy(true);
      await rtmDeleteCampaign(e.id);
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  function showEditDialog(email?: Email) {
    const modal = showModal(EditCampaignDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: email,
    });
  }

  useEffect(() => {
    setSection(`Emails`);
    setSectionDescription(`Send promotional emails to selected users.`);
    load();
  }, []);

  function RenderData(e: Email) {
    return (
      <TableRow>
        <TableCell>
          <Typography>{e.subject}</Typography>
        </TableCell>

        <TableCell>
          <Typography>{e.template}</Typography>
        </TableCell>

        <TableCell>
          <Typography>
            {e.audience} ({e.audienceCount})
          </Typography>
        </TableCell>

        <TableCell>
          <Typography>
            {moment.unix(e.created).format("MMM DD, YYYY hh:mm A")}
          </Typography>
        </TableCell>
        <TableCell>
          {e.completed && (
            <Typography>
              {moment.unix(e.completed).format("MMM DD, YYYY hh:mm A")}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Chip label={e.status} sx={{ textTransform: "capitalize" }} />
        </TableCell>
        <TableCell>
          {e.status === "scheduled" && (
            <Stack direction={"row"} spacing={"8px"}>
              <IconButton onClick={() => deleteCampaign(e)}>
                <SvgIcon>
                  <Delete />
                </SvgIcon>
              </IconButton>
              <IconButton onClick={() => showEditDialog(e)}>
                <SvgIcon>
                  <IC_EDIT />
                </SvgIcon>
              </IconButton>
            </Stack>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      style={{ overflow: "hidden", flex: 1, display: "flex", padding: "12px" }}
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
    >
      <PFMTable
        sx={{ flex: 1 }}
        title="History"
        description="List of all the campaigns."
        titleBadge={data.length + " Campaigns"}
        actionButtons={
          <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
            <Button
              size="large"
              variant="contained"
              disabled={busy}
              onClick={() => load()}
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
            <Button
              size="large"
              variant="contained"
              disabled={busy}
              onClick={() => showEditDialog()}
              color="primary"
              startIcon={
                <SvgIcon>
                  <IC_EDIT_EMAIL />
                </SvgIcon>
              }
            >
              New Campaign
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            <TableCell>Subject</TableCell>
            <TableCell>Template</TableCell>
            <TableCell>Audience</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Completed</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderData(d))}</>}
      />
    </motion.div>
  );
}
