import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import React from "react";

export default function ColorHeading(props: {
  title?: string;
  description?: string;
  title2?: string;
  mainSx?: SxProps;
  sx?: SxProps;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        my: "80px",
        background:
          "conic-gradient(from -90deg at 50% 0%, #1B57F0 0deg, #543488 360deg);",
        alignItems: "center",
        justifyContent: "center",
        height: "296px",
        width: "100%",
        [theme.breakpoints.down("tablet")]: {
          height: "148px",
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: "1280px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: "300",
            [theme.breakpoints.down("tablet")]: { fontSize: "20px" },
          }}
        >
          {props.title}
          <span style={{ color: "#FFF", fontWeight: "700" }}>
            {props.title2}
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "300",
            textAlign: "center",
            [theme.breakpoints.down("tablet")]: { fontSize: "10px" },
          }}
        >
          {props.description}
        </Typography>
      </Stack>
    </Stack>
  );
}
