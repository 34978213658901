import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_DELETE_CREDIT_CARD } from "../assets/ui";
import IconContainer from "../components/IconContainer";

export default function BalanceErrorDialog(props: DialogProps<any>) {
  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <IconContainer color="error">
            <IC_DELETE_CREDIT_CARD />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Request a Payout
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Request a withdrawal of your available funds to your crypto
              wallet.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack sx={{ py: "18px", px: "24px" }}>
          <Typography>
            You do not have the minimum balance required to create a payout
            request. Please make sure your account has enough balance before
            requesting a withdraw.
          </Typography>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          size="large"
          fullWidth
          variant="contained"
          color="secondary"
        >
          OKAY
        </Button>
      </DialogActions>
    </Dialog>
  );
}
