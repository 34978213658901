import { Radio, Stack, Typography, TypographyProps } from "@mui/material";
import React from "react";
export default function PFMRadio(props: {
  label?: string;
  checked?: boolean;
  onUpdate?: (checked: boolean) => void;
  labelProps?: TypographyProps;
  group?: string;
}) {
  return (
    <Stack direction={"row"} alignItems={"center"} spacing="4px">
      <Radio
        onChange={(c) => props.onUpdate?.(c.target.checked)}
        radioGroup={props.group}
        checked={props.checked}
      />
      <Typography {...props.labelProps}>{props.label}</Typography>
    </Stack>
  );
}
