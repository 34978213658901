import { Stack, Typography, useTheme } from "@mui/material";
import { useScroll, useTransform, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

export default function AnimatedTextLine(props: {
  text: string;
  from: number;
  to: number;
}) {
  const lineRef = useRef<any>(null);
  const theme = useTheme();
  const scroll = useScroll({});
  const [refresh, setRefresh] = useState(0);

  const lineWidth = useTransform(
    scroll.scrollY,
    [
      lineRef.current?.offsetTop - window.screen.availHeight + props.from,
      lineRef.current?.offsetTop - window.screen.availHeight + props.to,
    ],
    ["0%", "100%"]
  );

  useEffect(() => {
    setRefresh(new Date().getTime());
  });

  return (
    <Stack sx={{ position: "relative" }} ref={lineRef} key={refresh}>
      <Typography
        sx={{
          fontSize: "40px",
          fontWeight: "700",
          textAlign: "center",
          letterSpacing: "-0.4px",
          color: "#fff4",
          width: "100%",
          position: "relative",
          [theme.breakpoints.down("desktop")]: {
            fontSize: "30px",
            width: "583px",
          },
          [theme.breakpoints.down("tablet")]: {
            fontSize: "20px",
            textAlign: "center",
            width: "100%",
          },
        }}
      >
        <motion.span
          style={{
            color: "#FFF",
            display: "inline-block",
            width: lineWidth,
            textWrap: "nowrap",
            position: "absolute",
            overflow: "hidden",
          }}
        >
          {props.text}
        </motion.span>
        <span style={{ width: "100%", textWrap: "nowrap" }}>{props.text}</span>
      </Typography>
    </Stack>
  );
}
