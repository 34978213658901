import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { FbAuth } from "../authentication/firebase";
import LoadingView from "./LoadingView";

export default function PageSplashScreen({ children }: any) {
  const [initialized, setInitialized] = useState(false);

  // async function loadProfile() {
  //   try {
  //     const prof = await rtmGetProfile();
  //     setProfile(prof);
  //   } catch (err: any) {
  //     console.log(err);
  //   }
  // }

  useEffect(() => {
    FbAuth.authStateReady().then(async () => {
      // if (FbAuth.currentUser) {
      //   await ensureAuthentication();
      //   await loadProfile();
      // }
      setInitialized(true);
    });
  }, []);

  if (initialized) {
    return <>{children}</>;
  }

  return (
    <Stack sx={{ height: "100vh", width: "100%", alignItems: "center" }}>
      <LoadingView />
    </Stack>
  );
}
