import React, { useState } from "react";
import {
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IC_PRICE, IC_TICK } from "../../assets/ui";
import { FbAuth } from "../../authentication/firebase";
import { useNavigate } from "react-router";
export default function Pricing() {
  const desktop = useMediaQuery("(min-width: 700px)");

  const nav = useNavigate();
  const theme = useTheme();
  function loginOrGoToBilling() {
    const user = FbAuth.currentUser;
    if (user && user.emailVerified) {
      nav("/dashboard/billing");
    } else {
      nav("/signup");
    }
  }

  return (
    <Stack
      id="pricing"
      alignItems={"center"}
      sx={{
        padding: "48px",
        gap: "48px",
        [theme.breakpoints.down("desktop")]: {},

        [theme.breakpoints.down("tablet")]: {
          spacing: "32px",
          padding: "30px",
          gap: "0",
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            gap: "20px",
          },
        }}
      >
        <Stack
          sx={{
            minWidth: "550px",
            alignItems: "center",
            justifyContent: "center",
            padding: "130px 48px 0 48px",
            [theme.breakpoints.down("desktop")]: {
              height: "350px",
              minWidth: "350px",
            },
            [theme.breakpoints.down("tablet")]: {
              minWidth: "361px",
              width: "361px",
              height: "316px",
              padding: "0",
              // padding: "44px 44px 0 44px",
            },
          }}
        >
          {" "}
          <img src={IC_PRICE} alt="" style={{ width: "100%" }} />
          {/* <IC_DASHBOARDIMG width="100%" /> */}
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            gap: "24px",

            [theme.breakpoints.down("tablet")]: {
              alignItems: "start",
              justifyContent: "start",
              flexDirection: "column",
              padding: " 48px 16px",
              gap: "0",
            },
          }}
        >
          <Stack
            sx={{
              borderRadius: "4px",
              p: "32px",
              [theme.breakpoints.down("tablet")]: {
                p: "16px",
              },
            }}
          >
            <Stack
              sx={{
                height: "100%",
                px: "28px",
                gap: "18px",
                alignItems: "start",
                [theme.breakpoints.down("tablet")]: {
                  alignItems: "start",
                  justifyContent: "start",
                  p: "0",
                  gap: "18px",
                },
              }}
            >
              <Typography
                sx={{
                  padding: "8px 16px",
                  background: "rgba(255, 255, 255, 0.10)",
                  borderRadius: "5px",
                }}
              >
                PRICING
              </Typography>
              <Typography
                sx={{
                  fontSize: "40px",
                  fontWeight: "700",
                  textAlign: "start",
                  lineHeight: "100%",

                  [theme.breakpoints.down("desktop")]: {
                    fontSize: "30px",
                    fontWeight: "700",
                  },

                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "25px",
                    fontWeight: "700",
                  },
                }}
              >
                Cashifybot is completely free to use. We only take 3% off each
                transaction.
              </Typography>

              <Stack spacing="12px">
                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Free to Start
                  </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Dashboard Access
                  </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Sell Digital Assets
                  </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Unlimited Access Codes
                  </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Bot Customization
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Unlimited Subscriptions
                  </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Live Support
                  </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Built-in Affiliate Tools
                  </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                  <SvgIcon color="primary">
                    <IC_TICK />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "300",
                      [theme.breakpoints.down("desktop")]: {
                        fontSize: "20px",
                        fontWeight: "300",
                      },

                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "18px",
                        fontWeight: "300",
                      },
                    }}
                  >
                    Broadcast Messages
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Typography
        fontSize={12}
        sx={{
          textAlign: "center",
          fontSize: "12px",
          [theme.breakpoints.down("desktop")]: {
            textAlign: "center",
            fontSize: "12px",
          },

          [theme.breakpoints.down("tablet")]: {
            textAlign: "start",
            p: "0",
            fontSize: "12px",
            width: "100%",
          },
        }}
      >
        Note: Our transaction fees are calculated after factoring in payment
        processing fees.
      </Typography>
    </Stack>
  );
}
