import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import CancelSubscriptionDialog from "./CancelSubscription";
import { enqueueSnackbar } from "notistack";
import { IC_UESR } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import { rtmGetSubscriberDetails } from "../core/api/user";
import { TelegramUser } from "../types/TelegramUser";
import { ActiveSubscription } from "../types/ActiveSubscription";

export default function SubscriberDetailsDialog(
  props: DialogProps<TelegramUser>
) {
  const [busy, setBusy] = useState(false);

  const [subscriptions, setSubscriptions] = useState<ActiveSubscription[]>([]);

  const { showModal } = useModal();

  async function load() {
    // reload subscriptions of this user.
    try {
      setBusy(true);
      const _data = await rtmGetSubscriberDetails(props.data!.id);
      if (_data) {
        setSubscriptions(_data.subscriptions || []);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading user data. Please try again. ", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_UESR />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Subscriber Details
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              View and manage the subscriber details.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            {/* The user's image or avatar. Shown only if image is present, */}
            {props.data?.image && (
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Image
                </Typography>
                <Avatar src={props.data?.image} />
              </Stack>
            )}

            {/* The user identities  */}
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Identifiers
              </Typography>
              <Stack direction={"row"} spacing={"8px"}>
                <PFMInput
                  text={props.data?.id.toString()}
                  fullWidth
                  disabled={busy}
                  helperText="Telegram User ID"
                />
                {props.data?.customerId && (
                  <PFMInput
                    text={props.data?.customerId}
                    fullWidth
                    disabled={busy}
                    helperText="Stripe Customer ID"
                  />
                )}
              </Stack>
            </Stack>

            {/* Name of the user  */}
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <PFMInput text={props.data?.name} fullWidth disabled={busy} />
            </Stack>

            {/* Telegram username  */}
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Username
              </Typography>
              <PFMInput text={props.data?.username} fullWidth disabled={busy} />
            </Stack>

            {/* Email address from stripe records. Shown only if Stripe Info is vailable  */}
            {props.data?.customerInfo && (
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Email Address
                </Typography>
                <PFMInput
                  disabled
                  text={props.data?.customerInfo?.email || "Not available"}
                  fullWidth
                  helperText={
                    props.data?.customerInfo
                      ? "Extracted from stripe customer records."
                      : ""
                  }
                />
              </Stack>
            )}

            {/* Shows the list of subscriptions with cancel button  */}
            {subscriptions.length > 0 && (
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Divider />
                <Typography
                  fontSize={20}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Subscriptions
                </Typography>
              </Stack>
            )}
            {subscriptions.map((s) => (
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{
                    minWidth: "150px",
                    textDecoration: "underline",
                    ":hover": { color: "#4488FF", cursor: "pointer" },
                  }}
                >
                  {s.subscription.name}
                </Typography>
                {s.created && s.created && (
                  <Typography fontSize={14} color="#94969C">
                    Since{" "}
                    {moment.unix(s.created).format("MMM DD, YYYY HH:mm:ss")}
                  </Typography>
                )}
                <Button
                  disabled={
                    busy || (s.status !== "trialing" && s.status !== "active")
                  }
                  color="error"
                  onClick={() => {
                    const modal = showModal(CancelSubscriptionDialog, {
                      closeHandler(result) {
                        modal.destroy();
                        load();
                      },
                      data: s as any,
                    });
                  }}
                >
                  {(s as any).status === "canceled" ? "Canceled" : "Cancel"}
                </Button>
              </Stack>
            ))}
            <Divider />

            {/* User management (e.g. kick/ban) */}
            {/* <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={20}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Manage
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%", px: "32px" }}
                spacing="12px"
              >
                <Button
                  disabled={busy}
                  size="small"
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Kick
                </Button>
                <Button
                  disabled={busy}
                  size="small"
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Ban
                </Button>
              </Stack>
            </Stack> 
            <Divider />*/}

            {/* Activity (e.g. last seen, subscribed to, etc. ) */}
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={20}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Activity
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Last updated
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(props.data?.lastActivity!)
                  .format("MMM DD, YYYY HH:mm:ss")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={"row"}
          sx={{ width: "100%", px: "32px" }}
          spacing="12px"
        >
          <Button
            disabled={busy}
            onClick={props.closeHandler}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
