import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_PAID } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import {
  rtmGeneratePayoutLists,
  rtmGetPayoutDetails,
  rtmMarkPayoutAsComplete,
} from "../core/api/user";
import LoadingView from "../components/LoadingView";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import PFMTable from "../components/PFMTable";
import { Download } from "@mui/icons-material";
import moment from "moment";
import { TelegramPayout } from "../types/TelegramPayout";
import { User } from "../types/User";

export default function TelegramPayoutDetailsDialog(
  props: DialogProps<TelegramPayout>
) {
  const [profile, setProfile] = useState<User>();
  const [payout, setPayout] = useState<TelegramPayout>(props.data!);
  const [busy, setBusy] = useState(false);
  const [csvs, setCsvs] = useState<{
    csvPaypal?: string;
    csvCrypto?: string;
  }>();

  async function load() {
    try {
      // Load payout details with user infos
      const _info = await rtmGetPayoutDetails(props.data?.id!);
      if (_info) setPayout(_info);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading  data for payout details dialog.", err);
    }
  }

  async function generateCsvs() {
    try {
      setBusy(true);
      // Load payout details with user infos
      const _info = await rtmGeneratePayoutLists(props.data?.id!);
      if (_info) setCsvs(_info);
    } catch (err: any) {
      enqueueSnackbar("Error generating list. Please try again. ", {
        variant: "error",
      });
      console.error("Error generate csv list for payout details dialog.", err);
    }
    setBusy(false);
  }

  async function markComplete() {
    try {
      await rtmMarkPayoutAsComplete(payout.id!);
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating payout. Try again later. ", {
        variant: "error",
      });
      console.error("Error updating payout status to complete. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_PAID />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Payout Details
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              View payout information.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {busy && <LoadingView />}
        {!busy && payout && (
          <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
            {/* The totals */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Payout ID</Typography>
              <Typography>{payout.id}</Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Payout Status</Typography>
              <Chip label={payout.status} size="small" variant="identifier" />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Payout Gateway</Typography>
              <Typography>
                {payout.type === "external"
                  ? "Manual / External"
                  : "Automatic / Invoiced"}
              </Typography>
            </Stack>

            {/* If payout gateway is automatic, we show invoice id  */}
            {payout.invoiceId && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={{ opacity: 0.7 }}>
                  Payout Invoice (click to open)
                </Typography>
                <Chip
                  label={payout.invoiceId}
                  size="small"
                  onClick={() =>
                    window.open(
                      "https://cryptochill.com/invoice/" + payout.invoiceId,
                      "_blank"
                    )
                  }
                />
              </Stack>
            )}

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Created</Typography>
              <Typography>
                {moment
                  .unix(payout.created)
                  .format("MMM DD, YYYY hh:mm:ss A Z")}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Updated</Typography>
              <Typography>{moment.unix(payout.updated).fromNow()}</Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography sx={{ opacity: 0.7 }}>Total Amount</Typography>
              <Typography>
                {getCurrencySymbol(payout.currency)}
                {parseStripeAmount(payout.amount).dollars}.
                {parseStripeAmount(payout.amount).cents}
              </Typography>
            </Stack>
            <Divider />
            {/* Download Links to CSV files  */}
            {csvs?.csvPaypal && (
              <PFMInput
                label="PayPal CSV File"
                helperText="Use this file in PayPal mass payouts. Link is valid for 1 hour only."
                text={csvs.csvPaypal}
                endAdornment={
                  <IconButton
                    onClick={() => window.open(csvs.csvPaypal, "_blank")}
                  >
                    <SvgIcon>
                      <Download />
                    </SvgIcon>
                  </IconButton>
                }
              />
            )}

            {csvs?.csvCrypto && (
              <PFMInput
                label="NOWPayments.io CSV File"
                helperText="Use this file in NOWPayments.io Mass Payout option. Link is valid for 1 hour only."
                text={csvs.csvCrypto}
                endAdornment={
                  <IconButton
                    onClick={() => window.open(csvs.csvCrypto, "_blank")}
                  >
                    <SvgIcon>
                      <Download />
                    </SvgIcon>
                  </IconButton>
                }
              />
            )}
            {payout.users.length > 0 && (
              <Stack spacing={"4px"}>
                <PFMTable
                  key={payout as any}
                  title="Users"
                  actionButtons={
                    payout.type === "crypto" ? undefined : (
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={busy}
                        onClick={generateCsvs}
                      >
                        Generate CSV
                      </Button>
                    )
                  }
                  titleBadge={payout.users.length + " users"}
                  sx={{ minHeight: "200px" }}
                  tableHead={
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Method</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  }
                  tableBody={payout.users.map((cu) => (
                    <TableRow>
                      <TableCell>
                        <Stack
                          direction={"row"}
                          spacing="12px"
                          alignItems={"center"}
                        >
                          <Avatar
                            src={cu.info?.image || ""}
                            sx={{ color: "white" }}
                          />
                          <Stack>
                            <Typography fontSize={14} fontWeight={500}>
                              {cu.info?.name}
                            </Typography>
                            <Typography
                              fontSize={14}
                              fontWeight={400}
                              color="#94969C"
                            >
                              @{cu.info?.username || cu.id}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {cu.payout_email
                            ? "PayPal"
                            : cu.payout_currency?.toUpperCase()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          onClick={() => {
                            if (cu.payout_email) {
                              navigator.clipboard.writeText(cu.payout_email);
                            } else {
                              navigator.clipboard.writeText(cu.payout_address!);
                            }
                            enqueueSnackbar("Address copied to clipboard!");
                          }}
                          label={
                            cu.payout_email ||
                            cu.payout_address?.substring(0, 4) +
                              "..." +
                              cu.payout_address?.substring(
                                cu.payout_address.length - 4
                              )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {getCurrencySymbol(payout.currency)}
                          {parseStripeAmount(cu.amount).dollars}.
                          {parseStripeAmount(cu.amount).cents}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                />
              </Stack>
            )}
          </Stack>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          size="large"
          fullWidth
          variant="contained"
          color="secondary"
        >
          Close
        </Button>
        {payout.invoiceId && (
          <Button
            disabled={busy}
            onClick={() =>
              window.open(
                "https://cryptochill.com/invoice/" + payout.invoiceId,
                "_blank"
              )
            }
            size="large"
            fullWidth
            variant="contained"
            color="primary"
          >
            View Invoice
          </Button>
        )}
        {payout.type === "external" && payout.status !== "complete" && (
          <Button
            disabled={busy}
            onClick={markComplete}
            size="large"
            fullWidth
            variant="contained"
            color="primary"
          >
            Mark as Complete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
