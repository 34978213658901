import React, { useState } from "react";
import { useEffect } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../../../authentication/firebase";
import PFMInput from "../../../components/PFMInput";
import { IC_CHECKMARK, IC_EDIT } from "../../../assets/ui";
import { rtmGetProfile } from "../../../core/api";

export default function SectionAdminSettingsProfile() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [editingName, setEditingName] = useState(false);
  const [savingName, setSavingName] = useState(false);
  const [nameSaved, setNameSaved] = useState(false);
  const [editingPassword, setEditingPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [busy, setBusy] = useState(false);

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          // Here we update profile
          await updateProfile(FbAuth.currentUser!, {
            photoURL: url,
          });
          enqueueSnackbar("Profile photo updated successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setName(prof?.name || "");
      setEmail(prof?.email || "");
      setImage(FbAuth.currentUser?.photoURL || "");
      setPassword("***************");
    } catch (err: any) {
      // Error loading profile.
      enqueueSnackbar("Error loading profile. Please try again.", {
        variant: "error",
      });
      console.error("Error loading profile.");
      console.error(err);
    }
  }

  async function saveName() {
    try {
      setBusy(true);
      setSavingName(true);
      // Update profile
      await updateProfile(FbAuth.currentUser!, {
        displayName: name,
      });

      setEditingName(!editingName);
      setNameSaved(true);
    } catch (err: any) {
      enqueueSnackbar("Error saving profile data.", { variant: "error" });
      console.error("Error updating user's profile name.");
      console.error(err);
    }
    setBusy(false);
    setSavingName(false);
  }

  async function changePassword() {
    try {
      setBusy(true);
      const oldCreds = await EmailAuthProvider.credential(email!, password);
      await reauthenticateWithCredential(FbAuth.currentUser!, oldCreds);
      await updatePassword(FbAuth.currentUser!, newPassword);
      setPassword("***************");
      setPasswordChanged(true);
      setEditingPassword(false);
      enqueueSnackbar("Password updated. ", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Error updating password. ", { variant: "error" });
      console.error("Error updating password for a user.");
      console.error(err);
    }
    setBusy(false);
  }

  async function removePhoto() {
    try {
      setBusy(true);
      // Update profile
      await updateProfile(FbAuth.currentUser!, {
        photoURL: "",
      });
      setImage("");
      setUploadInfo("");
      enqueueSnackbar("Profile photo has been removed.", { variant: "info" });
    } catch (err: any) {
      enqueueSnackbar("Error saving profile data.", { variant: "error" });
      console.error("Error removing profile photo.");
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <Stack spacing={"44px"} sx={{ py: "18px" }}>
      {/* Sub Container with 48px spacing.  */}
      <Stack spacing={"40px"}>
        <Stack direction={"row"} spacing={"24px"}>
          <Stack sx={{ position: "relative" }}>
            <Avatar
              sx={{ height: "96px", width: "96px" }}
              src={image || uploadInfo}
            />
            {uploadState === "uploading" && (
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size="96px"
                sx={{ position: "absolute" }}
              />
            )}
          </Stack>
          <Stack
            flex={1}
            sx={{ height: "100%" }}
            justifyContent={"space-around"}
          >
            <Typography fontSize={18} fontWeight={400}>
              Profile Photo
            </Typography>
            <Stack direction={"row"} spacing={"8px"}>
              <input
                id="file-input"
                style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
                type="file"
                onChange={(f) => {
                  if (f.target.files && f.target.files?.length > 0) {
                    uploadAttachment(f.target.files[0]);
                  }
                }}
              />
              <Button
                onClick={() => document.getElementById("file-input")?.click()}
                disabled={busy}
                color="secondary"
                variant="contained"
              >
                {image || uploadInfo ? "Change Photo" : "Upload Photo"}
              </Button>
              {(image || uploadInfo) && (
                <Button disabled={busy} onClick={removePhoto} color="error">
                  Remove Photo
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing="8px">
          <PFMInput
            disabled={busy}
            label="Full Name"
            text={name}
            onUpdate={(t) => {
              if (editingName) setName(t);
            }}
            sx={{ width: "380px" }}
            endAdornment={
              <>
                <Stack
                  direction={"row"}
                  sx={{ height: "100%", width: "48px" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Divider orientation="vertical" />
                  {savingName && (
                    <CircularProgress
                      color="primary"
                      sx={{ height: "100%", width: "100%", p: "10px" }}
                    />
                  )}
                  {!savingName && (
                    <IconButton
                      color="input"
                      onClick={() => {
                        if (editingName) {
                          // save
                          saveName();
                        } else {
                          setEditingName(!editingName);
                        }
                      }}
                    >
                      {editingName === true && <IC_CHECKMARK />}
                      {editingName === false && <IC_EDIT />}
                    </IconButton>
                  )}
                </Stack>
              </>
            }
          />
          {nameSaved && (
            <Typography color="#47CD89">Name Changed Successfully!</Typography>
          )}
        </Stack>
        <PFMInput label="Email Address" text={email} sx={{ width: "380px" }} />
        <Stack spacing="8px">
          <PFMInput
            disabled={busy}
            label={editingPassword ? "Old Password" : "Password"}
            text={password}
            password
            onUpdate={(t) => {
              if (editingPassword) setPassword(t);
            }}
            placeholder="Enter old password..."
            sx={{ width: "380px" }}
            endAdornment={
              !editingPassword ? (
                <>
                  <Stack
                    direction={"row"}
                    sx={{ height: "100%", width: "250px" }}
                    alignItems={"center"}
                  >
                    <Divider orientation="vertical" />
                    <Button
                      disabled={busy}
                      sx={{
                        color: "#94969C",
                        height: "100%",
                      }}
                      onClick={() => {
                        setPassword("");
                        setPasswordChanged(false);
                        setEditingPassword(true);
                      }}
                    >
                      Change Password
                    </Button>
                  </Stack>
                </>
              ) : undefined
            }
          />
        </Stack>
        {editingPassword && (
          <Stack spacing="8px">
            <PFMInput
              disabled={busy}
              label={"New Password"}
              text={newPassword}
              password
              onUpdate={(t) => {
                setNewPassword(t);
              }}
              placeholder="Enter your new password..."
              sx={{ width: "380px" }}
              endAdornment={
                <>
                  <Stack
                    direction={"row"}
                    sx={{ height: "100%", width: "250px" }}
                    alignItems={"center"}
                  >
                    <Divider orientation="vertical" />
                    <Button
                      disabled={busy}
                      sx={{
                        color: "#94969C",
                        height: "100%",
                      }}
                      onClick={changePassword}
                    >
                      Update Password
                    </Button>
                  </Stack>
                </>
              }
            />

            {passwordChanged && (
              <Typography color="#47CD89">
                Password Changed Successfully!
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
