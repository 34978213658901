import React from "react";
import { Stack } from "@mui/material";
import LoadingView from "./LoadingView";

export default function LoadingOverlay(props: {
  busy: boolean;
  loadingText?: string;
}) {
  return (
    <Stack
      sx={{
        display: props.busy ? "flex" : "none",
        height: "100vh",
        width: "100vw",
        position: "absolute",
        zIndex: 100,
      }}
    >
      <LoadingView />
    </Stack>
  );
}
