import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { aCurrentSection, aCurrentSectionDescription } from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import {
  IC_FILTER,
  IC_FILTER_REMOVE,
  IC_REFRESH,
  IC_SEARCH,
  IC_SORT_A,
  IC_SORT_Z,
} from "../../assets/ui";
import { rtmGetLogs } from "../../core/api/admin";
import PFMInput from "../../components/PFMInput";
import PFMSelect from "../../components/PFMSelect";
import { LogMessage } from "../../types/LogMessage";

export default function SectionAdminLogs() {
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLogs, setTotalUsers] = useState(0);
  const [data, setData] = useState<LogMessage[]>([]);

  const [sort, setSort] = useState<{
    type: "created" | "type" | "source" | "uid";
    value: "asc" | "desc";
  }>();

  const [filter, setFilter] = useState<{
    type: "type" | "source" | "uid";
    value: any;
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  const [filterPopoverAnchor, setfilterPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  async function loadData(page: number) {
    try {
      //Load data
      setBusy(true);
      const _logs = await rtmGetLogs({
        page: page,
        sort: sort,
        filter: filter,
        search: search,
      });
      if (_logs) {
        setTotalPages(_logs.pageCount);
        setPageNumber(_logs.currentPage);
        setTotalUsers(_logs.total);
        setData(_logs.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`System Logs`);
    setSectionDescription(`View log messages created by the platform.`);

    // Load page 0
    loadData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, search]);

  function RenderLog(log: LogMessage) {
    return (
      <TableRow
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
          overflow: "hidden",
          maxWidth: "100%",
        }}
      >
        <TableCell sx={{ minWidth: "220px" }}>
          <Typography color="#94969C">
            {moment.unix(log.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Chip
            label={log.type}
            variant="outlined"
            color={
              log.type === "error"
                ? "error"
                : log.type === "warning"
                ? "warning"
                : "info"
            }
            size="small"
          />
        </TableCell>
        <TableCell sx={{ flex: 1, overflow: "hidden" }}>
          <Typography
            sx={{
              textWrap: "wrap",
              height: "auto",
              maxWidth: "500px",
              maxHeight: "auto",
              textOverflow: "ellipsis",
            }}
          >
            {log.message}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">{log.source}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">{log.accountId || log.uid}</Typography>
        </TableCell>

        <TableCell>
          {(log.error || log.stack) && <Button>Details</Button>}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <PFMTable
        sx={{ flex: 1 }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => loadData(pn)}
        prevPage={(pn) => loadData(pn)}
        titleBadge={totalLogs + " logs"}
        title="Logs"
        description="Platform logs and messages."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <IconButton
              onClick={(e) => {
                if (filter) {
                  setFilter(undefined);
                } else {
                  setfilterPopoverAnchor(e.currentTarget);
                }
              }}
            >
              <SvgIcon>
                {filter && <IC_FILTER_REMOVE />}
                {!filter && <IC_FILTER />}
              </SvgIcon>
            </IconButton>
            <Popover
              open={Boolean(filterPopoverAnchor)}
              anchorEl={filterPopoverAnchor}
              onClose={() => setfilterPopoverAnchor(null)}
            >
              <Stack sx={{ px: "24px", py: "12px" }}>
                <Typography>Filter Users Where</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
                  <PFMSelect
                    value={filter?.type}
                    onChange={(ce) =>
                      setFilter({ type: ce?.id as any, value: filter?.value })
                    }
                    options={[{ label: "Type", id: "type" }]}
                  />
                  <Typography>is</Typography>
                  {/* We show options for plan and role, and input for balance  */}
                  {filter?.type === "type" && (
                    <PFMSelect
                      value={filter?.value}
                      onChange={(ce) =>
                        setFilter({
                          type: filter.type,
                          value: ce?.id,
                        })
                      }
                      options={[
                        { label: "Error", id: "error" },
                        { label: "Warning", id: "warning" },
                        { label: "Info", id: "info" },
                      ]}
                    />
                  )}
                  {filter?.type === "source" && (
                    <PFMSelect
                      value={filter?.value}
                      onChange={(ce) =>
                        setFilter({
                          type: filter.type,
                          value: ce?.id,
                        })
                      }
                      options={[
                        { label: "Admin API", id: "Admin API" },
                        { label: "Stripe API", id: "Stripe API" },
                        { label: "Blockchain API", id: "Blockchain API" },
                      ]}
                    />
                  )}
                </Stack>
              </Stack>
            </Popover>
            <PFMInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={IC_SEARCH}
              placeholder="Search logs..."
            />
            <Button
              disabled={busy}
              sx={{ width: "200px", height: "48px" }}
              onClick={() => loadData(pageNumber)}
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* Created  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "created",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Time</Typography>
                {sort?.type === "created" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Type  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "type",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Type</Typography>
                {sort?.type === "type" && (
                  <SvgIcon>
                    {sort.value === "asc" && <IC_SORT_A />}
                    {sort.value === "desc" && <IC_SORT_Z />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Msg  */}
            <TableCell>
              <Typography>Message</Typography>
            </TableCell>
            <TableCell>
              <Typography>Source</Typography>
            </TableCell>
            <TableCell>
              <Typography>UID/Account Id</Typography>
            </TableCell>
            <TableCell>
              <Typography>Actions</Typography>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderLog(d))}</>}
      />
    </Stack>
  );
}
