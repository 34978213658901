import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import AddUserDialog from "../../dialogs/AddUser";
import EditUserDialog from "../../dialogs/EditUser";
import SuspendedUserDialog from "../../dialogs/SuspendedUserDialog";
import {
  IC_FILTER,
  IC_FILTER_REMOVE,
  IC_REFRESH,
  IC_SEARCH,
  IC_SORT_A,
  IC_SORT_Z,
  IC_USER_ADD,
} from "../../assets/ui";
import {
  rtmDeleteStripeAccount,
  rtmGetStripeAccounts,
  rtmListUsers,
} from "../../core/api/admin";
import PFMInput from "../../components/PFMInput";
import PFMSelect from "../../components/PFMSelect";
import { User } from "../../types/User";
import { Account } from "../../types/Stripe/StripeAccount";
import { parseStripeAmount } from "../../core/helper";
import DeleteStripeAccountDialog from "../../dialogs/DeleteStripeAccount";

export default function SectionAdminStripeAccounts() {
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [totalUsers, setTotalUsers] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState<{ user?: User; account: Account }[]>([]);
  const { showModal } = useModal();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  async function loadNext() {
    try {
      //Load data
      setBusy(true);
      const _accs = await rtmGetStripeAccounts({
        starting_after: data[data.length - 1].account.id,
      });
      setHasMore(_accs.hasMore);
      setData(_accs.data);
      if (_accs.hasMore) {
        setTotalPages(totalPages + 1);
        setPageNumber(pageNumber + 1);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  async function loadPrev() {
    try {
      //Load data
      setBusy(true);
      const _accs = await rtmGetStripeAccounts({
        ending_before: data[0].account.id,
      });
      setHasMore(_accs.hasMore);
      setData(_accs.data);
      setPageNumber(pageNumber - 1);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _accs = await rtmGetStripeAccounts({});
      setHasMore(_accs.hasMore);
      setData(_accs.data);
      if (_accs.hasMore) {
        setTotalPages(2);
        setPageNumber(1);
      } else {
        setTotalPages(1);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showDeleteDialog(info: { account: Account; user?: User }) {
    const modal = showModal(DeleteStripeAccountDialog, {
      async closeHandler(result) {
        modal.destroy();
        if (result) {
          setBusy(true);
          try {
            await rtmDeleteStripeAccount(info.account.id);
            enqueueSnackbar("Account deleted successfully.");
          } catch (err: any) {
            enqueueSnackbar("Error deleting account. ", { variant: "error" });
            console.error(err);
          }
          setBusy(false);
          load();
        }
      },
      data: info,
    });
  }

  useEffect(() => {
    setSection(`Stripe Connect Accounts`);
    setSectionDescription(
      `View and manage Stripe Connect accounts on the platform.`
    );

    load();
  }, []);

  function RenderAccount(info: { account: Account; user?: User }) {
    const user = info.user;
    const acc = info.account;
    return (
      <TableRow
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(info.account.id)}
          >
            {info.account.id}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={user?.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {user?.name || acc.business_profile?.name}{" "}
                {user ? "" : "(deleted)"}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {user?.email || acc.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip label={acc.type} variant="outlined" size="small" />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(acc.created!).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {acc.default_currency?.toUpperCase()}/{acc.country}
          </Typography>
        </TableCell>

        <TableCell>
          <Button color="error" onClick={() => showDeleteDialog(info)}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        busy={busy}
        paginated
        totalPages={totalPages}
        pageNumber={pageNumber}
        nextPage={loadNext}
        prevPage={loadPrev}
        titleBadge={totalUsers + " Accounts"}
        title="Accounts"
        description="List of all the accounts on the platform."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <PFMInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={IC_SEARCH}
              placeholder="Search account..."
            />
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell>Title</TableCell>
            {/* Status  */}
            <TableCell>Type</TableCell>
            {/* Registered  */}
            <TableCell>Created</TableCell>
            <TableCell>Country</TableCell>
            {/* Last Activity  */}
            <TableCell>Actions</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderAccount(d))}</>}
      />
    </Stack>
  );
}
