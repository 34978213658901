import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import PFMInput from "../../components/PFMInput";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { IC_INVOICE, IC_REFRESH, IC_SEARCH } from "../../assets/ui";
import { motion } from "framer-motion";
import { Download } from "@mui/icons-material";
import { rtmGetProfile } from "../../core/api";
import { rtmGetPlatformInvoicesA } from "../../core/api/admin";
import { Invoice } from "../../types/Invoice";

export default function SectionAdminInvoices() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [data, setData] = useState<Invoice[]>([]);

  async function load(page: number) {
    try {
      setBusy(true);
      const _data = await rtmGetPlatformInvoicesA(page, search);
      if (_data) {
        setData(_data.data);
        setPageNumber(_data.currentPage);
        setDataCount(_data.total);
        setPageCount(_data.pageCount);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Platform Invoices`);
    setSectionDescription(
      `View the invoices created for Cashifybot customers.`
    );
    load(0);
  }, []);

  function RenderData(u: Invoice) {
    const amnt = parseStripeAmount(
      u.source === "stripe"
        ? u.data?.amount_paid || u.data?.amount_due
        : u.data.amount.fiat.amount * 100
    );
    const feeAmnt = parseStripeAmount(u.fee);
    const sym = getCurrencySymbol(
      u.source === "stripe" ? u.data?.currency : u.data.amount.fiat.currency
    );
    return (
      <TableRow>
        <TableCell>
          <Chip
            label={u.id}
            variant="identifier"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(u.id);
              enqueueSnackbar("Invoice ID copied to clipboard!");
            }}
          />
        </TableCell>
        <TableCell align="left">
          <Typography color="#94969C">
            {moment.unix(u.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Avatar src={u.ownerInfo?.image} />
            <Stack>
              <Typography>{u.ownerInfo?.name}</Typography>
              {u.customerInfo?.username && (
                <Typography>@{u.ownerInfo?.email}</Typography>
              )}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          {sym}
          {feeAmnt.dollars}.{feeAmnt.cents}
        </TableCell>
        <TableCell>
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
        <TableCell>
          <Chip label={u.status} variant="identifier" size="small" />
        </TableCell>
        <TableCell>
          <IconButton
            title="View Invoice"
            onClick={() => {
              if (u.source === "stripe") {
                window.open(u.data.hosted_invoice_url, "_blank");
              } else {
                window.open(
                  "https://cryptochill.com/invoice/" + u.data.id,
                  "_blank"
                );
              }
            }}
          >
            <SvgIcon>
              <IC_INVOICE />
            </SvgIcon>
          </IconButton>
          {u.source === "stripe" && (
            <IconButton
              title="Download PDF"
              onClick={() => {
                if (u.source === "stripe") {
                  window.open(u.data.invoice_pdf, "_blank");
                }
              }}
            >
              <SvgIcon>
                <Download />
              </SvgIcon>
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{ overflow: "hidden", flex: 1, display: "flex", padding: "12px" }}
    >
      <PFMTable
        sx={{ flex: 1 }}
        title="Platform Invoices"
        paginated
        pageNumber={pageNumber}
        totalPages={pageCount}
        nextPage={(pn) => load(pn)}
        prevPage={(pn) => load(pn)}
        description="List of all the platform invoices."
        titleBadge={data.length + " Invoices"}
        actionButtons={
          <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
            <PFMInput
              placeholder="Search..."
              startIcon={IC_SEARCH}
              text={search}
              onUpdate={(t) => setSearch(t)}
              onReturn={() => {
                load(pageNumber);
              }}
            />
            <Button
              size="large"
              variant="contained"
              disabled={busy}
              onClick={() => load(pageNumber)}
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Fee</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderData(d))}</>}
      />
    </motion.div>
  );
}
