import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import { IC_REFRESH } from "../../assets/ui";
import { rtmGetWallets, rtmWithdrawBalance } from "../../core/api/admin";

export default function SectionAdminWallets() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<
    {
      network: string;
      currency: string;
      fee: number;
      balance: string;
    }[]
  >([]);

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _data: any[] = [];
      const _wallets = await rtmGetWallets();
      for (let w of Object.entries(_wallets)) {
        for (let c of Object.entries(w[1].balances)) {
          _data.push({
            network: w[0],
            currency: c[0],
            balance: c[1],
            fee: w[1].fee,
          });
        }
      }
      setData(_data);
      console.log(_data);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  // async function withdraw(network: string, token?: string) {
  //   try {
  //     setBusy(true);
  //     const _rx = await rtmWithdrawBalance(network, token);
  //     if (_rx) {
  //       window.open(_rx, "_blank");
  //     }
  //   } catch (err: any) {
  //     console.error("Error sending withdraw request. ", err);
  //     enqueueSnackbar("Error processing request. ", { variant: "error" });
  //   }
  //   setBusy(false);
  // }

  useEffect(() => {
    setSection(`Wallets`);
    setSectionDescription(`View balances in the crypto wallet.`);

    load();
  }, []);

  useEffect(() => {
    load();
  }, []);

  function RenderWallet(w: {
    network: string;
    currency: string;
    balance: string;
    fee: number;
  }) {
    return (
      <TableRow
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography color="#94969C">{w.network.toUpperCase()}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="#94969C" fontSize={14} fontWeight={500}>
            {w.currency.toUpperCase()}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C" fontSize={14} fontWeight={500}>
            {w.fee}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontSize={14} fontWeight={500}>
            {w.balance} {w.currency.toUpperCase()}
          </Typography>
        </TableCell>
        <TableCell>
          {/* <Stack direction={"row"}>
            <Button
              disabled={busy}
              onClick={() => {
                withdraw(w.network, w.currency);
              }}
            >
              Withdraw
            </Button>
          </Stack> */}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        busy={busy}
        title="Balanaces"
        description="List of all the balances on different networks"
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <Button
              disabled={busy}
              sx={{ width: "200px", height: "48px" }}
              onClick={() => load()}
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon>
                  <IC_REFRESH />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Network</Typography>
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Currency</Typography>
              </Stack>
            </TableCell>
            {/* Registered  */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Fee %</Typography>
              </Stack>
            </TableCell>
            {/* Last Activity  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Balance</Typography>
              </Stack>
            </TableCell>
            <TableCell sx={{ cursor: "pointer" }}>
              <Typography>Actions</Typography>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderWallet(d))}</>}
      />
    </Stack>
  );
}
