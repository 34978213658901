import React, { useEffect, useState } from "react";
import { DialogProps } from "./props";
import {
  Badge,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  MenuItem,
  RadioGroup,
  Select,
  Slide,
  Stack,
  SvgIcon,
  ToggleButton,
  Typography,
  useTheme,
} from "@mui/material";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import { IC_CANCEL, IC_STORE_ADD } from "../assets/ui";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import {
  rtmCreateProduct,
  rtmCreateSubscription,
  rtmGetSubscriptions,
} from "../core/api/user";
import { Add } from "@mui/icons-material";
import { FbAuth, FbStorage } from "../authentication/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { StyledToggleButtonGroup } from "../components/ToggleButtonGroup";

import ReactPlayer from "react-player";
import { PFMSwitch } from "../components/PFMSwitch";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import PFMRadio from "../components/PFMRadio";

export default function CreateProductDialog(props: DialogProps<any>) {
  const theme = useTheme();
  const [profile] = useRecoilState(aProfile);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [successUrl, setSuccessUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [generateLicenseKey, setGenerateLicenseKey] = useState(false);
  const [limit, setLimit] = useState(0);
  const [files, setFiles] = useState<
    { name: string; size: number; url: string }[]
  >([]);

  const [media, setMedia] = useState<
    { name: string; size: number; url: string; type: string }[]
  >([]);
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [busy, setBusy] = useState(false);

  const [subSection, setSubSection] = useState<
    "basic" | "screenshots" | "deliverables" | "pricing"
  >("basic");

  const [subscriptions, setSubscriptions] = useState<AutocompleteOption[]>([]);
  const [attachedSubscriptions, setAttachedSubscriptions] = useState<
    AutocompleteOption[]
  >([]);
  const [newSubscriptions, setNewSubscriptions] = useState<
    {
      price: number;
      kind: "onetime" | "recurring";
      schedule?: string;
      trial?: number;
      name: string;
      description: string;
    }[]
  >([]);
  // New subscription form
  const [price, setPrice] = useState("");
  const [priceName, setPriceName] = useState("");
  const [priceDescription, setPriceDescription] = useState("");
  const [kind, setKind] = useState<"onetime" | "recurring">("onetime");
  const [schedule, setSchedule] = useState("");
  const [trial, setTrial] = useState(0);

  function addPlan() {
    if (!priceName) {
      enqueueSnackbar("Please enter a name for the price plan. ", {
        variant: "error",
      });
      return;
    }
    if (!priceDescription) {
      enqueueSnackbar("Please enter a short description for the price plan. ", {
        variant: "error",
      });
      return;
    }
    if (!price || parseFloat(price) < 3) {
      enqueueSnackbar("Invalid price. ", { variant: "error" });
      return;
    }
    if (kind !== "onetime") {
      if (!schedule) {
        enqueueSnackbar("Please select a schedule for recurring price plan. ", {
          variant: "error",
        });
        return;
      }
    }
    setNewSubscriptions([
      ...newSubscriptions,
      {
        kind: kind,
        price: Math.floor(parseFloat(price) * 100),
        schedule: schedule,
        trial: trial,
        name: priceName,
        description: priceDescription,
      },
    ]);
    setKind("onetime");
    setPrice("");
    setPriceName("");
    setPriceDescription("");
    setSchedule("");
    setTrial(0);
    enqueueSnackbar("Added new price plan for the product. ", {
      variant: "success",
    });
  }

  async function loadSubscriptions() {
    try {
      //Load data
      const _subs = await rtmGetSubscriptions();
      subscriptions.splice(0);
      for (let _sub of _subs) {
        subscriptions.push({
          label: _sub.name,
          ..._sub,
        });
      }
      setSubscriptions([...subscriptions]);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.error(err);
    }
  }

  async function addProduct() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a product name.", {
          variant: "error",
        });
        return;
      }
      if (!description) {
        enqueueSnackbar("Please enter a description for the product.", {
          variant: "error",
        });
        return;
      }
      if (!successUrl && files.length === 0) {
        enqueueSnackbar("Please add deliverables or a link. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const r = await rtmCreateProduct({
        description: description,
        files: files,
        generateLicenseKeys: generateLicenseKey,
        licenseKeyLimit: limit,
        media: media,
        name: name,
        status: "active",
        successMessage: successMessage,
        successUrl: successUrl,
      });
      // Create subscriptions
      for (let _newSub of newSubscriptions) {
        await rtmCreateSubscription({
          description: _newSub.description,
          name: _newSub.name,
          kind: _newSub.kind,
          price: {
            amount: _newSub.price,
            currency: profile?.currency || "usd",
          },
          schedule: (_newSub.schedule as any) || undefined,
          trial: _newSub.trial,
          products: [{ id: r.id, name: r.name } as any],
        });
      }
      // Update all attached subscriptions, and include this product in them.

      enqueueSnackbar("Product has been added. ", { variant: "success" });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error creating a new product. Error: " + err.message, {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  async function uploadMedia(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/media/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setMedia([
            ...media,
            {
              name: attachment.name,
              size: attachment.size,
              type: attachment.type,
              url: url,
            },
          ]);
          enqueueSnackbar("Product media uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading a file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function uploadDeliverable(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/files/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setFiles([
            ...files,
            { name: attachment.name, size: attachment.size, url: url },
          ]);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  useEffect(() => {
    loadSubscriptions();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullScreen
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_STORE_ADD />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Add Product
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Add a new product to your store.
            </Typography>
          </Stack>
        </Stack>
        {uploadState === "uploading" && (
          <LinearProgress value={uploadProgress} variant="determinate" />
        )}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          spacing="48px"
          alignItems={"center"}
          sx={{ py: "24px", width: "100%", position: "relative" }}
        >
          <Stack sx={{ alignSelf: "center", width: "600px" }}>
            <StyledToggleButtonGroup
              sx={{
                alignSelf: "center",
                [theme.breakpoints.down("md")]: {
                  alignSelf: "flex-start",
                },
              }}
              exclusive
              value={subSection}
              onChange={(c, e) => setSubSection(e ? e : subSection)}
            >
              <ToggleButton value={"basic"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{
                    px: "16px",
                    [theme.breakpoints.down("md")]: {
                      px: "8px",
                    },
                  }}
                >
                  <Badge
                    variant="dot"
                    color={subSection === "basic" ? "success" : undefined}
                  ></Badge>
                  <Typography
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                  >
                    Basic
                  </Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={"screenshots"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{
                    px: "16px",
                    [theme.breakpoints.down("md")]: {
                      px: "8px",
                    },
                  }}
                >
                  <Badge
                    variant="dot"
                    color={subSection === "screenshots" ? "success" : undefined}
                  ></Badge>
                  <Typography
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                  >
                    Media
                  </Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={"deliverables"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{
                    px: "16px",
                    [theme.breakpoints.down("md")]: {
                      px: "8px",
                    },
                  }}
                >
                  <Badge
                    variant="dot"
                    color={
                      subSection === "deliverables" ? "success" : undefined
                    }
                  ></Badge>
                  <Typography
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                  >
                    Deliverables
                  </Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={"pricing"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                  spacing="16px"
                  sx={{
                    px: "16px",
                    [theme.breakpoints.down("md")]: {
                      px: "8px",
                    },
                  }}
                >
                  <Badge
                    variant="dot"
                    color={subSection === "pricing" ? "success" : undefined}
                  ></Badge>
                  <Typography
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        fontSize: 10,
                      },
                    }}
                  >
                    Pricing
                  </Typography>
                </Stack>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>

          {/* Basic  */}
          <Slide direction="down" in={subSection === "basic"}>
            <Stack
              spacing={"32px"}
              sx={{
                px: "24px",
                position: "absolute",
                top: "48px",
                width: "600px",
              }}
            >
              <PFMInput
                label="Product Name"
                placeholder="Name your product..."
                important
                text={name}
                onUpdate={(t) => setName(t)}
                disabled={busy}
              />
              <PFMInput
                label="Short Description"
                placeholder="Write a short description for this product. It will be shown to the users in the product details screen. "
                multiline
                rows={4}
                text={description}
                onUpdate={(t) => setDescription(t)}
                disabled={busy}
              />

              <PFMInput
                label="Thank you Note"
                placeholder="Write a short message that is shown after the purchase."
                multiline
                rows={3}
                text={successMessage}
                onUpdate={(t) => setSuccessMessage(t)}
                disabled={busy}
              />
            </Stack>
          </Slide>

          <Slide direction="down" in={subSection === "screenshots"}>
            <Stack
              spacing={"24px"}
              sx={{
                px: "24px",
                position: "absolute",
                top: "48px",
                width: "600px",
              }}
            >
              <Stack>
                <Typography fontSize={14} fontWeight={500}>
                  Product Media
                </Typography>
                <Typography fontSize={12} fontWeight={300}>
                  Insert screenshots, images, or videos of the product.
                </Typography>

                <input
                  id="file-input-media"
                  style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
                  type="file"
                  accept="image/png,image/jpeg,image/jpg,video/mp4,video/flv"
                  onChange={(f) => {
                    if (f.target.files && f.target.files?.length > 0) {
                      uploadMedia(f.target.files[0]);
                    }
                  }}
                />
                <Button
                  disabled={busy}
                  onClick={() =>
                    document.getElementById("file-input-media")?.click()
                  }
                  startIcon={<Add />}
                  sx={{ alignSelf: "start" }}
                >
                  Add a file
                </Button>

                <Stack
                  direction={"row"}
                  rowGap={"12px"}
                  columnGap={"12px"}
                  flexWrap={"wrap"}
                >
                  {media.map((f) => (
                    <Stack
                      sx={{
                        background: "#333",
                        borderRadius: "12px",
                        position: "relative",
                        pb: "12px",
                      }}
                    >
                      {f.type.startsWith("image/") && (
                        <img
                          alt={f.name}
                          src={f.url}
                          style={{
                            height: "300px",
                            width: "400px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                      {f.type.startsWith("video/") && (
                        <ReactPlayer
                          controls
                          width={"400px"}
                          height={"300px"}
                          url={f.url}
                        />
                      )}
                      <Typography sx={{ pl: "12px" }}>{f.name}</Typography>
                      <Typography sx={{ pl: "12px" }} fontSize={12}>
                        {Math.ceil(f.size / 1024)}KB
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setMedia([...media.filter((fx) => fx.url !== f.url)]);
                        }}
                        sx={{
                          position: "absolute",
                          right: "24px",
                          top: "24px",
                        }}
                      >
                        <SvgIcon>
                          <IC_CANCEL />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Slide>

          <Slide direction="down" in={subSection === "deliverables"}>
            <Stack
              spacing={"24px"}
              sx={{
                px: "24px",
                position: "absolute",
                top: "48px",
                width: "600px",
              }}
            >
              <Stack>
                <Typography>Generate License Keys</Typography>
                <PFMSwitch
                  value={generateLicenseKey}
                  onChange={(e, c) => setGenerateLicenseKey(c)}
                />
              </Stack>
              {generateLicenseKey && (
                <PFMInput
                  label="Limit"
                  placeholder="0"
                  important
                  text={limit.toString() || ""}
                  onUpdate={(t) => setLimit(parseInt(t || "0"))}
                  disabled={busy}
                  helperText="Number of times a license key can be used. 0 for unlimited."
                />
              )}
              <Stack>
                <Typography fontSize={14} fontWeight={500}>
                  Assets
                </Typography>
                <Typography fontSize={12} fontWeight={300}>
                  Users can access these assets and the url with an active
                  subscription of this product.
                </Typography>
                <input
                  id="file-input"
                  style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
                  type="file"
                  onChange={(f) => {
                    if (f.target.files && f.target.files?.length > 0) {
                      uploadDeliverable(f.target.files[0]);
                    }
                  }}
                />
                <Button
                  disabled={busy}
                  onClick={() => document.getElementById("file-input")?.click()}
                  startIcon={<Add />}
                  sx={{ alignSelf: "start" }}
                >
                  Add a file
                </Button>

                {files.map((f) => (
                  <Stack
                    justifyContent={"space-between"}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Typography>
                      {f.name}
                      {" - "}
                      <span>{Math.ceil(f.size / 1024)}KB</span>
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setFiles([...files.filter((fx) => fx.url !== f.url)]);
                      }}
                    >
                      <SvgIcon>
                        <IC_CANCEL />
                      </SvgIcon>
                    </IconButton>
                  </Stack>
                ))}
                <PFMInput
                  placeholder="https://"
                  label="Link"
                  text={successUrl}
                  onUpdate={(t) => setSuccessUrl(t)}
                  disabled={busy}
                  helperText="Users can access this link when they have an active subscription of this product."
                />
              </Stack>
            </Stack>
          </Slide>

          <Slide direction="down" in={subSection === "pricing"}>
            <Stack
              spacing={"32px"}
              sx={{
                px: "24px",
                position: "absolute",
                top: "48px",
                width: "600px",
              }}
            >
              <Stack>
                <Typography fontSize={24}>Price Plans</Typography>
                <Stack
                  direction={"row"}
                  flexWrap={"wrap"}
                  rowGap={"8px"}
                  columnGap={"8px"}
                >
                  {newSubscriptions.length === 0 &&
                    attachedSubscriptions.length === 0 && (
                      <Stack
                        sx={{
                          p: "24px",
                          borderRadius: "12px",
                          background: "#3336",
                          border: "1px solid #333D",
                        }}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                          Please attach an existing subscription or create a new
                          one below.
                        </Typography>
                      </Stack>
                    )}
                  {newSubscriptions.map((ns, index) => (
                    <Stack
                      sx={{
                        p: "24px",
                        borderRadius: "12px",
                        background: "#3336",
                        border: "1px solid #333D",
                        position: "relative",
                      }}
                      alignItems={"start"}
                    >
                      <Typography>
                        {ns.name}{" "}
                        <Chip
                          size="small"
                          label={ns.kind}
                          sx={{
                            textTransform: "capitalize",
                            p: "0px",
                            minWidth: "0px",
                            px: 0,
                          }}
                        />
                      </Typography>
                      <Typography fontSize={12}>{ns.description}</Typography>

                      <Typography fontSize={16} fontWeight={600}>
                        {getCurrencySymbol(profile?.currency!)}{" "}
                        {parseStripeAmount(ns.price).dollars}.
                        {parseStripeAmount(ns.price).cents}
                      </Typography>
                      {ns.schedule && ns.kind === "recurring" && (
                        <Typography fontSize={12}>/{ns.schedule}</Typography>
                      )}
                      <IconButton
                        onClick={() => {
                          newSubscriptions.splice(index, 1);
                          setNewSubscriptions([...newSubscriptions]);
                        }}
                        sx={{
                          top: "0px",
                          right: "0px",
                          position: "absolute",
                        }}
                      >
                        <IC_CANCEL />
                      </IconButton>
                    </Stack>
                  ))}
                  {attachedSubscriptions.map((ns, index) => (
                    <Stack
                      sx={{
                        p: "24px",
                        borderRadius: "12px",
                        background: "#3336",
                        border: "1px solid #333D",
                        position: "relative",
                      }}
                      alignItems={"start"}
                    >
                      <Typography>
                        {ns.name}{" "}
                        <Chip
                          size="small"
                          label={ns.kind}
                          sx={{
                            textTransform: "capitalize",
                            p: "0px",
                            minWidth: "0px",
                            px: 0,
                          }}
                        />
                      </Typography>
                      <Typography fontSize={12}>{ns.description}</Typography>

                      <Typography fontSize={16} fontWeight={600}>
                        {getCurrencySymbol(ns.price.currency)}{" "}
                        {parseStripeAmount(ns.price.amount).dollars}.
                        {parseStripeAmount(ns.price.amount).cents}
                      </Typography>
                      {ns.schedule && ns.kind === "recurring" && (
                        <Typography fontSize={12}>/{ns.schedule}</Typography>
                      )}
                      <IconButton
                        onClick={() => {
                          attachedSubscriptions.splice(index, 1);
                          setAttachedSubscriptions([...attachedSubscriptions]);
                        }}
                        sx={{
                          top: "0px",
                          right: "0px",
                          position: "absolute",
                        }}
                      >
                        <IC_CANCEL />
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack spacing={"8px"}>
                <Typography fontSize={18}>
                  Attach an existing subscription
                </Typography>
                <Typography fontSize={14} variant="caption">
                  You can add this product to any existing subscription models
                  by selecting a subscription below.
                </Typography>
                <PFMAutoComplete
                  multiple
                  key={subscriptions.length}
                  options={subscriptions}
                  placeholder="Select a subscription to attach.."
                  onUpdate={(sel) => setAttachedSubscriptions(sel as any)}
                  value={attachedSubscriptions}
                />
              </Stack>
              <Stack spacing={"24px"}>
                <Stack spacing="8px">
                  <Typography fontSize={24}>
                    Create a subscription plan
                  </Typography>
                  <Typography variant="caption" fontSize={14}>
                    Enter subscription details below if you want to create a new
                    subscription model for this product.
                  </Typography>
                </Stack>
                <Stack spacing={"24px"}>
                  <PFMInput
                    label="Subscription Name"
                    placeholder="Name the price plan..."
                    important
                    text={priceName}
                    onUpdate={(t) => setPriceName(t)}
                    disabled={busy}
                  />
                  <PFMInput
                    label="Description"
                    placeholder="Write a short description for this model. It will be shown to the users in the price plans. "
                    important
                    multiline
                    rows={4}
                    text={priceDescription}
                    onUpdate={(t) => setPriceDescription(t)}
                    disabled={busy}
                  />

                  <PFMInput
                    startAdornment={
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{ pl: "8px", height: "100%" }}
                      >
                        <Typography>
                          {getCurrencySymbol(profile?.currency!)}
                        </Typography>
                      </Stack>
                    }
                    text={price.toString()}
                    onUpdate={(p) => setPrice(p)}
                    label="Subscription Price"
                    placeholder="5.00"
                    errorText={
                      price &&
                      (Number.isNaN(parseFloat(price)) || parseFloat(price) < 5)
                        ? "Invalid price."
                        : undefined
                    }
                    helperText={`Minimum price is ${getCurrencySymbol(
                      profile?.currency!
                    )}5.`}
                    important
                  />
                  <Stack spacing={"18px"}>
                    <Typography fontSize={18} fontWeight={500}>
                      Type of subscription
                    </Typography>
                    <RadioGroup radioGroup="subscriptionKind">
                      <PFMRadio
                        checked={kind === "onetime"}
                        onUpdate={(c) => setKind("onetime")}
                        label="One-time"
                        group="subscriptionKind"
                        labelProps={{ fontSize: 16, fontWeight: 500 }}
                      />
                      <PFMRadio
                        checked={kind === "recurring"}
                        onUpdate={(c) => setKind("recurring")}
                        label="Recurring"
                        group="subscriptionKind"
                        labelProps={{ fontSize: 16, fontWeight: 500 }}
                      />
                    </RadioGroup>

                    {kind === "recurring" && (
                      <PFMAutoComplete
                        options={[
                          { label: "Daily" },
                          { label: "Weekly" },
                          { label: "Monthly" },
                          { label: "Every 3 months" },
                          { label: "Every 6 months" },
                          { label: "Yearly" },
                        ]}
                        value={{ label: schedule }}
                        onUpdate={(v: any) => setSchedule(v.label)}
                        label="Recurring Schedule"
                        placeholder="Select a schedule..."
                        important
                      />
                    )}

                    {/* Add trial option for recurring  */}
                    {kind === "recurring" && (
                      <PFMInput
                        sx={{ pt: "12px" }}
                        label="Trial Period (Stripe Only)"
                        placeholder="7"
                        text={trial.toString()}
                        helperText="Enter the trial period in number of days, or 0 to disable trial."
                        onUpdate={(c) => setTrial(parseInt(c) || 0)}
                        endAdornment={
                          <Select
                            variant="standard"
                            disableUnderline
                            value={"days"}
                            sx={{ background: "transparent" }}
                          >
                            <MenuItem value={"days"}>Days</MenuItem>
                          </Select>
                        }
                      />
                    )}
                  </Stack>
                </Stack>

                <Button
                  onClick={addPlan}
                  variant="contained"
                  sx={{ alignSelf: "flex-start" }}
                >
                  Add Plan
                </Button>
              </Stack>
            </Stack>
          </Slide>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack direction={"row"} spacing={"8px"}>
          <Button
            disabled={busy}
            onClick={props.closeHandler}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={busy}
            onClick={addProduct}
            color="primary"
            variant="contained"
          >
            Create Product
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
