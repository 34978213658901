import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import IconContainer from "../components/IconContainer";
import { IC_ALERT, IC_DELETE_CREDIT_CARD, IC_STORE_REMOVE } from "../assets/ui";

export default function DeleteProductDialog(props: DialogProps<any>) {
  const [busy, setBusy] = useState(false);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_STORE_REMOVE />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Delete Product?
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          <Typography fontSize={14} fontWeight={400} sx={{ px: "24px" }}>
            Do you really want to delete this product? Users will no longer see
            this product in the menu, and you will not be able to make any
            further changes either.
            <br />
            <br />
            This action cannot be undone.
          </Typography>
          <Alert
            sx={{ alignItems: "center" }}
            color="warning"
            icon={
              <SvgIcon>
                <IC_ALERT />
              </SvgIcon>
            }
          >
            <Typography>
              This does not affect existing users with this product.
            </Typography>
          </Alert>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={() => props.closeHandler(false)}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setBusy(true);
                props.closeHandler(true);
              }}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
