import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { Invoice } from "../../types/Invoice";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";
import { RestGetInvoice } from "../../core/rest";
import Header from "../landing/header";
import Footer from "../landing/footer";
import { LOGO_HEADER } from "../../assets/brand";
import moment from "moment";
import { getCurrencySymbol } from "../../core/helper";
import { BlockchainInvoiceStatus } from "../../types/Blockchain/BlockchainInvoiceStatus";
import WhiteInput from "../landing/components/WhiteInput";
import { IC_ALERT, IC_COPY } from "../../assets/ui";
import PageSplashScreen from "../../components/SplashScreen";

export default function InvoicePage() {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [invoice, setInvoice] = useState<Invoice & { source: "crypto" }>();

  async function loadInvoice() {
    try {
      if (!params.id) {
        navigate("/NotFound");
        return;
      }
      const _invoice = await RestGetInvoice(params.id);
      if (!_invoice) {
        navigate("/NotFound");
        return;
      }
      setInvoice(_invoice as any);
    } catch (err: any) {
      if (err.response.status === 404) {
        navigate("/NotFound");
        return;
      }
      enqueueSnackbar("Error loading invoice. ", { variant: "error" });
      console.error(err);
    }
  }

  useEffect(() => {
    loadInvoice();
    const timer = setInterval(loadInvoice, 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (!invoice) {
    return <PageSplashScreen />;
  }

  return (
    <Stack
      sx={{
        pt: "148px",
        minHeight: "100vh",
        [theme.breakpoints.down("tablet")]: { pt: "96px" },
      }}
    >
      <Header />
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        flex={1}
        sx={{ mb: "48px" }}
      >
        <Stack
          sx={{
            gap: "32px",
            width: "700px",
            background: "#1c1c1c",
            p: "24px",
            borderRadius: "12px",
            [theme.breakpoints.down("tablet")]: {
              width: "100%",
              gap: "24px",
            },
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              fontSize={32}
              fontWeight={500}
              sx={{
                [theme.breakpoints.down("tablet")]: {
                  fontSize: 24,
                },
              }}
            >
              Invoice
            </Typography>
          </Stack>

          <Stack spacing={"12px"}>
            {/* ID */}
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                fontSize={18}
                fontWeight={500}
                minWidth={200}
                sx={{
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                    minWidth: 140,
                  },
                }}
              >
                Invoice ID
              </Typography>
              <Typography
                sx={{
                  opacity: 0.9,
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                  },
                }}
                fontSize={18}
              >
                {invoice?.id}
              </Typography>
            </Stack>

            {/* Date/Time */}
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                fontSize={18}
                fontWeight={500}
                minWidth={200}
                sx={{
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                    minWidth: 140,
                  },
                }}
              >
                Created on
              </Typography>
              <Typography
                sx={{
                  opacity: 0.9,
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                  },
                }}
                fontSize={18}
              >
                {moment.unix(invoice?.created!).format("MMMM DD, YYYY hh:mm A")}
              </Typography>
            </Stack>

            {/* Amount Requested */}
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                fontSize={18}
                fontWeight={500}
                minWidth={200}
                sx={{
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                    minWidth: 140,
                  },
                }}
              >
                Amount requested
              </Typography>
              <Typography
                sx={{
                  opacity: 0.9,
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                  },
                }}
                fontSize={18}
              >
                {invoice?.data.amount.crypto.amount}{" "}
                {invoice?.data.amount.crypto.currency} (
                {getCurrencySymbol(invoice?.data.amount.fiat.currency!)}
                {invoice?.data.amount.fiat.amount})
              </Typography>
            </Stack>

            {/* Amount Paid */}
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                fontSize={18}
                fontWeight={500}
                minWidth={200}
                sx={{
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                    minWidth: 140,
                  },
                }}
              >
                Amount paid
              </Typography>
              <Typography
                sx={{
                  opacity: 0.9,
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                  },
                }}
                fontSize={18}
              >
                {invoice?.data.amount_paid?.crypto?.amount || 0}{" "}
                {invoice?.data.amount_paid?.crypto?.currency ||
                  invoice?.data.amount.crypto.currency}{" "}
                ({getCurrencySymbol(invoice?.data.amount.fiat.currency!)}
                {invoice?.data.amount_paid?.fiat?.amount || 0})
              </Typography>
            </Stack>

            {/* Invoice Status */}
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                fontSize={18}
                fontWeight={500}
                minWidth={200}
                sx={{
                  opacity: 0.9,
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: 14,
                    minWidth: 140,
                  },
                }}
              >
                Status
              </Typography>
              <Chip
                label={
                  invoice?.status === BlockchainInvoiceStatus.PartiallyPaid
                    ? "Partially Paid"
                    : invoice.status
                }
                variant="outlined"
                size="small"
                color={
                  invoice?.status === BlockchainInvoiceStatus.Paid
                    ? "success"
                    : invoice?.status === BlockchainInvoiceStatus.Unpaid
                    ? "default"
                    : invoice.status === BlockchainInvoiceStatus.PartiallyPaid
                    ? "warning"
                    : "error"
                }
                sx={{ textTransform: "capitalize" }}
              />
            </Stack>

            {(invoice.status === BlockchainInvoiceStatus.Unpaid ||
              invoice.status === BlockchainInvoiceStatus.PartiallyPaid) && (
              <Stack direction={"row"} alignItems={"center"}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  minWidth={200}
                  sx={{
                    opacity: 0.9,
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                      minWidth: 140,
                    },
                  }}
                >
                  Expires
                </Typography>
                <Stack flex={1}>
                  <Typography>
                    {moment
                      .unix(invoice.data.expires!)
                      .format("MMM DD, YYYY hh:mm A")}
                  </Typography>
                  <LinearProgress
                    sx={{ width: "100%", height: "5px", borderRadius: "8px" }}
                    value={
                      100 -
                      ((moment().unix() - invoice.data.createdAt) /
                        (invoice.data.expires - invoice.data.createdAt)) *
                        100.0
                    }
                    variant="determinate"
                  />
                </Stack>
              </Stack>
            )}

            {/* Paid at (if status = paid) */}
            {invoice.status === BlockchainInvoiceStatus.Paid && (
              <Stack direction={"row"} alignItems={"center"}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  minWidth={200}
                  sx={{
                    opacity: 0.9,
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                      minWidth: 140,
                    },
                  }}
                >
                  Paid on
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.9,
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                    },
                  }}
                  fontSize={18}
                >
                  {moment
                    .unix(invoice?.data.paidAt!)
                    .format("MMMM DD, YYYY hh:mm A")}
                </Typography>
              </Stack>
            )}
          </Stack>

          {invoice.status === BlockchainInvoiceStatus.Unpaid && (
            <Typography sx={{ fontSize: 14 }}>
              Complete the payment by sending the specified amount to the
              address below from your preferred wallet.
            </Typography>
          )}
          {invoice.status === BlockchainInvoiceStatus.PartiallyPaid && (
            <Typography sx={{ fontSize: 14 }}>
              The invoice has been underpaid. To complete the payment, send the
              remaining amount to the address below from your preferred wallet.
            </Typography>
          )}

          {invoice.status === BlockchainInvoiceStatus.Expired && (
            <Typography sx={{ fontSize: 14 }}>
              The invoice has been expired. Please contact administrators to
              manually process the invoice, or create a new one.
            </Typography>
          )}
          {(invoice.status === BlockchainInvoiceStatus.Unpaid ||
            invoice.status === BlockchainInvoiceStatus.PartiallyPaid) && (
            <Stack spacing={"12px"}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  minWidth={200}
                  sx={{
                    opacity: 0.9,
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                      minWidth: 140,
                    },
                  }}
                >
                  Network
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.9,
                    textTransform: "uppercase",
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                    },
                  }}
                  fontSize={18}
                >
                  {invoice?.data.network}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  minWidth={200}
                  sx={{
                    opacity: 0.9,
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                      minWidth: 140,
                    },
                  }}
                >
                  Currency
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.9,
                    textTransform: "uppercase",
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                    },
                  }}
                  fontSize={18}
                >
                  {invoice?.data.amount.crypto.currency}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  minWidth={200}
                  sx={{
                    opacity: 0.9,
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                      minWidth: 140,
                    },
                  }}
                >
                  Address
                </Typography>
              </Stack>
              <WhiteInput
                text={invoice.data.address}
                endAdornment={
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(invoice.data.address);
                      enqueueSnackbar("Address copied to clipboard!");
                    }}
                  >
                    <SvgIcon htmlColor="#000">
                      <IC_COPY />
                    </SvgIcon>
                  </IconButton>
                }
              />
              <Stack direction={"row"} alignItems={"center"}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  minWidth={200}
                  sx={{
                    opacity: 0.9,
                    [theme.breakpoints.down("tablet")]: {
                      fontSize: 14,
                      minWidth: 140,
                    },
                  }}
                >
                  Amount (in {invoice.data.amount.crypto.currency.toUpperCase()}
                  )
                </Typography>
              </Stack>
              <WhiteInput
                text={invoice.data.amount.crypto.amount.toString()}
                endAdornment={
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        invoice.data.amount.crypto.amount.toString()
                      );
                      enqueueSnackbar("Amount copied to clipboard!");
                    }}
                  >
                    <SvgIcon htmlColor="#000">
                      <IC_COPY />
                    </SvgIcon>
                  </IconButton>
                }
              />
              <Alert
                icon={
                  <SvgIcon>
                    <IC_ALERT />
                  </SvgIcon>
                }
                variant="outlined"
                color="warning"
              >
                <AlertTitle>Warning</AlertTitle>
                <Typography>
                  Send only the specified currency to this address and ensure
                  you are on the same network as mentioned above.
                </Typography>
              </Alert>
            </Stack>
          )}

          {/* When invoice is paid, we show */}
          {invoice?.status === BlockchainInvoiceStatus.Paid && (
            <Typography>
              Payment for this invoice has been completed.
            </Typography>
          )}

          {invoice?.status === BlockchainInvoiceStatus.Paid && (
            <Stack spacing={"12px"}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography fontSize={18} fontWeight={500} minWidth={200}>
                  Address ({invoice.data.network.toUpperCase()})
                </Typography>
              </Stack>
              <WhiteInput
                text={invoice.data.address}
                endAdornment={
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(invoice.data.address);
                      enqueueSnackbar("Address copied to clipboard!");
                    }}
                  >
                    <SvgIcon htmlColor="#000">
                      <IC_COPY />
                    </SvgIcon>
                  </IconButton>
                }
              />
              <Stack direction={"row"} alignItems={"center"}>
                <Typography fontSize={18} fontWeight={500} minWidth={200}>
                  Transaction Hash
                </Typography>
              </Stack>
              <WhiteInput
                text={invoice.data.transaction}
                endAdornment={
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(invoice.data.transaction!);
                      enqueueSnackbar("Transaction hash copied to clipboard!");
                    }}
                  >
                    <SvgIcon htmlColor="#000">
                      <IC_COPY />
                    </SvgIcon>
                  </IconButton>
                }
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Footer />
    </Stack>
  );
}
