import React, { useEffect, useState } from "react";
import {
  Avatar,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { rtmGetFeedbacksA } from "../../core/api/admin";
import { User } from "../../types/User";
import { PlatformFeedback } from "../../types/PlatformFeedback";
import ViewFeedbackDialog from "../../dialogs/ViewFeedback";

export default function SectionAdminFeedbacks() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<(PlatformFeedback & { user: User })[]>([]);
  const { showModal } = useModal();

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _data = await rtmGetFeedbacksA();
      if (_data) {
        setData(_data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Platform Feedbacks`);
    setSectionDescription(
      `View feedbacks and reports from users on the platform.`
    );

    // Load page 0
    load();
  }, []);

  function RenderFeedback(fb: PlatformFeedback & { user: User }) {
    return (
      <TableRow
        onClick={() => {
          const md = showModal(ViewFeedbackDialog, {
            data: fb,
            closeHandler(result) {
              md.destroy();
            },
          });
        }}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography color="#94969C">{fb.id}</Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={fb.user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {fb.user.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {fb.user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip label={fb.category} variant="outlined" size="small" />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(fb.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {fb.content.substring(0, 100)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        busy={busy}
        titleBadge={data.length + " Feedbacks"}
        title="Feedbacks"
        description="All the feedbacks provided by users."
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>User</Typography>
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell>
              <Typography>Category</Typography>
            </TableCell>
            {/* Time  */}
            <TableCell>
              <Typography>Time</Typography>
            </TableCell>
            {/* Content  */}
            <TableCell>
              <Typography>Content</Typography>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderFeedback(d))}</>}
      />
    </Stack>
  );
}
