import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { IC_ALERT, IC_HELP } from "../../../assets/ui";
import PFMInput from "../../../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import {
  rtmGetPlatformConfig,
  rtmSetPlatformConfig,
} from "../../../core/api/admin";
import { Config, PlatformConfiguration } from "../../../types/Config";

export default function SectionAdminSettingsConfig() {
  const [busy, setBusy] = useState(false);
  const [config, setConfig] = useState<
    Config & { value: PlatformConfiguration }
  >();

  const [image, setImage] = useState("");
  const [secrets, setSecrets] = useState("");
  const [requestedCpu, setRequestedCpu] = useState(0);
  const [requestedRam, setRequestedRam] = useState(0);
  const [limitCpu, setLimitCpu] = useState(0);
  const [limitRam, setLimitRam] = useState(0);

  async function load() {
    try {
      setBusy(true);
      const cfg = await rtmGetPlatformConfig();
      if (cfg) {
        setConfig(cfg);
        const pc = cfg.value as PlatformConfiguration;
        setImage(pc?.image || "");
        setSecrets(pc?.pullSecrets || "");
        setRequestedCpu(pc?.resources.requests.cpu || 0);
        setRequestedRam(pc?.resources.requests.ram || 0);
        setLimitCpu(pc?.resources.limits.cpu || 0);
        setLimitRam(pc?.resources.limits.ram || 0);
      } else {
        enqueueSnackbar(
          "No configuration found. Please set the values to enable the auto-deployment of services."
        );
      }
    } catch (err: any) {
      console.error("Error loading platform configuration for admin. ", err);
      enqueueSnackbar("Error loading configuration. Please try later. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function save() {
    try {
      if (!limitCpu || !limitRam || !requestedCpu || !requestedRam) {
        enqueueSnackbar("One or more required values are empty. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const cfg = await rtmSetPlatformConfig({
        image: image,
        pullSecrets: secrets,
        resources: {
          limits: {
            cpu: limitCpu,
            ram: limitRam,
          },
          requests: {
            cpu: requestedCpu,
            ram: requestedRam,
          },
        },
      });
      if (cfg) {
        setConfig(cfg);
        enqueueSnackbar("Configuration updated successfully. ", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          "Looks like that did not work. Reload the page and try again.",
          { variant: "error" }
        );
      }
    } catch (err: any) {
      console.error("Error saving platform configuration for admin. ", err);
      enqueueSnackbar("Error saving configuration. Please try later. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack flex={1} sx={{ py: "18px" }} alignItems={"start"} spacing="8px">
      <Stack spacing="8px" direction={"row"}>
        <PFMInput
          label="Image"
          disabled={busy}
          sx={{ width: "380px" }}
          helperText="The latest docker image from the container registry that is launched for new users."
          text={image}
          onUpdate={(t) => setImage(t)}
        />
        <PFMInput
          text={secrets}
          onUpdate={(t) => setSecrets(t)}
          disabled={busy}
          sx={{ width: "380px" }}
          label="Pull Secrets Ref"
          helperText="Reference to ImagePullSecrets secret on cluster for pulling the image."
        />
      </Stack>

      {/* The resource settings  */}
      <Stack direction={"row"} alignItems={"center"} spacing="8px">
        <Typography>Resource Allocation</Typography>
        <Tooltip
          arrow
          placement="right"
          title="The amount of RAM and CPU to allocate for each instance."
        >
          <SvgIcon>
            <IC_HELP />
          </SvgIcon>
        </Tooltip>
      </Stack>
      <Stack spacing="8px">
        <Stack direction={"row"} spacing="8px">
          <PFMInput
            text={requestedCpu.toString()}
            onUpdate={(t) => setRequestedCpu(parseInt(t) || 0)}
            label="Requested CPU"
            important
            sx={{ width: "380px" }}
            endAdornment={
              <Typography sx={{ width: "120px" }}>Millicores</Typography>
            }
          />

          <PFMInput
            text={limitCpu.toString()}
            onUpdate={(t) => setLimitCpu(parseInt(t) || 0)}
            label="CPU Limit"
            important
            sx={{ width: "380px" }}
            endAdornment={
              <Typography sx={{ width: "120px" }}>Millicores</Typography>
            }
          />
        </Stack>
        <Stack direction={"row"} spacing="8px">
          <PFMInput
            text={requestedRam.toString()}
            onUpdate={(t) => setRequestedRam(parseInt(t) || 0)}
            label="Requested RAM"
            important
            sx={{ width: "380px" }}
            endAdornment={
              <Typography sx={{ width: "120px" }}>Mebibytes</Typography>
            }
          />

          <PFMInput
            text={limitRam.toString()}
            onUpdate={(t) => setLimitRam(parseInt(t) || 0)}
            label="RAM Limit"
            important
            sx={{ width: "380px" }}
            endAdornment={
              <Typography sx={{ width: "120px" }}>Mebibytes</Typography>
            }
          />
        </Stack>
        <Button
          disabled={busy}
          onClick={save}
          sx={{ alignSelf: "flex-start" }}
          variant="contained"
        >
          Save
        </Button>

        {config && config.updated && (
          <Typography color={"#AAA"}>
            Last updated:{" "}
            <span style={{ color: "#DDD" }}>
              {moment.unix(config.updated).fromNow()}
            </span>{" "}
            by{" "}
            <span style={{ color: "#DDD" }}>
              {config.updatedBy.name} ({config.updatedBy.email})
            </span>
          </Typography>
        )}
      </Stack>

      <Stack flex={1} />

      <Stack direction={"row"} spacing="8px" alignItems={"center"}>
        <SvgIcon color="warning" fontSize="small">
          <IC_ALERT />
        </SvgIcon>
        <Typography fontSize={"12px"}>
          Please don't touch anything unless you know what you are doing.
        </Typography>
      </Stack>
    </Stack>
  );
}
