import {
  Button,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CARD_IMAGE from "../../assets/ui/Card.png";
import { IC_GROUP_OF_ICON } from "../../assets/ui";

function GetStartBanner() {
  const desktop = useMediaQuery("(min-width: 700px)");
  const tablet = useMediaQuery("(min-width: 1024px)");
  const theme = useTheme();

  return (
    <Stack
      padding={" 48px"}
      sx={{
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          // flexDirection: "column",
          padding: "16px",
        },
      }}
    >
      <Stack
        borderRadius={"20px"}
        direction={"row"}
        padding={"0 40px"}
        // overflow={"hidden"}
        sx={{
          background: "linear-gradient(100deg, #4275F5 0%, #BE72EC 100%)",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            padding: " 0 16px",
            gap: "32px",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        {/* img */}
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 233px 0px 13px",
            width: "600px",
            [theme.breakpoints.down("desktop")]: {
              minWidth: "350px",
            },
            [theme.breakpoints.down("tablet")]: {
              width: "225px",
              padding: " 0 46px 0px 50px",
              alignSelf: "stretch",
            },
          }}
        >
          <img src={IC_GROUP_OF_ICON} alt="" style={{ width: "100%" }} />
          {/* <IC_GROUP_OF_ICON width="100%" /> */}
        </Stack>
        {/* text */}

        <Stack spacing={"30px"} justifyContent={"end"}>
          <Stack spacing={"20px"}>
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                lineHeight: "100%",
                letterSpacing: "-0.6px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  flexDirection: "column",
                  fontSize: "20px",
                },
              }}
            >
              Start monetising your Telegram channels / groups
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "500",
                textAlign: "start",
                lineHeight: "100%",
                letterSpacing: " -0.3px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  flexDirection: "column",
                  fontSize: "12px",
                },
              }}
            >
              Accept Stripe or Crypto payments and exchange them with your
              content, subscriptions and access links.
            </Typography>
          </Stack>
          <Stack
            sx={{
              paddingTop: "48px",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                paddingTop: "24px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                gap: "10px",
              },
            }}
          >
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "stretch",
                [theme.breakpoints.down("desktop")]: {
                  minWidth: "350px",
                },
                [theme.breakpoints.down("tablet")]: {
                  alignItems: "start",
                  justifyContent: "start",
                  minWidth: "170px",
                  width: "170px",
                },
              }}
            >
              {" "}
              <img src={CARD_IMAGE} alt="" style={{ width: "100%" }} />
            </Stack>
            <Stack>
              <Button
                href={"/signup"}
                variant="contained"
                size={desktop ? "large" : tablet ? "medium" : "small"}
                sx={{
                  borderRadius: "10px 0px",
                  textTransform: "uppercase",
                  background: "#fff",
                  border: "1px solid transparent",
                  padding: "8px 32px",
                  color: "#000000",
                  ":hover": {
                    background: "transparent",
                    border: "1px solid #FFF",
                    color: "white",
                  },
                  [theme.breakpoints.down("desktop")]: {},
                  [theme.breakpoints.down("tablet")]: {
                    padding: "4px 16px",
                    flexShrink: "0",
                  },
                }}
              >
                get started
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default GetStartBanner;
