import React from "react";
import { Stack, Typography } from "@mui/material";
import Header from "../header";
import Footer from "../footer";
import GoToTop from "../fab";
import ContactUs from "../contact";
import DualHeading from "../../../components/DualHeading";

export default function PrivacyPage() {
  const TERMS = [
    {
      title: "1. Information We Collect",
      text: "",
    },
    {
      title: "a. Firebase Authentication",
      text: "To facilitate user authentication, we utilize Google Firebase, collecting and storing user data such as names, emails, and display pictures.",
    },
    {
      title: "b. Stripe Integration",
      text: "For seamless payment processing, we integrate with Stripe. Please review Stripe's Privacy Policy for comprehensive information on their data handling practices.",
    },
    {
      title: "c. Cashifybot User Data",
      text: "We gather and store data for both end-users (Telegram subscribers) and our platform users (business owners). Platform owners may request the deletion of their data. Telegram subscribers must reach out to the respective business or Telegram owner for data deletion.",
    },
    {
      title: "d. Web Application Data",
      text: "Our web app stores essential information on users' browsers using storage and cookies, vital for the application's functionality.",
    },
    {
      title: "e. Email Marketing",
      text: "We collect and use your email address to send you newsletters about our product. These communications are designed to keep you informed about updates, new features, and other relevant information about our services. We do not share your email address with third parties for their marketing purposes. You have the right to opt out of receiving these marketing emails at any time by: ",
      lines: [
        `1. Clicking the "Unsubscribe" link at the bottom of any marketing email we send you`,
        "2. Updating your communication preferences in your account settings",
        "3. Contacting us directly at privacy@cashifybot.com",
        "Please note that even if you opt out of marketing emails, we may still send you important transactional emails related to your account or our services. We process and store your email information in accordance with applicable data protection laws.",
      ],
    },
    {
      title: "2. Data Deletion Requests",
      text: "",
    },
    {
      title: "a. Cashifybot User Data",
      text: "Individual platform owners may request the deletion of their data by contacting us.",
    },
    {
      title: "b. Telegram Subscribers",
      text: "Subscribers must directly contact the respective business/Telegram owner to initiate data deletion requests.",
    },
    {
      title: "3. Browser Storage and Cookies",
      text: "Our web application employs storage and cookies, necessary for its functionality. Without this data, users cannot utilize the application.",
    },
    {
      title: "4. Telegram Interaction",
      text: "End users interacting with our Telegram bot are subject to Telegram's terms and policies. These terms apply specifically to the bot side and not the web application.",
    },
    {
      title: "5. Updates and Modifications",
      text: "This Privacy Policy may be periodically updated, and users are encouraged to revisit it regularly to stay informed about any changes.",
    },
    {
      title: "6. Consent",
      text: "By utilizing our web application, users acknowledge they have read and understood the terms and privacy policy.",
    },
    {
      title: "7. Trademarks",
      text: "Cashifybot and its logo are registered trademarks of Cashifybot Inc in the UK and US.",
    },
    {
      title: "8. Additional Provisions",
      text: "",
    },
    {
      title: "a. Security Measures",
      text: "We implement industry-standard security measures to protect user data.",
    },
    {
      title: "b. Third-party Links",
      text: "Our web app may contain links to third-party websites. We are not responsible for their privacy practices and encourage users to review their policies.",
    },
    {
      title: "c. Children's Privacy",
      text: "Cashifybot is not intended for individuals under the age of 16. We do not knowingly collect data from individuals in this age group.",
    },
  ];

  return (
    <Stack sx={{ pt: "148px" }}>
      <Header />
      <Stack alignItems={"center"}>
        <Stack
          sx={{ maxWidth: "50%", py: "32px", minHeight: "100vh" }}
          justifyContent={"center"}
        >
          <DualHeading
            h1="Privacy"
            h2="Policy"
            sx={{ fontSize: "26px" }}
            h2sx={{ color: "#48F" }}
          />
          <DualHeading
            h1="Last updated: "
            h2="August 3rd, 2024"
            sx={{ fontSize: "16px" }}
            h2sx={{ color: "#48F" }}
          />
          <DualHeading
            h1="For further information or inquiries regarding this Privacy Policy, please contact us at "
            h2="privacy@cashifybot.com"
            sx={{ fontSize: "12px" }}
            h2sx={{ color: "#48F" }}
          />
          <br />
          <br />
          <Typography>
            Welcome to Cashifybot, a service provided by CASHIFYBOT LTD,
            registered in the United Kingdom. In this Privacy Policy, we outline
            the information we collect, how it is used, and your choices
            concerning your data.
          </Typography>
          <br />
          <Stack spacing={"24px"}>
            {TERMS.map((t) => (
              <>
                <Typography
                  sx={{ px: t.text ? "14px" : undefined }}
                  color={"primary"}
                  fontSize={16}
                  fontWeight={500}
                >
                  {t.title}
                </Typography>
                {t.text && (
                  <Typography
                    sx={{ pl: "28px" }}
                    fontSize={16}
                    fontWeight={400}
                  >
                    {t.text}
                  </Typography>
                )}
                {t.lines && (
                  <>
                    {t.lines.map((tl) => (
                      <Typography
                        sx={{ pl: "28px" }}
                        fontSize={16}
                        fontWeight={400}
                      >
                        {tl}
                      </Typography>
                    ))}
                  </>
                )}
              </>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <ContactUs />
      <Footer />
      <GoToTop />
    </Stack>
  );
}
