import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_AFFILIATE, IC_DELETE_CREDIT_CARD } from "../assets/ui";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import { rtmSetAffiliateProfile } from "../core/api/user";
import { rtmGetProfile } from "../core/api";
import { PFMSwitch } from "../components/PFMSwitch";
import LoadingView from "../components/LoadingView";
import { getCurrencySymbol } from "../core/helper";
import { User } from "../types/User";

export default function SetupAffiliationDialog(props: DialogProps<any>) {
  const [profile, setProfile] = useState<User>();
  const [percentage, setPercentage] = useState(10);
  const [minPayout, setMinPayout] = useState(5);
  const [enabled, setEnabled] = useState(true);
  const [paypal, setPaypal] = useState(false);

  const [mode, setMode] = useState<"single" | "recurring">("single");
  const [busy, setBusy] = useState(false);

  const canSave = useMemo(() => {
    return percentage > 0 && minPayout >= 5;
  }, [percentage, minPayout]);

  async function save() {
    try {
      setBusy(true);
      await rtmSetAffiliateProfile({
        commission: percentage,
        enabled: enabled,
        minPayout: Math.ceil(minPayout * 100),
        mode: mode,
        paypal: paypal,
      });
      enqueueSnackbar("Successfully updated affiliation settings. ", {
        variant: "success",
      });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again. ", {
        variant: "error",
      });
      console.error("Error saving affiliation settings. ", err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      setEnabled(prof.affiliation ? prof.affiliation.enabled : true); // should be true if profile does not exist.
      setMinPayout((prof.affiliation?.minPayout || 500) / 100);
      setPercentage(prof.affiliation?.commission || 10);
      setMode(prof.affiliation?.mode || "single");
      setPaypal(prof.affiliation?.paypal || false);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading data for affiliation settings dialog.", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <IconContainer>
            <IC_AFFILIATE />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Setup Affiliation
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Settings to enable affiliation for your telegram users.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!profile && <LoadingView />}
        {profile && (
          <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
            {/* Switch for Toggle: Only shown if profile.affiliation is already set */}
            {profile?.affiliation && (
              <Stack spacing={"4px"}>
                <Typography>Enable Affiliation</Typography>
                <PFMSwitch
                  checked={enabled}
                  onChange={(e) => setEnabled(e.currentTarget.checked)}
                />
              </Stack>
            )}

            {/* Affiliation Kind  */}
            <Stack spacing={"4px"}>
              <Typography>Select your affiliation type</Typography>
              <Stack direction={"row"} spacing={"8px"}>
                <Stack
                  onClick={() => setMode("single")}
                  flex={1}
                  sx={{
                    cursor: "pointer",
                    border:
                      mode === "single" ? "1px solid #48F" : "1px solid #FFF3",
                    borderRadius: "12px",
                    px: "14px",
                    py: "8px",
                  }}
                >
                  <Typography fontWeight={500} fontSize={20}>
                    One-time Payments
                  </Typography>
                  <Typography fontSize={14}>
                    Reward affiliates on first invoice paid by their referral.
                  </Typography>
                </Stack>

                <Stack
                  onClick={() => setMode("recurring")}
                  flex={1}
                  sx={{
                    cursor: "pointer",
                    border:
                      mode === "recurring"
                        ? "1px solid #48F"
                        : "1px solid #FFF3",
                    borderRadius: "12px",
                    px: "14px",
                    py: "8px",
                  }}
                >
                  <Typography fontWeight={500} fontSize={20}>
                    Recurring Payments
                  </Typography>
                  <Typography fontSize={14}>
                    Reward affiliates on every invoice paid by their referral.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack spacing={"4px"}>
              <Typography>Allow PayPal Addresss for Payout Method</Typography>
              <PFMSwitch
                checked={paypal}
                onChange={(e) => setPaypal(e.currentTarget.checked)}
              />
            </Stack>

            {/* Percentage of commission */}
            <Stack spacing={"4px"}>
              <Typography>Commission percentage</Typography>
              <PFMInput
                startAdornment={
                  <Typography sx={{ width: "24px" }} textAlign={"end"}>
                    %
                  </Typography>
                }
                onUpdate={(t) => setPercentage(parseFloat(t))}
                defaultText={percentage.toString()}
                placeholder="Enter commission percentage.."
                helperText="The % is calculated after factoring in our platform fees."
                errorText={
                  percentage <= 0 ? "Value cannot be less than 1." : undefined
                }
              />
            </Stack>

            {/* Minimum Payout  */}
            <Stack spacing={"4px"}>
              <Typography>Minimum Payout</Typography>
              <PFMInput
                startAdornment={
                  <Typography sx={{ width: "24px" }} textAlign={"end"}>
                    {getCurrencySymbol(profile?.currency!)}
                  </Typography>
                }
                onUpdate={(t) => setMinPayout(parseFloat(t))}
                placeholder="Enter minimum payout amount.."
                defaultText={minPayout.toString()}
                helperText="The amount specified is in your platform's currency."
                errorText={
                  minPayout < 5 ? "Value must be at least 5.0" : undefined
                }
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          size="large"
          fullWidth
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={!canSave}
          onClick={save}
          size="large"
          fullWidth
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
