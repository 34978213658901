import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { BOT_ERROR, BOT_HAPPY } from "../assets/ui";
import PFMInput from "../components/PFMInput";
import {
  rtmGetBotStats,
  rtmGetCommand,
  rtmGetTelegramConfig,
  rtmSetCommand,
  rtmUnsetCommand,
} from "../core/api/user";
import { enqueueSnackbar } from "notistack";
import { BotStatus } from "../types/BotStatus";
import { TelegramConfig } from "../types/TelegramConfig";

export default function ReconfigureBotDialog(props: DialogProps<any>) {
  const [connected, setConnected] = useState(false);
  const [token, setToken] = useState("");
  const [botStatus, setBotStatus] = useState<BotStatus>();
  const [config, setConfig] = useState<TelegramConfig>();
  const [busy, setBusy] = useState(false);

  async function load() {
    try {
      // Get Bot Status
      const stats = await rtmGetBotStats();
      setBotStatus(stats);
      // Load existing configuratio
      const cfg = await rtmGetTelegramConfig();
      if (cfg) {
        setConfig(cfg);
        setToken(cfg.token);
        if (Boolean(cfg.token) && Boolean(cfg.username)) setConnected(true);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
  }

  async function setupTelegram() {
    try {
      // Create profile
      setBusy(true);
      // Set the command
      await rtmSetCommand("init_telegram", token);
      // Set telegram
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Error updating data. Please try again later. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    load();

    let _isIntervalBusy = false;
    let timer = setInterval(async () => {
      // Load stats of bot.
      if (_isIntervalBusy) return;
      _isIntervalBusy = true;
      try {
        const stats = await rtmGetBotStats();
        setBotStatus(stats);
        const cmd = await rtmGetCommand();
        if (cmd && !cmd.result) {
          setToken(cmd.input);
          setBusy(true);
        }
        if (cmd.result?.success) {
          // command succeeded
          setConnected(true);

          setBusy(false);
          load();
          await rtmUnsetCommand();
        } else if (cmd.result?.success === false) {
          setBusy(false);
          setConnected(false);
          enqueueSnackbar(
            "Failed to login. Try again or use a different token. ",
            { variant: "error" }
          );
          await rtmUnsetCommand();
        }
      } catch (err: any) {}
      _isIntervalBusy = false;
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Dialog open hideBackdrop PaperComponent={TransparentPaper} fullWidth>
      <DialogContent>
        <Stack sx={{ py: "18px", px: "24px" }} spacing={"18px"}>
          {!connected && (
            <Stack alignItems={"center"}>
              <BOT_ERROR height="168px" />
              <Typography textAlign={"center"} color={"error"}>
                It appears that your bot token may be invalid or is no longer
                working. Please re-enter your bot token to continue using
                Cashifybot. Please avoid changing bot identity as your new bot
                will not able to access chats with the old bot ID.
              </Typography>
            </Stack>
          )}
          {connected && (
            <Stack alignItems={"center"} spacing={"18px"}>
              <BOT_HAPPY height="168px" />
              <Typography textAlign={"center"} color={"primary"}>
                Your bot is back online and ready to use. You can now close this
                dialog and continue using Cashifybot.
              </Typography>
            </Stack>
          )}
          <PFMInput
            disabled={busy || connected}
            text={token}
            onUpdate={(t) => setToken(t)}
            placeholder="Enter your bot token..."
            label="Bot Token"
            important
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={busy || !token || botStatus?.status !== "running"}
          size="large"
          onClick={() => {
            if (connected) {
              props.closeHandler(true);
            } else {
              setupTelegram();
            }
          }}
          fullWidth
          variant="contained"
          color="primary"
        >
          {botStatus?.status === "pending" && (
            <>
              Provisioning, please wait...
              <CircularProgress size={"24px"} />
            </>
          )}
          {botStatus?.status !== "pending" && connected && <>Close</>}
          {botStatus?.status !== "pending" && !connected && <>Connect</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
