import _logo_cashifybot from "./cashifybot.png";
import _logo_retina from "./retina.png";
import _logo_sidebar from "./sidebar.png";
import logo_header from "./logo_white_full.png";
import logo_retina_white from "./logo_white.png";

export const LOGO_CASHIFYBOT = _logo_cashifybot;
export const LOGO_RETINA = _logo_retina;
export const LOGO_SIDEBAR = _logo_sidebar;
export const LOGO_HEADER = logo_header;
export const LOGO_RETINA_WHITE = logo_retina_white;
