import React, { useState } from "react";
import { Button, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { getCookie, setCookie } from "../core/helper";
import { IC_COOKIE } from "../assets/ui";

export default function CookieConsentForm() {
  const theme = useTheme();
  const [consented, setConsented] = useState(
    getCookie("cashifybot-consent-result") === "accepted"
  );
  function consent() {
    setCookie("cashifybot-consent-result", "accepted", 30);
    setConsented(getCookie("cashifybot-consent-result") === "accepted");
  }
  if (consented) return <></>;
  return (
    <Stack
      sx={{
        position: "fixed",
        bottom: 24,
        left: 24,
        background: "#333",
        width: "390px",
        px: "24px",
        py: "24px",
        borderRadius: "14px",
        zIndex: 210,
        border: "1px solid #FFF2",
        [theme.breakpoints.down("md")]: {
          width: "100%",
          left: 8,
          bottom: 8,
        },
      }}
      alignItems={"start"}
      spacing={"12px"}
    >
      <SvgIcon>
        <IC_COOKIE />
      </SvgIcon>
      <Typography>
        The site uses third-party cookies to personalize content, ads, and
        analyze site traffic.{" "}
      </Typography>
      <Stack direction={"row"} spacing={"8px"}>
        <Button variant="contained" onClick={consent}>
          Accept
        </Button>
        <Button href="/privacy">Privacy Policy</Button>
      </Stack>
    </Stack>
  );
}
