import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

export default function GoToTop() {
  const [show, setShow] = useState(false);

  function handleScroll() {
    setShow(window.scrollY > 300);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <IconButton
      color="primary"
      sx={{
        position: "fixed",
        bottom: "24px",
        left: "24px",
        borderRadius: "100px",
        width: "64px",
        height: "64px",
        zIndex: 100,
        background: "#48F2",
        transition: "all .5s",
        opacity: show ? 100 : 0,
      }}
      title="Go to top"
      onClick={() => {
        window.scrollTo({
          behavior: "smooth",
          top: 0,
        });
      }}
    >
      <ArrowUpward />
    </IconButton>
  );
}
