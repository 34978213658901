import { RtmClient } from "@zexcore/rtm-client";
import { FbAuth } from "../../authentication/firebase";
import { AppConfig } from "../../config";
import { User } from "../../types/User";
import { Config, PaymentsConfiguration } from "../../types/Config";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { Notification } from "../../types/Notification";

let client: RtmClient;

export async function ensureAuthentication(fallbackEmail?: string) {
  return new Promise(async (resolve, reject) => {
    if (!client) {
      const token = FbAuth.currentUser
        ? await FbAuth.currentUser.getIdToken()
        : "livechat:" + fallbackEmail;
      client = new RtmClient(AppConfig.rtm, {
        reconnectDelayMs: 5000,
        onReconnecting(attempt) {
          console.log(`Trying to reconnect for ${attempt} time...`);
        },
        authenticationData: token,
        async onOpen() {
          console.log("Connected to the RTM server.");
          resolve(true);
        },

        onClose() {
          console.log("Connection with the RTM server was closed.");
        },

        onError() {
          console.log("Error connecting to the RTM server");
          reject();
        },
      });
    } else {
      resolve(true);
    }
  });
}

export function Subscribe(
  event: "onMessage" | "onMessageUpdate",
  callback: (data: any) => void
) {
  return client.Subscribe(event, (data) => {
    callback(data);
  });
}

export function getRtmClient() {
  return client;
}

export async function rtmGetProfile() {
  await ensureAuthentication();
  const prof = await client.CallWait<User>("rtmGetProfile");
  return prof;
}

export async function rtmGetPaymentsConfig() {
  await ensureAuthentication();
  const prof = await client.CallWait<Config & { value: PaymentsConfiguration }>(
    "rtmGetPaymentsConfig"
  );
  return prof;
}

export async function rtmGetPlans() {
  await ensureAuthentication();
  const prof = await getRtmClient().CallWait<{
    yearly: any;
    monthly: any;
    trial: number;
  }>("rtmGetPlans");
  return prof;
}

/**
 * Gets the user's notifications.
 * @param filter Filter notifications by read or unread, or all.
 * @param page Current page number for paginated query.
 */
export async function rtmGetNotifications(
  filter: "all" | "read" | "unread",
  page: number
) {
  await ensureAuthentication();
  const notis = await getRtmClient().CallWait<PaginatedResponse<Notification>>(
    "rtmGetNotifications",
    {
      filter,
      page,
    }
  );
  return notis;
}

/**
 * Returns the unread notifications count.
 * @returns
 */
export async function rtmGetNotificationsCount(
  status: "all" | "read" | "unread" = "unread"
) {
  await ensureAuthentication();
  const notis = await getRtmClient().CallWait<number>(
    "rtmGetNotificationsCount",
    status
  );
  return notis;
}

/**
 * Update the status of a specified notification. Optionally allows marking all notifications as read/unread by passing ID as 'all'
 * @param notificationId
 * @param status
 * @returns
 */
export async function rtmUpdateNotification(
  notificationId: "all" | string,
  status: "read" | "unread"
) {
  await ensureAuthentication();
  const r = await getRtmClient().CallWait<boolean>("rtmUpdateNotification", {
    read: status,
    notification: notificationId,
  });
  return r;
}
