import React, { useEffect, useState } from "react";
import {
  IconButton,
  Stack,
  stackClasses,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import { motion } from "framer-motion";
import {
  aCurrentSection,
  aCurrentSectionDescription,
} from "../../../core/states";
import {
  IC_COPY,
  IC_EDIT_EMAIL,
  IC_EMAIL_ADD,
  IC_EMAIL_DELETE,
} from "../../../assets/ui";
import { useModal } from "mui-modal-provider";
import {
  rtmDeleteTemplate,
  rtmGetEmailTemplates,
} from "../../../core/api/admin";
import { Add } from "@mui/icons-material";
import { EmailTemplate } from "../../../types/EmailTemplate";
import EditTemplateDialog from "../../../dialogs/EditTemplate";

export default function SectionAdminEmailTemplates() {
  const { showModal } = useModal();
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<EmailTemplate[]>([]);

  async function load() {
    try {
      setBusy(true);
      const camps = await rtmGetEmailTemplates();
      setData(camps);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  async function deleteTemplate(t: EmailTemplate) {
    try {
      setBusy(true);
      await rtmDeleteTemplate(t.id);
      load();
    } catch (err: any) {
      enqueueSnackbar("Error updating data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  function showEditDialog(t?: Partial<EmailTemplate>) {
    const modal = showModal(EditTemplateDialog, {
      closeHandler(result) {
        modal.destroy();
        load();
      },
      data: t as any,
    });
  }

  useEffect(() => {
    setSection(`Template Manager`);
    setSectionDescription(`Add, update, or delete email templates. `);
    load();
  }, []);

  function RenderTemplate(t: EmailTemplate) {
    return (
      <Stack
        sx={{
          width: "250px",
          height: "300px",
          border: "1px solid #FFF3",
          borderRadius: "12px",
          position: "relative",
          overflow: "hidden",
          ":hover": {
            [`& .${stackClasses.root}`]: {
              opacity: 1,
            },
          },
        }}
        alignItems={"start"}
        justifyContent={"flex-end"}
        spacing={"8px"}
      >
        <img
          src={t.thumbnail}
          alt={t.name}
          style={{
            flex: 1,
            objectFit: "contain",
            width: "100%",
          }}
        />
        <Typography fontSize={18} sx={{ padding: "8px" }}>
          {t.name}
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"8px"}
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            background: "#0003",
            transition: "all .3s",
            opacity: 0,
            zIndex: 2,
          }}
        >
          <IconButton
            title="Edit"
            sx={{ background: "#222", ":hover": { background: "#444" } }}
            onClick={() => showEditDialog(t)}
          >
            <SvgIcon color="primary">
              <IC_EDIT_EMAIL />
            </SvgIcon>
          </IconButton>
          <IconButton
            title="Duplicate"
            sx={{ background: "#222", ":hover": { background: "#444" } }}
            onClick={() => showEditDialog({ ...t, id: undefined })}
          >
            <SvgIcon color="primary">
              <IC_COPY />
            </SvgIcon>
          </IconButton>
          <IconButton
            title="Delete"
            sx={{ background: "#222", ":hover": { background: "#444" } }}
            onClick={() => deleteTemplate(t)}
          >
            <SvgIcon color="error">
              <IC_EMAIL_DELETE />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>
    );
  }

  return (
    <motion.div
      style={{ overflow: "hidden", flex: 1, display: "flex", padding: "12px" }}
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
    >
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        columnGap={"18px"}
        rowGap={"18px"}
        flex={1}
        sx={{ overflow: "auto" }}
      >
        {data.map((t) => RenderTemplate(t))}
        <Stack
          sx={{
            width: "250px",
            height: "300px",
            border: "1px solid #FFF3",
            borderRadius: "12px",
            position: "relative",
            cursor: "pointer",
            ":hover": {
              [`& .${stackClasses.root}`]: {
                opacity: 1,
              },
            },
          }}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={"8px"}
          onClick={() => showEditDialog()}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={"8px"}
            sx={{
              height: "100%",
              width: "100%",
              position: "absolute",
              background: "#0003",
              transition: "all .3s",
              opacity: 0,
            }}
          ></Stack>
          <SvgIcon>
            <Add />
          </SvgIcon>
          <Typography fontSize={14} sx={{ padding: "8px" }}>
            Add a Template
          </Typography>
        </Stack>
      </Stack>
    </motion.div>
  );
}
