import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import { IC_CANCEL, IC_COPY, IC_HELP, IC_USER_ADD } from "../assets/ui";
import { FbAuth, FbStorage } from "../authentication/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Add } from "@mui/icons-material";
import ReactPlayer from "react-player";
import { rtmSubmitFeedback } from "../core/api/user";
export default function FeedbackDialog(props: DialogProps<any>) {
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");

  const [media, setMedia] = useState<
    { name: string; size: number; url: string; type: string }[]
  >([]);
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [busy, setBusy] = useState(false);

  async function submit() {
    try {
      setBusy(true);
      await rtmSubmitFeedback(content, category, media);
      enqueueSnackbar("Your feedback has been submitted. Thank you!", {
        variant: "success",
      });
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error submitting feedback. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function uploadMedia(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/media/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setMedia([
            ...media,
            {
              name: attachment.name,
              size: attachment.size,
              type: attachment.type,
              url: url,
            },
          ]);
          enqueueSnackbar("Product media uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading a file. ", { variant: "error" });
      console.log(err);
    }
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_HELP />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Feedback
              </Typography>
              <Typography fontSize={14} fontWeight={400} variant="caption">
                Fill in the user's details and give them a role.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Category
              </Typography>
              <PFMAutoComplete
                options={[
                  { label: "UI/UX" },
                  { label: "Telegram Bot" },
                  { label: "Payments" },
                  { label: "Other" },
                ]}
                placeholder="Select a category.."
                onUpdate={(v) => setCategory((v as any)?.label || "")}
                value={{ label: category } as any}
                important
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Your Feedback
              </Typography>
              <Stack flex={1} spacing={"16px"}>
                <PFMInput
                  text={content}
                  onUpdate={(t) => setContent(t)}
                  fullWidth
                  multiline
                  rows={8}
                  placeholder="Please write a brief description."
                />
                <Stack>
                  <Typography fontSize={18} fontWeight={500}>
                    Attach Media
                  </Typography>
                  <Typography fontSize={14} fontWeight={300} variant="caption">
                    Insert screenshots, images, or videos.
                  </Typography>

                  <input
                    id="file-input-media"
                    style={{
                      position: "absolute",
                      opacity: 0,
                      zIndex: -999999,
                    }}
                    type="file"
                    accept="image/png,image/jpeg,image/jpg,video/mp4,video/flv"
                    onChange={(f) => {
                      if (f.target.files && f.target.files?.length > 0) {
                        uploadMedia(f.target.files[0]);
                      }
                    }}
                  />
                  <Button
                    disabled={busy}
                    onClick={() =>
                      document.getElementById("file-input-media")?.click()
                    }
                    startIcon={<Add />}
                    sx={{ alignSelf: "start", my: "8px" }}
                  >
                    Add a file
                  </Button>

                  <Stack spacing="8px">
                    {media.map((f) => (
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        sx={{
                          background: "#333",
                          borderRadius: "12px",
                          position: "relative",
                          height: "50px",
                          p: "8px",
                        }}
                      >
                        {f.type.startsWith("image/") && (
                          <img
                            alt={f.name}
                            src={f.url}
                            style={{
                              height: "100%",
                              maxWidth: "100px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                        {f.type.startsWith("video/") && (
                          <ReactPlayer
                            controls
                            height={"100%"}
                            style={{ maxWidth: "100px" }}
                            url={f.url}
                          />
                        )}
                        <Stack
                          flex={1}
                          alignItems={"start"}
                          sx={{ px: "16px" }}
                        >
                          <Typography sx={{ pl: "12px" }}>{f.name}</Typography>
                          <Typography
                            sx={{ pl: "12px", color: "#FFF9" }}
                            fontSize={12}
                          >
                            {Math.ceil(f.size / 1024)}KB
                          </Typography>
                        </Stack>

                        <IconButton
                          onClick={() => {
                            setMedia([
                              ...media.filter((fx) => fx.url !== f.url),
                            ]);
                          }}
                        >
                          <SvgIcon>
                            <IC_CANCEL />
                          </SvgIcon>
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={submit}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
