import _img_arbitrum from "./arbitrum.png";
import _img_ethereum from "./ethereum.png";
import _img_matic from "./polygon.png";
import _img_telegram from "./telegram.png";
import _img_stripe from "./stripe.png";
import _img_discord from "./discord.png";
import _img_wise from "./wise.png";
import _img_binance from "./binance.png";

export const IMG_BINANCE = _img_binance;
export const IMG_DISCORD = _img_discord;
export const IMG_STRIPE = _img_stripe;
export const IMG_TELEGRAM = _img_telegram;
export const IMG_MATIC = _img_matic;
export const IMG_ETHEREUM = _img_ethereum;
export const IMG_ARBITRUM = _img_arbitrum;
export const IMG_WISE = _img_wise;
