import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import SubscriberDetailsDialog from "../../dialogs/SubscriberDetails";
import PFMInput from "../../components/PFMInput";
import { IC_REFRESH, IC_SEARCH } from "../../assets/ui";
import { motion } from "framer-motion";
import { rtmGetSubscribers } from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import { TelegramUser } from "../../types/TelegramUser";
import { ActiveSubscription } from "../../types/ActiveSubscription";

export default function SectionUserSubscribers() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [busy, setBusy] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [data, setData] = useState<TelegramUser[]>([]);

  async function loadSubscribers(page: number) {
    try {
      setBusy(true);
      const _data = await rtmGetSubscribers(page, search);
      if (_data) {
        setData(_data.data);
        setPageNumber(_data.currentPage);
        setDataCount(_data.total);
        setPageCount(_data.pageCount);
      }
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please contact administrators.", {
        variant: "error",
      });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Customers`);
    setSectionDescription(
      `View your active subscribers & telegram bot users that interacted with the bot.`
    );
    loadSubscribers(0);
  }, []);

  function RenderSub(
    u: TelegramUser & { subscriptions: ActiveSubscription[] }
  ) {
    return (
      <TableRow>
        {!mobile && (
          <TableCell>
            <Typography color="#94969C">{u.id}</Typography>
          </TableCell>
        )}
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {u.name}
        </TableCell>
        <TableCell
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 10,
            },
          }}
        >
          {u.username}
        </TableCell>
        <TableCell>
          <Stack direction={"row"} spacing={"4px"}>
            {u.subscriptions?.map((s, i) => (
              <Typography
                sx={{
                  transition: "all .1s",
                  py: "2px",
                  px: "4px",
                  cursor: "pointer",
                  borderRadius: "8px",
                  ":hover": {
                    background: "#4488FF33",
                  },
                  ":active": {
                    background: "#4488FF66",
                  },
                  [theme.breakpoints.down("md")]: {
                    fontSize: 10,
                  },
                }}
              >
                {s.subscription.name}
                {i < u.subscriptions.length - 1 ? ", " : ""}
              </Typography>
            ))}
          </Stack>
        </TableCell>
        <TableCell>
          {u.access_codes?.map((s) => (
            <Typography
              sx={{
                textDecoration: "underline",
                ":hover": { color: "#4488FF", cursor: "pointer" },
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              {s.code}
            </Typography>
          ))}
        </TableCell>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(u.lastActivity).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={() => {
              const modal = showModal(SubscriberDetailsDialog, {
                closeHandler(result) {
                  modal.destroy();
                },
                data: u,
              });
            }}
          >
            Details
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "12px",
      }}
    >
      {mobile && (
        <Stack direction="row" justifyContent={"flex-end"} spacing={"8px"}>
          <PFMInput
            placeholder="Search..."
            startIcon={IC_SEARCH}
            text={search}
            onUpdate={(t) => setSearch(t)}
            onReturn={() => {
              loadSubscribers(pageNumber);
            }}
          />
          <Button
            variant="contained"
            disabled={busy}
            onClick={() => loadSubscribers(pageNumber)}
            color="secondary"
            size="small"
            sx={{ width: "140px" }}
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
        </Stack>
      )}
      <PFMTable
        sx={{ flex: 1 }}
        title="Subscribers"
        description="List of all the subscribers."
        titleBadge={data.length + " Subscribers"}
        paginated
        pageNumber={pageNumber}
        totalPages={pageCount}
        prevPage={(n) => loadSubscribers(n)}
        nextPage={(n) => loadSubscribers(n)}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
              <PFMInput
                placeholder="Search..."
                startIcon={IC_SEARCH}
                text={search}
                onUpdate={(t) => setSearch(t)}
                onReturn={() => {
                  loadSubscribers(pageNumber);
                }}
              />
              <Button
                size="large"
                variant="contained"
                disabled={busy}
                onClick={() => loadSubscribers(pageNumber)}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
            </Stack>
          )
        }
        tableHead={
          <TableRow>
            {!mobile && <TableCell>User ID</TableCell>}
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Username
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Subscriptions
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Codes
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Last Activity
            </TableCell>
            <TableCell
              align="right"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderSub(d as any))}</>}
      />
    </motion.div>
  );
}
