import {
  Alert,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import PFMCheckbox from "../../components/PFMCheckbox";
import { enqueueSnackbar } from "notistack";
import { RestSendMessage } from "../../core/rest";
import WhiteInput from "./components/WhiteInput";
export default function ContactUs() {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  const tablet = useMediaQuery("(min-width: 1024px)");

  async function sendForm() {
    try {
      setSubmitting(true);
      await RestSendMessage(name, email, subject, msg);

      enqueueSnackbar("Your message has been sent. ", { variant: "success" });
      setSubmitted(true);
    } catch (err: any) {
      enqueueSnackbar("Error submitting form. Please try again.", {
        variant: "error",
      });
    }
    setSubmitting(false);
  }
  const theme = useTheme();
  return (
    <Stack
      id="contact"
      alignItems={"center"}
      sx={{
        padding: "48px 0px 96px 0px",
        [theme.breakpoints.down("desktop")]: {
          padding: "48px 0px 96px 0px",
        },
        [theme.breakpoints.down("tablet")]: {
          padding: "48px 0px 96px 0px",
        },
      }}
    >
      <Stack spacing={"16px"} alignItems={"center"}>
        <Typography
          sx={{
            padding: "8px 16px",
            background: "rgba(255, 255, 255, 0.10)",
            borderRadius: "5px",
          }}
        >
          CONTACT US
        </Typography>

        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "300",
            textAlign: "start",
            lineHeight: "100%",
            letterSpacing: "-1px",
            [theme.breakpoints.down("desktop")]: {
              fontSize: "40px",
            },
            [theme.breakpoints.down("tablet")]: {
              fontSize: "30px",
            },
          }}
        >
          Have More Questions?
        </Typography>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            color: "#fff",
            textAlign: "center",
            display: "inline",
            //
            [theme.breakpoints.down("tablet")]: {
              fontSize: "15px",
              display: "inline",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              fontSize: "12px",
              display: "inline",
            },
          }}
        >
          You can also reach us anytime via{" "}
          <Typography
            component={"a"}
            href="https://t.me/CashifySupportBot"
            sx={{
              fontSize: "15px",
              display: "inline",
              fontWeight: "400",
              color: "#fff",
              textDecoration: "none",
              //
              [theme.breakpoints.down("tablet")]: {
                fontSize: "15px",
                display: "inline",
              },
              //
              [theme.breakpoints.down("tablet")]: {
                fontSize: "12px",
                display: "inline",
              },
            }}
          >
            @CashifySupportBot
          </Typography>{" "}
          on telegram
        </Typography>

        {/* The contact form  */}
        <Stack sx={{ width: "100%" }} alignItems={"center"}>
          <Stack
            flex={1}
            sx={{
              maxWidth: desktop ? "600px" : "100%",
              p: "32px",
              borderRadius: "12px",
            }}
            spacing={"8px"}
          >
            <WhiteInput
              disabled={submitted || submitting}
              label="Full name"
              // placeholder="Enter your full name..."
              text={name}
              onUpdate={(t) => setName(t)}
            />
            <WhiteInput
              disabled={submitted || submitting}
              label="Email"
              // placeholder="Enter your email address..."
              text={email}
              onUpdate={(t) => setEmail(t)}
            />
            <WhiteInput
              disabled={submitted || submitting}
              label="Subject"
              // placeholder="What is the message about?"
              text={subject}
              onUpdate={(t) => setSubject(t)}
            />
            <WhiteInput
              disabled={submitted || submitting}
              label="Message"
              multiline
              text={msg}
              onUpdate={(t) => setMsg(t)}
              rows={8}
              // placeholder="Write your message."
            />
            <PFMCheckbox
              typographySx={{ fontSize: desktop ? 14 : 10 }}
              value={agreement}
              onUpdate={(t) => setAgreement(t)}
              label="By submitting this form, you agree to Cashifybot's privacy policy."
            />
            {!submitted && (
              <Button
                disabled={submitting || !agreement}
                onClick={sendForm}
                variant="contained"
                size={desktop ? "large" : tablet ? "medium" : "small"}
                sx={{
                  borderRadius: "10px 0px",
                  textTransform: "uppercase",
                  background:
                    "linear-gradient(180deg, #0494BE 0%, #543488 100%)",
                  padding: "8px 32px",

                  overflow: "hidden",
                  color: "#FFF",
                  ":hover": {
                    background: "transparent",
                    border: "1px solid #FFF",
                  },
                }}
              >
                Submit
              </Button>
            )}

            {submitted && <Alert>Your message has been sent.</Alert>}
          </Stack>{" "}
        </Stack>
      </Stack>
    </Stack>
  );
}
