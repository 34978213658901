import { GetCurrentToken } from "../authentication/firebase";
import axios from "axios";
import { AppConfig } from "../config";
import { Invoice } from "../types/Invoice";

const BASE_URL = AppConfig.api;
export async function RestGetInvoice(id: string) {
  // Send the request
  const resp = await axios.get(BASE_URL + "/invoices/" + id);
  return resp.data as Invoice;
}

/**
 * Called after a successful signup event. Sends an account confirmation email for the logged in user.
 */
export async function RestPostSignup(ref?: string, affiliate?: boolean) {
  const token = await GetCurrentToken();

  // Send the request
  await axios.post(
    BASE_URL + "/accounts/signup",
    { ref: ref, affiliate: affiliate },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

/**
 * Subscribe a user to the mailing list.
 */
export async function RestSubscribe(email: string) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/subscribe", {
    email: email,
  });
}

/**
 * Called after a successful verification event. Sends a welcome email for the registered user.
 */
export async function RestPostVerification(email: string, stamp: number) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/confirmed", {
    stamp: stamp,
    email: email,
  });
}

/**
 * Sends a password reset link via email. Called from forgot password section.
 */
export async function RestRequestPasswordReset(email: string) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/password-reset", {
    email: email,
  });
}

/**
 * Sends the contact form
 */
export async function RestSendMessage(
  name: string,
  email: string,
  subject: string,
  message: string
) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/message", {
    email: email,
    name: name,
    subject: subject,
    message: message,
  });
}
