import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Stack, Typography, useTheme } from "@mui/material";
export default function RotatoryText(props: { text: string[]; delay: number }) {
  const theme = useTheme();

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const _timer = setInterval(() => {
      if (currentIndex >= props.text.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(Math.min(currentIndex + 1, props.text.length - 1));
      }
    }, props.delay);

    return () => {
      clearInterval(_timer);
    };
  });

  return (
    <Stack
      sx={{ overflow: "hidden", position: "relative", alignItems: "center" }}
    >
      <AnimatePresence mode="popLayout">
        <motion.div
          key={currentIndex}
          style={{
            background:
              "linear-gradient(90deg, rgba(6, 160, 191, 0.50) 0%, rgba(105, 95, 234, 0.50) 100%)",
          }}
          initial={{ translateY: 100 }}
          animate={{ translateY: 0 }}
          exit={{ translateY: -100, opacity: 0, scale: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            sx={{
              fontSize: "50px",
              color: "#FFF",
              fontWeight: "700",
              padding: "8px",
              textAlign: "center",
              textWrap: "nowrap",
              [theme.breakpoints.down("desktop")]: {
                fontSize: "30px",
              },
            }}
          >
            {props.text[currentIndex]}
          </Typography>
        </motion.div>
      </AnimatePresence>
    </Stack>
  );
}
