import { Stack, Typography, useTheme } from "@mui/material";
import { useMotionValue, useScroll, useTransform, motion } from "framer-motion";
import React, { useRef } from "react";
import AnimatedTextLine from "./components/AnimatedTextLine";

function CreaterText() {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        padding: "128px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        [theme.breakpoints.down("tablet")]: { padding: "64px 191px" },
        [theme.breakpoints.down("tablet")]: { padding: "64px 32px" },
      }}
    >
      <AnimatedTextLine
        text={"Are you a content creator"}
        from={150}
        to={300}
      />
      <AnimatedTextLine
        text={"entrepreneur, or professional"}
        from={200}
        to={250}
      />

      <AnimatedTextLine
        text={"looking to turn your passion into"}
        from={250}
        to={300}
      />

      <AnimatedTextLine
        text={"profit but feeling overwhelmed"}
        from={300}
        to={400}
      />

      <AnimatedTextLine
        text={"by setting up payments and"}
        from={400}
        to={500}
      />

      <AnimatedTextLine text={"subscriptions?"} from={500} to={550} />
    </Stack>
  );
}

export default CreaterText;
