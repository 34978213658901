import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { IC_PAID } from "../assets/ui";

import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import { rtmUpdatePayout } from "../core/api/admin";
import { User } from "../types/User";
import { Payout } from "../types/Affiliation/Payout";
import { IMG_BINANCE, IMG_WISE } from "../assets/brands";
import PFMInput from "../components/PFMInput";

export default function PayoutDetailsDialog(
  props: DialogProps<Payout & { user: User }>
) {
  const [busy, setBusy] = useState(false);
  const theme = useTheme();

  const payout = props.data;

  async function updateStatus(
    status: "pending" | "processing" | "complete" | "failed"
  ) {
    try {
      setBusy(true);
      await rtmUpdatePayout({
        ...(payout as any),
        status: status,
      });
      enqueueSnackbar("Payout has been updated. ", { variant: "success" });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error processing payout. Please try later.", {
        variant: "error",
      });
      console.error("Error processing payout. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {}, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <SvgIcon>
            <IC_PAID />
          </SvgIcon>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Payout Details
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              View and confirm payout.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="8px" sx={{ p: "32px", pb: "32px" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} spacing="12px" alignItems={"center"}>
              <Avatar
                src={props.data?.user.image || ""}
                sx={{ color: "white" }}
              />
              <Stack>
                <Typography fontSize={14} fontWeight={500}>
                  {props.data?.user.name}
                </Typography>
                <Typography fontSize={14} fontWeight={400} color="#94969C">
                  {props.data?.user.email}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              color={"primary"}
              sx={{ fontSize: 20, fontWeight: 500 }}
            >
              {getCurrencySymbol(props.data?.currency!)}
              {`${parseStripeAmount(props.data?.amount || 0).dollars}.${
                parseStripeAmount(props.data?.amount || 0).cents
              }`}
            </Typography>
          </Stack>

          {/* Payout Details */}

          {payout?.method === "wise" && (
            <Stack
              spacing="8px"
              sx={{
                transition: "all .3s",
                cursor: "pointer",
                py: "12px",
                px: "24px",
                borderRadius: "8px",
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <img
                  src={IMG_WISE}
                  alt="Wise"
                  style={{
                    height: "64px",
                    objectFit: "contain",
                    alignSelf: "start",
                  }}
                />
                <Typography> Payments</Typography>
              </Stack>
              <Stack spacing={"8px"}>
                <PFMInput
                  text={payout.details?.email}
                  label="Registered Email"
                  placeholder="Enter your registered wise email..."
                />
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    text={payout.details?.iban}
                    label="IBAN #"
                    placeholder="Enter your IBAN number..."
                  />
                  <PFMInput
                    text={payout.details?.swift}
                    label="Swift Code"
                    placeholder="Enter your bank's swift code.."
                  />
                </Stack>
                <Typography>
                  Additional details required in some cases
                </Typography>
                <PFMInput
                  text={payout.details?.fullName}
                  label="Full Name"
                  placeholder="Enter Your Full Name"
                />
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    label="Address"
                    placeholder="Enter your address"
                    text={payout.details?.address}
                  />
                  <PFMInput
                    label="City"
                    placeholder="Enter name of your city"
                    text={payout.details?.city}
                  />
                </Stack>
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    text={payout.details?.zip}
                    label="ZIP Code"
                    placeholder="Enter your area zip code"
                  />
                  <PFMInput
                    label="Country"
                    text={payout.details?.country}
                    placeholder="Enter name of your country or State"
                  />
                </Stack>
              </Stack>
            </Stack>
          )}

          {payout?.method === "binance" && (
            <Stack
              spacing="8px"
              sx={{
                transition: "all .3s",
                cursor: "pointer",
                py: "12px",
                px: "24px",
                borderRadius: "8px",
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <img
                  src={IMG_BINANCE}
                  alt="BinancePay"
                  style={{
                    height: "96px",
                    objectFit: "contain",
                    alignSelf: "start",
                  }}
                />
                <Typography>Pay</Typography>
              </Stack>

              <Stack spacing={"8px"}>
                <Stack direction={"row"} spacing={"8px"}>
                  <PFMInput
                    text={payout.details?.email}
                    label="Email Address"
                    placeholder="Enter your registered binance email..."
                  />
                  <PFMInput
                    text={payout.details?.payId}
                    label="Binance Pay ID"
                    placeholder="Enter your binance pay id..."
                  />
                </Stack>
                <PFMInput
                  text={payout.details?.notes}
                  label="Additional Notes"
                  multiline
                  rows={3}
                  placeholder="Enter additional notes or instructions..."
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={"row"}
          sx={{ width: "100%", px: "32px" }}
          spacing="12px"
        >
          <Button
            disabled={busy}
            onClick={props.closeHandler}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
          {props.data?.status === "pending" && (
            <Button
              disabled={busy}
              size="large"
              fullWidth
              onClick={() => updateStatus("processing")}
              variant="contained"
              color="primary"
            >
              Mark Processing
            </Button>
          )}
          {props.data?.status === "processing" && (
            <Button
              disabled={busy}
              size="large"
              fullWidth
              onClick={() => updateStatus("complete")}
              variant="contained"
              color="primary"
            >
              Mark Complete
            </Button>
          )}
          {props.data?.status === "processing" && (
            <Button
              disabled={busy}
              size="large"
              fullWidth
              onClick={() => updateStatus("failed")}
              variant="contained"
              color="primary"
            >
              Mark Failed
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
