import React from "react";
import {
  Button,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IC_MENU } from "../assets/ui";
import { LOGO_SIDEBAR } from "../assets/brand";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
  aShowSidebar,
} from "../core/states";
import { Logout } from "@mui/icons-material";
import { rtmSignout } from "../core/api/user";

export default function AppBar() {
  const theme = useTheme();
  const [profile] = useRecoilState(aProfile);
  const [section] = useRecoilState(aCurrentSection);
  const [description] = useRecoilState(aCurrentSectionDescription);
  const [, setShowSidebar] = useRecoilState(aShowSidebar);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  async function signOut() {
    try {
      await rtmSignout();
      window.location.reload();
    } catch (err: any) {
      console.error("Error signing out. ", err);
    }
  }
  return (
    <>
      {profile?.impersonator && (
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            p: "8px",
            background: theme.palette.warning.dark,
          }}
        >
          <Typography fontSize={20} fontFamily={"Space Grotesk"}>
            Impersonation Mode Enabled
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
            <Typography>
              Signed in as <b>{profile.email}</b> by{" "}
              <b>{profile.impersonator.email}</b>
            </Typography>
            <Button
              endIcon={
                <SvgIcon>
                  <Logout />
                </SvgIcon>
              }
              variant="contained"
              color="inherit"
              sx={{ height: "40px" }}
              onClick={signOut}
            >
              Exit
            </Button>
          </Stack>
        </Stack>
      )}
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={"8px"}
        sx={{
          height: "64px",
          mx: "12px",
          mt: "12px",
          borderRadius: "12px",
          px: "20px",
          [theme.breakpoints.down("md")]: {
            background: "#FFFFFF13",
          },
        }}
      >
        {mobile && (
          <>
            <IconButton onClick={() => setShowSidebar(true)}>
              <SvgIcon>
                <IC_MENU />
              </SvgIcon>
            </IconButton>

            <img
              src={LOGO_SIDEBAR}
              alt="Cashifybot Logo"
              style={{ height: "32px" }}
            />
            <Typography>|</Typography>
          </>
        )}
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: 500,
            [theme.breakpoints.down("md")]: {
              fontSize: 14,
            },
          }}
        >
          {section || "Home"}
        </Typography>
        {!mobile && (
          <>
            <Typography>|</Typography>
            <Typography sx={{ color: "#858585" }}>{description}</Typography>
          </>
        )}
      </Stack>
    </>
  );
}
