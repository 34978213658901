import React, { useEffect, useState } from "react";
import { Badge, Stack, ToggleButton, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../../core/states";
import { StyledToggleButtonGroup } from "../../../components/ToggleButtonGroup";
import SectionAdminSettingsProfile from "./profile";
import SectionAdminSettingsConfig from "./configuration";
import SectionAdminSettingsPayments from "./payments";

export default function SectionAdminSettings() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [range, setRange] = useState<"profile" | "payments" | "configuration">(
    "profile"
  );

  useEffect(() => {
    setSection(`Settings`);
    setSectionDescription(`View or update platform configuration.`);
  }, []);

  return (
    <Stack sx={{ p: "32px", overflow: "hidden" }} flex={1}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        {range === "profile" && (
          <Stack>
            <Typography fontSize={24} fontWeight={400}>
              Profile
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="#94969C">
              View or update your user profile.
            </Typography>
          </Stack>
        )}
        {range === "payments" && (
          <Stack>
            <Typography fontSize={24} fontWeight={400}>
              Payments
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="#94969C">
              View or update payment settings, such as fee percentage, referral
              percentage, etc.
            </Typography>
          </Stack>
        )}
        {range === "configuration" && (
          <Stack>
            <Typography fontSize={24} fontWeight={400}>
              Configuration
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="#94969C">
              Change platform configuration, such as service image, CPU and RAM
              allocation, etc.
            </Typography>
          </Stack>
        )}
        <StyledToggleButtonGroup
          exclusive
          value={range}
          onChange={(c, e) => setRange(e || range)}
        >
          <ToggleButton value={"profile"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "profile" ? "primary" : undefined}
              ></Badge>
              <Typography>Profile</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"payments"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "payments" ? "primary" : undefined}
              ></Badge>
              <Typography>Payments</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"configuration"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "configuration" ? "primary" : undefined}
              ></Badge>
              <Typography>Configuration</Typography>
            </Stack>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Stack>
      {range === "profile" && <SectionAdminSettingsProfile />}
      {range === "payments" && <SectionAdminSettingsPayments />}
      {range === "configuration" && <SectionAdminSettingsConfig />}
    </Stack>
  );
}
