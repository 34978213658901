import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { IC_ABOUT } from "../../assets/ui";
import TextCard from "./components/AboutText";

function About() {
  const theme = useTheme();

  return (
    <Stack
      id="about"
      sx={{
        maxWidth: "1280px",
        width: "100%",
        alignSelf: "center",
        flexDirection: "row",
        [theme.breakpoints.down("tablet")]: {
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <Box
        sx={{
          height: "531px",
          minWidth: "579px",
          p: 0,
          [theme.breakpoints.down("desktop")]: {
            height: "350px",
            minWidth: "350px",
          },
          [theme.breakpoints.down("tablet")]: {
            minWidth: "261px",
            width: "261px",
            height: "auto",
          },
        }}
      >
        {" "}
        <img src={IC_ABOUT} alt="" style={{ width: "100%" }} />
        {/* <IC_ABOUT width="100%" /> */}
      </Box>
      <Stack direction={"column"} spacing={"32px"} sx={{ px: "26px" }}>
        <Stack alignItems={"flex-start"} justifyContent={"start"} gap={"24px"}>
          <Typography
            sx={{
              padding: "8px 16px",
              background: "rgba(255, 255, 255, 0.10)",
              borderRadius: "5px",
              fontSize: "20px",
              fontWeight: "700",
              [theme.breakpoints.down("tablet")]: {
                fontSize: "10px",
                fontWeight: "700",
              },
            }}
          >
            ABOUT US
          </Typography>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "40px",
              fontWeight: "700",
              alignSelf: "stretch",
              [theme.breakpoints.down("desktop")]: {
                fontSize: "28px",
              },
              [theme.breakpoints.down("tablet")]: {
                fontSize: "22px",
              },
            }}
          >
            Cashifybot makes it simple to start making money from your online
            community through Telegram.
          </Typography>

          <Typography
            sx={{
              color: "#FFF",
              fontSize: "20px",
              fontWeight: "300",

              [theme.breakpoints.down("tablet")]: {
                fontSize: "16px",
              },
            }}
          >
            Whether you want to charge for access, sell digital downloads, or
            offer subscriptions, we’ve got you covered.
          </Typography>
        </Stack>
        <Stack>
          {/* 1 */}
          <TextCard
            Number="01"
            title="Our web app is built with you in mind—easy to use, intuitive, and
              accessible to everyone."
          />
          {/* 2 */}
          <TextCard
            Number="02"
            title=" We provide the tools you need to manage subscriptions, payments,
              and digital assets, all in one place."
          />
          {/* 3 */}
          <TextCard
            Number="03"
            title=" Our platform offers everything you need to manage your earnings
              without the complexity—no coding, no hassle."
          />
          {/* 4 */}
          <TextCard
            Number="04"
            title=" We're here to help you succeed, offering reliable customer support
              and continuous updates to meet your needs."
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default About;
