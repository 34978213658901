import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { Delete, Edit } from "@mui/icons-material";
import CreateSubscriptionDialog from "../../dialogs/CreateSubscription";
import EditSubscriptionDialog from "../../dialogs/EditSubscription";
import DeleteSubscriptionDialog from "../../dialogs/DeleteSubscription";
import DisableSubscriptionDialog from "../../dialogs/DisableSubscription";

import { IC_ADD_CARD, IC_REFRESH } from "../../assets/ui";
import { motion } from "framer-motion";
import {
  rtmEditSubscription,
  rtmGetSubscriptions,
  rtmGetTelegramChats,
} from "../../core/api/user";
import { rtmGetProfile } from "../../core/api";
import { Subscription } from "../../types/Subscription";

export default function SectionUserSubscriptions() {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<any[]>([]);

  async function updateSubscription(sub: Subscription) {
    try {
      setBusy(true);
      await rtmEditSubscription(sub);
      enqueueSnackbar("Subscription has been updated. ", {
        variant: "success",
      });
      loadSubscriptions();
    } catch (err: any) {
      enqueueSnackbar("Error updating a subscription. Error: " + err.message, {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function loadSubscriptions() {
    // try {
    //   //Load data

    setBusy(true);
    const _subs = await rtmGetSubscriptions();
    setData(_subs || []);
    console.log("Loaded Subs", _subs);
    // Get all chats, and then substitute names for chats in subscriptions
    const _chats = await rtmGetTelegramChats();
    console.log("Loaded Chats", _chats);
    for (let _s of _subs) {
      for (let _c of _s.chats) {
        // Find chat
        const _chat = _chats.find((c) => c.id === _c.id);
        if (_chat) {
          // Insert it in _s
          _c.title = _chat.title;
          _c.icon = _chat.icon;
          _c.lastActivity = _chat.lastActivity;
          _c.type = _chat.type;
          _c.username = _chat.username;
        }
      }
    }
    setData(_subs || []);
    console.log("Loaded Data", _subs);
    const prof = await rtmGetProfile();
    setProfile(prof);
    // } catch (err: any) {
    //   enqueueSnackbar("Error loading data. ", { variant: "error" });
    //   console.error("Error loading data. ", { err, data, profile });
    // }
    setBusy(false);
  }

  function showCreateDialog() {
    if (!profile?.currency) {
      enqueueSnackbar(
        "ERROR: Currency not set. Please finish onboarding before creating subscriptions.",
        { variant: "error" }
      );
      return;
    }
    const modal = showModal(CreateSubscriptionDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          loadSubscriptions();
        }
      },
    });
  }

  function showEditDialog(sub: Subscription) {
    const modal = showModal(EditSubscriptionDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          loadSubscriptions();
        }
      },
      data: sub,
    });
  }
  function showDeleteDialog(sub: Subscription) {
    const modal = showModal(DeleteSubscriptionDialog, {
      async closeHandler(result) {
        if (result) {
          // proceed to delete the subscription
          sub.deleted = true;
          await updateSubscription(sub);
          loadSubscriptions();
        }
        modal.destroy();
      },
      data: sub,
    });
  }

  async function showDisableDialog(sub: Subscription) {
    // If subscription is disabled, we enable it.
    if (sub.disabled) {
      // Enable
      sub.disabled = false;
      await updateSubscription(sub);
      return;
    } else {
      // Otherwise
      const modal = showModal(DisableSubscriptionDialog, {
        async closeHandler(result) {
          if (result) {
            // Disable the subscription
            sub.disabled = true;
            await updateSubscription(sub);
            loadSubscriptions();
          }
          modal.destroy();
        },
        data: sub,
      });
    }
  }

  useEffect(() => {
    setSection(`Subscriptions`);
    setSectionDescription(
      `Discover subscription plans, customize user experience with price plans and linked products.`
    );
    loadSubscriptions();
  }, []);

  function RenderSubscription(sub: Subscription) {
    return (
      <TableRow>
        {!mobile && (
          <TableCell>
            <Typography color="#94969C">{sub.id}</Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {sub.name}
          </Typography>
        </TableCell>
        <TableCell>
          {sub.disabled && (
            <Chip
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
              label="Disabled"
              variant="outlined"
              color="warning"
              size="small"
            />
          )}
          {!sub.disabled && (
            <Chip
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
              label="Active"
              variant="outlined"
              color="success"
              size="small"
            />
          )}
        </TableCell>
        <TableCell align="left">
          <Typography
            color="#94969C"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {moment.unix(sub.modifiedTimestamp).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              textTransform: "capitalize",
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {sub.kind === "onetime" ? "Lifetime" : sub.schedule}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="#94969C"
            sx={{
              textTransform: "capitalize",
              [theme.breakpoints.down("md")]: {
                fontSize: 10,
              },
            }}
          >
            {getCurrencySymbol(sub.price.currency)}
            {parseStripeAmount(sub.price.amount).dollars +
              "." +
              parseStripeAmount(sub.price.amount).cents}
          </Typography>
        </TableCell>
        <TableCell>
          {sub.chats.map((c) => (
            <Chip
              variant="identifier"
              label={c.title}
              size="small"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            />
          ))}
        </TableCell>
        <TableCell>
          {sub.products?.map((p) => (
            <Chip
              variant="identifier"
              label={p.name}
              size="small"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            />
          ))}
        </TableCell>

        <TableCell align="right">
          <Stack direction={"row"}>
            {sub.disabled && (
              <Button
                disabled={busy}
                color="success"
                title="Enable the subscription"
                onClick={() => showDisableDialog(sub)}
              >
                Enable
              </Button>
            )}
            {!sub.disabled && (
              <Button
                disabled={busy}
                color="warning"
                title="Disable the subscription"
                onClick={() => showDisableDialog(sub)}
              >
                Disable
              </Button>
            )}
            <IconButton
              disabled={busy}
              color="error"
              title="Delete"
              onClick={() => showDeleteDialog(sub)}
            >
              <Delete />
            </IconButton>
            <IconButton disabled={busy} onClick={() => showEditDialog(sub)}>
              <Edit />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: 20 }}
      transition={{ duration: 0.5 }}
      style={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        gap: "12px",
      }}
    >
      {mobile && (
        <Stack direction="row" justifyContent={"flex-end"} spacing={"8px"}>
          <Button
            variant="contained"
            disabled={busy}
            onClick={loadSubscriptions}
            color="secondary"
            startIcon={
              <SvgIcon>
                <IC_REFRESH />
              </SvgIcon>
            }
          >
            Refresh
          </Button>
          <Button
            disabled={busy}
            variant="contained"
            color="primary"
            startIcon={
              <SvgIcon>
                <IC_ADD_CARD />
              </SvgIcon>
            }
            onClick={showCreateDialog}
          >
            Create Subscription
          </Button>
        </Stack>
      )}
      <PFMTable
        sx={{ flex: 1 }}
        title="Subscriptions"
        description="List of all the subscription models for your bot."
        titleBadge={data.length + " Subscriptions"}
        actionButtons={
          mobile ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                disabled={busy}
                onClick={loadSubscriptions}
                color="secondary"
                startIcon={
                  <SvgIcon>
                    <IC_REFRESH />
                  </SvgIcon>
                }
              >
                Refresh
              </Button>
              <Button
                disabled={busy}
                variant="contained"
                color="primary"
                startIcon={
                  <SvgIcon>
                    <IC_ADD_CARD />
                  </SvgIcon>
                }
                onClick={showCreateDialog}
              >
                Create Subscription
              </Button>
            </>
          )
        }
        tableHead={
          <TableRow>
            {!mobile && <TableCell>ID</TableCell>}
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Subscription Name
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Last Modified
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Duration
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Price
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Chats
            </TableCell>
            <TableCell
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Products
            </TableCell>
            <TableCell
              align="right"
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 10,
                },
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderSubscription(d))}</>}
      />
    </motion.div>
  );
}
